import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import DateTimePicker from "react-datetime-picker";
import { format } from "date-fns";
import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";

// type ValuePiece = Date | null;

// type Value = ValuePiece | [ValuePiece, ValuePiece];
function Component({
  Id,
  name,
  Type,
  fromValue,
  toValue,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  disabled,
}) {
  const [startDate, setStartDate] = useState(fromValue ? fromValue : "");
  const [endDate, setEndDate] = useState(toValue ? toValue : "");
  const [startTime, setStartTime] = useState(fromValue ? fromValue : "");
  const [endTime, setEndTime] = useState(toValue ? toValue : "");

  const [startDateTime, setStartDateTime] = useState(
    fromValue ? fromValue : ""
  );
  const [endDateTime, setEndDateTime] = useState(toValue ? toValue : "");

  let DF = "MM/DD/YYYY";
  let TF = "HH:mm";
  let DTF = "MM/DD/YYYY HH:mm";
  useEffect(() => {
    handleChange(Id);
    // console.log(startDate);
    // console.log(startTime);
    // console.log(Id);
    // console.log(startDateTime);
  }, [startDate, startTime, startDateTime]);
  useEffect(() => {
    handleChange(Id);
    // console.log(endDate);
    // console.log(endTime);
    // console.log(Id);
    // console.log(endDateTime);
  }, [endDate, endTime, endDateTime]);

  const handleChange = (name) => {
    let val;
    if (Type === "dateRange") {
      if (startDate && endDate) {
        val = startDate + "-" + endDate;
      } else {
        val = "";
      }
    } else if (Type === "timeRange") {
      if (startTime && endTime) {
        val = startTime + "-" + endTime;
      } else {
        val = "";
      }
    } else if (Type === "datetimeRange") {
      if (startDateTime && endDateTime) {
        val = startDateTime + "-" + endDateTime;
      } else {
        val = "";
      }
    }

    //  console.log(val);
    if (name) {
      setAssignedDataProp({
        dataPropertyId: name,
        propertyValue: val.toString(),
      });
      let a = [...AssignedDataPropForm];
      a.forEach(myFunction);
      function myFunction(item) {
        if (item && item["dataPropertyId"] === name) {
          var index = a.indexOf(item);
          a.splice(index, 1);
        }
      }
      setAssignedDataPropForm(a);
    }
  };

  return (
    <div className={disabled}>
      <div className="row d-flex align-items-center">
        <div className="col-6">
          <Form.Group
            className={Type === "datetimeRange" ? "" : "mb-3"}
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label className="fw-semibold fs-7 mb-0">{name}</Form.Label>
            {Type === "dateRange" ? (
              <DatePicker
                className="field-input rangeFrom"
                selected={startDate}
                placeholderText="Start Date"
                onChange={(date) =>
                  date
                    ? setStartDate(moment(date).format(DF))
                    : setStartDate("")
                }
                name={Id}
              />
            ) : Type === "timeRange" ? (
              <TimePicker
                className=" rangeFrom "
                onChange={(time) =>
                  time
                    ? setStartTime(moment("Wed Feb 21 2024 " + time).format(TF))
                    : setStartTime("")
                }
                value={startTime}
                name={Id}
              />
            ) : Type === "datetimeRange" ? (
              <DateTimePicker
                className=" rangeFrom "
                onChange={(datetime) =>
                  datetime
                    ? setStartDateTime(moment(datetime).format(DTF))
                    : setStartDateTime("")
                }
                value={startDateTime}
                name={Id}
              />
            ) : null}
          </Form.Group>
        </div>
        {/* <div className="col-1 p-0 d-flex flex-column justify-content-center align-items-center">
          <Form.Label className="fw-semibold fs-7 mb-0 mt-3">-</Form.Label>
        </div> */}
        <div className={Type === "datetimeRange" ? "col-12" : "col-6"}>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold mb-2"></Form.Label>

            {Type === "dateRange" ? (
              <DatePicker
                className="field-input mt-2 rangeTo"
                selected={endDate}
                placeholderText="End Date"
                onChange={(date) =>
                  date ? setEndDate(moment(date).format(DF)) : setEndDate("")
                }
                name={Id}
              />
            ) : Type === "timeRange" ? (
              <TimePicker
                className=" rangeTo "
                onChange={(time) =>
                  time
                    ? setEndTime(moment("Wed Feb 21 2024 " + time).format(TF))
                    : setEndTime()
                }
                value={endTime}
                name={Id}
              />
            ) : Type === "datetimeRange" ? (
              <DateTimePicker
                className=" rangeTo "
                onChange={(datetime) =>
                  datetime
                    ? setEndDateTime(moment(datetime).format(DTF))
                    : setEndDateTime("")
                }
                value={endDateTime}
                name={Id}
              />
            ) : null}
          </Form.Group>
        </div>
      </div>
    </div>
  );
}
export default Component;
