import React from 'react'
import Query from './Query'

const QueryList = () => {
  return (
    <div className=' d-flex flex-column justify-content-between h-100 '>
    <div className='mb-5'>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <div className='d-flex align-items-center'>
                    <span className='fs-7 third'>QUERY</span>
                </div>
            </div>
           <Query/>
           <Query/>
           <Query/>
     </div>
    </div>
  )
}

export default QueryList
