import React from "react";

import { Route, Switch } from "react-router-dom";
import Home from "../pages/Home";
import Diagram from "../pages/Diagram";
import DataInputs from "../pages/DataInputs";
import Relationships from "../pages/Relationships";
import Query from "../pages/AddQuery";
import Outputs from "../pages/Outputs";
import AI_TutorDashboard from "../pages/Dashboard";
import UploadDocuments from "../pages/UploadDocuments";
import Tables from "../pages/Tables";
import DataFields from "../pages/DataFields";
import CreateRelation from "../pages/CreateRelation";
import SavedQuery from "../pages/SavedQuery";
import SavedOutput from "../pages/SavedOutput";
import Setting from "../pages/Settings";
import Login from "../pages/Login";
import API from "../pages/API";
import Users from "../pages/Users";
import UsersPage from "../pages/Users/UsersPage";
import Report from "../pages/Report";
import Platfrom from "../pages/Platfrom";

import Office360 from "../pages/Platforms/Office360";
import Canvas from "../pages/Platforms/Canvas";
import AUD from "../pages/Platforms/AUD";
import BlackBoard from "../pages/Platforms/BlackBoard";
import ChooseDirectory from "../pages/ChooseDirectory";
import ConnectBlackBoard from "../pages/Platforms/Connect/ConnectBlackBoard";
import ConnectOffice360 from "../pages/Platforms/Connect/ConnectOffice365";
import ConnectOffice365 from "../pages/Platforms/Connect/ConnectOffice365";
import ConnectCanvas from "../pages/Platforms/Connect/ConnectCanvas";
import ConnectAUD from "../pages/Platforms/Connect/ConnectAUD";
import ConnectASI from "../pages/Platforms/Connect/ConnectASI";
import DirectoryList from "../pages/DirectoryList";
import EditReport from "../pages/EditReport";
import ViewReport from "../pages/ViewReport";
// import Try from "../pages/try";
import Jobs from "../pages/Jobs";
import Filter from "../pages/Filtering";
import Filtering from "../pages/Filtering";
import FilterColumn from "../pages/Filtering";
import ReportOption from "../pages/ReportOptions";
import examples from "../pages/FreeExample";
import GetBaseUrl from "../functions/SetBaseUrl";

import Memo from "../pages/TestFolder/Memoised";
import NoPermission from "../pages/NoPermission";

import NewCreateRelation from "../pages/NewCreateRelation";
import NewRelationships from "../pages/NewRelationships";
import Fields from "../pages/Fields";
import Conditonal from "../pages/Conditonal";
var role = localStorage.getItem(GetBaseUrl()[1] + "_Role");
var dashboardsPermissions = localStorage.getItem(
  GetBaseUrl()[1] + "_dashboardsPermissions"
);

const Routes = () => {
  const checkPermissions = (arrayOf, Permission, Page) => {
    let userPermissions = arrayOf.split(",");
    // console.log(userPermissions);

    if (userPermissions.indexOf(Permission) > -1) {
      return Page;
    } else {
      return NoPermission;
    }
  };

  return (
    <Switch>
      <Route path="/memo" exact component={Memo} />
      {/* <Route path="/try" exact component={Try} /> */}

      <Route path="/" exact component={Home} />
      <Route
        path="/AI_Tutor"
        exact
        component={checkPermissions(
          dashboardsPermissions,
          "AI_Tutor",
          AI_TutorDashboard
        )}
      />
      {/* <Route path="/Diagram" exact component={Try} /> */}
      <Route
        path="/Users"
        exact
        component={role === "Admin" ? UsersPage : NoPermission}
      />

      <Route
        path="/ConnectDirectory/Office"
        exact
        component={ConnectOffice360}
      />
      <Route path="/ConnectDirectory/Canvas" exact component={ConnectCanvas} />
      <Route path="/ConnectDirectory/AUD" exact component={ConnectAUD} />
      <Route path="/ConnectDirectory/ASI" exact component={ConnectASI} />
      <Route
        path="/ConnectDirectory/BlackBoard"
        exact
        component={ConnectBlackBoard}
      />

      <Route path="/Office360/Platform/Tables" exact component={Office360} />
      <Route path="/Canvas/Platform/Tables" exact component={Canvas} />
      <Route path="/AUD/Platform/Tables" exact component={AUD} />
      <Route path="/BlackBoard/Platform/Tables" exact component={BlackBoard} />

      <Route path="/DataInput" exact component={DataInputs} />
      <Route
        path="/DataInput/Upload-Document"
        exact
        component={UploadDocuments}
      />
      <Route path="/DataInput/Tables" exact component={Tables} />
      <Route path="/DataInput/Data-Fields" exact component={DataFields} />
      <Route path="/Relationships" exact component={Relationships} />
      <Route path="/Relationships/Create" exact component={CreateRelation} />

      <Route path="/Query" exact component={Query} />
      <Route path="/Query/Saved-Query" exact component={SavedQuery} />
      <Route path="/Reports" exact component={Outputs} />
      <Route path="/Reports/Saved-Reports" exact component={SavedOutput} />
      {/* <Route path="/Dashboard" exact component={Dashboard} /> */}
      <Route path="/Platform" exact component={Platfrom} />
      <Route path="/Fields" exact component={Fields} />

      <Route path="/DataInput/Directory" exact component={ChooseDirectory} />
      <Route path="/Reports/Edit-Report" exact component={EditReport} />
      <Route path="/Home/Reports/View-Report" exact component={ViewReport} />
      <Route path="/Jobs" exact component={Jobs} />
      <Route path="/Filter" exact component={FilterColumn} />
      <Route path="/Reportoption" exact component={ReportOption} />

      <Route path="/examples" exact component={examples} />

      <Route path="/formatting" exact component={Conditonal} />
      <Route path="/NewRelationships" exact component={NewRelationships} />
      <Route
        path="/NewRelationships/Create"
        exact
        component={NewCreateRelation}
      />
    </Switch>
  );
};

export default Routes;
