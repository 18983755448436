import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rel_arrow from "../../assets/svg/connected.svg";
import header from "../../assets/svg/header.svg";
import bar from "../../assets/svg/bar.svg";
import bar2 from "../../assets/svg/bar2.svg";
import SelectData from "../table/SelectData";
import Select from "react-select";
import OneLink from "../relationship/one";
import ManyLink from "../relationship/Many";
import MainLink from "../relationship/MainLink";
import MultiLink from "../relationship/MultiLink";

import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  BackgroundVariant,
  Background,
  Controls,
  ConnectionMode,
} from "reactflow";
import "reactflow/dist/style.css";
import CustomNode from "../../pages/Nodes/CustomNode";

import CustomEdge from "../../pages/Nodes/CustomEdge";
import CustomType from "../../pages/Nodes/CustomType";

const nodeTypes = { custom: CustomNode };

const edgeTypes = {
  CustomEdge: CustomEdge,
  "start-end": CustomType,
};

function CreateReports(props) {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const onConnect = useCallback(
    (params) => setEdges((els) => addEdge(params, els)),
    []
  );

  const [reload, setReload] = useState(0);
  const [edgeId, setEdgeId] = useState(0);
  const [TT, setTT] = useState(0);
  const [FT, setFT] = useState(0);
  const [doNext, setDoNext] = useState([]);

  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [allLinkArray, setAllLinkArray] = useState([]);

  const [byColumn, setByColumn] = useState(null);
  const [toColumn, setToColumn] = useState(null);
  const [linkTableId, setLinkTableId] = useState(null);

  const [byColumnName, setByColumnName] = useState(null);
  const [toColumnName, setToColumnName] = useState(null);
  const [linkTableName, setLinkTableName] = useState(null);
  const [linkType, setLinkType] = useState(null);

  const [refJson, setRefJson] = useState({});
  const [refTable, setRefTable] = useState("");
  const [refTableName, setRefTableName] = useState("");

  const [Changed, setChanged] = useState({});
  const [ToChangeRight, setToChangeRight] = useState({ value: "", text: "" });
  const [ToChangeLeft, setToChangeLeft] = useState({ value: "", text: "" });
  const [From, setFrom] = useState(null);
  const [leftTables, setLeftTables] = useState([]);
  const [rightTables, setRightTables] = useState([]);
  const [addJson, setAddJson] = useState(false);

  const [selectedOption, setSelectedOption] = useState([]);
  const [dataOptions, setDataOptions] = useState([
    { value: 1, text: "One to One" },
    { value: 2, text: "One to Many" },
    { value: 3, text: "Many to One" },
    { value: 4, text: "Many to Many" },
  ]);

  const [allTables, setAllTables] = useState([]);
  const [finalAllTables, setFinalAllTables] = useState([]);

  //------------------------------------------------------
  const handleChange = (e) => {
    setSelectedOption(e);
    setLinkType(e.value);
  };
  //----------------------------------------------------
  function FindDeleteFromObj(array, Key1, Value1, Key2, Value2) {
    let res = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key1] === Value1 && array[i][Key2] === Value2) {
        array.splice(i, 1);
        res = true;
      }
    }

    return res;
  }

  //******

  function FL(array, Key1, Value1, Key2, Value2) {
    let res = [];
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key1] === Value1 && array[i][Key2] === Value2) {
        if (array[i].Links.length > 0) {
          res = array[i].Links;
        }
      }
    }

    return res;
  }

  //----------------------------------------------------
  function removeFromRelationship(
    FromTable,
    Fromcolumn,
    ToTable,
    ToColumn,
    LinkId = 0
  ) {
    // console.log(FromTable);
    // console.log(ToTable);
    // console.log(Fromcolumn);
    // console.log(ToColumn);
    setEdgeId(LinkId);
    setTT(ToTable);
    setFT(FromTable);

    let array1 = [...props.MainJsonBody];
    for (var i = 0; i < array1.length; i++) {
      if (array1[i]["tableId"] === FromTable) {
        for (var j = 0; j < array1[i].reference.length; j++) {
          //From create relation
          if (
            !props.From_ViewRelationship &&
            array1[i].reference[j]["ByColumn"] === Fromcolumn
          ) {
            FindDeleteFromObj(
              array1[i].reference[j].Target,
              "targetTableId",
              ToTable,
              "targetColumnId",
              ToColumn
            );

            if (array1[i].reference[j]["Target"].length === 0) {
              array1[i].reference.splice(j, 1);
            }
            break;
          }
          //from view relation
          else if (
            props.From_ViewRelationship &&
            array1[i].reference[j]["columnId"] === Fromcolumn
          ) {
            FindDeleteFromObj(
              array1[i].reference[j].target,
              "tableId",
              ToTable,
              "columnId",
              ToColumn
            );
            if (array1[i].reference[j].Target) {
              FindDeleteFromObj(
                array1[i].reference[j].Target,
                "targetTableId",
                ToTable,
                "targetColumnId",
                ToColumn
              );
            }

            if (array1[i].reference[j]["target"].length === 0) {
              array1[i].reference.splice(j, 1);
            }
            break;
          }
        }
        break;
      }
    }
    props.setMainJsonBody(array1);
  }
  //----------------------------------------------------
  useEffect(() => {
    let array = [...edges];
    let arr = [...nodes];

    if (edgeId) {
      for (var i = 0; i < array.length; i++) {
        if (array[i]["id"] === edgeId) {
          array.splice(i, 1);
          break;
        }
      }
      setEdges(array);

      // console.log(FT);
      // console.log(TT);
      // console.log(array);
      let FromSourceFind = FindObj(array, "source", FT);
      let FromTargetFind = FindObj(array, "target", FT);
      let ToSourceFind = FindObj(array, "source", TT);
      let ToTargetFind = FindObj(array, "target", TT);
      // console.log('-----------------------------------');
      // console.log(FromSourceFind);
      // console.log(FromTargetFind);
      // console.log(ToSourceFind);
      // console.log(ToTargetFind);
      // console.log('-----------------------------------');
      for (var i = 0; i < arr.length; i++) {
        if (!FromSourceFind && !FromTargetFind && arr[i].id === FT) {
          arr.splice(i, 1);
          // console.log('11111111111');
          break;
        }
      }
      for (var i = 0; i < arr.length; i++) {
        if (!ToSourceFind && !ToTargetFind && arr[i].id === TT) {
          arr.splice(i, 1);
          // console.log('2222222222');
          break;
        }
      }
      setNodes(arr);
    }
  }, [edgeId]);

  //----------------------------------------------------
  useEffect(() => {
    let t = [];

    if (From) {
      t = [...rightTables];
      for (let index = 0; index < t.length; index++) {
        const item = t[index];
        if (Changed.value === item.value) {
          t.splice(index, 1);
        }
      }
      setRightTables(t);
    } else {
      t = [...leftTables];
      for (let index = 0; index < t.length; index++) {
        const item = t[index];
        if (Changed.value === item.value) {
          t.splice(index, 1);
        }
      }
      setLeftTables(t);
    }
  }, [Changed]);

  //----------------------------------------------------
  useEffect(() => {
    let t = [];
    for (let index = 0; index < props.MainJsonBody.length; index++) {
      const item = props.MainJsonBody[index];

      t.push({ value: item.tableId, text: item.tableName });
    }

    setRightTables(t);
    setLeftTables(t);
  }, [props.ResetNow]);

  //----------------------------------------------------props.ResetNow
  function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }

  //----------------------------------------------------
  function FindIndexInObj(array, Key, Value) {
    var msg = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i + 1;
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------
  function AddLink(Table, TableName, obj) {
    let Link1, Link2;
    if (linkType === 1) {
      Link1 = "One";
      Link2 = "One";
    } else if (linkType === 2) {
      Link1 = "One";
      Link2 = "Many";
    } else if (linkType === 3) {
      Link1 = "Many";
      Link2 = "One";
    } else if (linkType === 4) {
      Link1 = "Many";
      Link2 = "Many";
    }

    if (Table && obj && linkType) {
      let found = false;
      let array = [...props.MainJsonBody];
      //--------------------order
      let abort = false;
      let hasRows = 0;
      for (var i = 0; i < array.length && !abort; i++) {
        array[i].hasRows = 0;
        if (array[i]["tableId"] === Table) {
          if (array[i].reference) {
            for (var j = 0; j < array[i].reference.length && !abort; j++) {
              //--------------------order
              if (
                FindObj(array[i].reference[j].target, "columnId", obj.ToColumn)
              ) {
                abort = true;
                break;
              } else {
                if (array[i].reference[j]["ByColumn"] === obj.ByColumn) {
                  array[i].reference[j].Target.push({
                    tableId: obj.TableId,
                    columnId: obj.ToColumn,
                    targetTableId: obj.TableId,
                    targetColumnId: obj.ToColumn,
                    targetTable: obj.TableName,
                    targetColumn: obj.ToColumnName,
                    Link: Link2,
                  });
                  array[i].reference[j].target.push({
                    tableId: obj.TableId,
                    columnId: obj.ToColumn,
                    linkType: linkType,
                  });
                  found = true;
                  hasRows += array[i].reference[j].target.length;
                  break;
                }
              }
            }
            array[i].hasRows = hasRows;

            if (!found && !abort) {
              delete obj.TableId;
              delete obj.ToColumn;
              delete obj.TableName;
              delete obj.ToColumnName;

              obj.Link = Link1;
              obj.Target[0].Link = Link2;

              obj.target[0].linkType = linkType;
              array[i].reference.push(obj);
              array[i].hasRows = 1;
            }
            break;
          }
        }
      }

      props.setMainJsonBody(array);
    } else {
      // toast.warning('Select Relation Type', {position: toast.POSITION.TOP_RIGHT});
    }
  }
  //----------------------------------------------------
  useEffect(() => {
    let Link1, Link2;

    if (linkType === 1) {
      Link1 = "One";
      Link2 = "One";
    } else if (linkType === 2) {
      Link1 = "One";
      Link2 = "Many";
    } else if (linkType === 3) {
      Link1 = "Many";
      Link2 = "One";
    } else if (linkType === 4) {
      Link1 = "Many";
      Link2 = "Many";
    }

    if (Object.keys(refJson).length > 0) {
      RelationsFlow(
        refTable,
        refTableName,
        refJson.ByColumn,
        refJson.ByColumnName,
        Link1,
        refJson.Target[0].targetTableId,
        refJson.Target[0].targetTable,
        refJson.Target[0].targetColumnId,
        refJson.Target[0].targetColumn,
        Link2
      );
    }

    AddLink(refTable, refTableName, refJson);

    let t = [];
    for (let index = 0; index < props.MainJsonBody.length; index++) {
      const item = props.MainJsonBody[index];
      t.push({ value: item.tableId, text: item.tableName });
    }
    setRightTables(t);
    setLeftTables(t);
  }, [refJson]);

  //----------------------------------------------------
  function RelationshipValidation(ByTableId, ToTableId) {
    let array = [...edges];
    let FirstArray = [];
    let SecondArray = [];
    let AllChildsArray = [];
    let msg = "";

    for (var i = 0; i < array.length; i++) {
      if (array[i]["source"] === ByTableId) {
        AllChildsArray.push(array[i]["target"]);
      }
    }

    for (var i = 0; i < array.length; i++) {
      if (array[i]["target"] === ByTableId) {
        FirstArray.push(array[i]["source"]);
      }
    }
    for (var i = 0; i < array.length; i++) {
      if (array[i]["target"] === ToTableId) {
        SecondArray.push(array[i]["source"]);
      }
    }

    //-----------Case 1
    for (let index = 0; index < FirstArray.length; index++) {
      const element = FirstArray[index];
      if (SecondArray.indexOf(element) > -1) {
        msg = "Link between these tables is not allowed";
        break;
      }
    }

    //-----------Case 2
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (
        (element.source === ByTableId && element.target === ToTableId) ||
        (element.source === ToTableId && element.target === ByTableId)
      ) {
        msg = "These Tables already have linked";
        break;
      }
    }

    //-----------Case 3
    for (let index = 0; index < AllChildsArray.length; index++) {
      const element = AllChildsArray[index];
      if (SecondArray.indexOf(element) > -1) {
        msg = "Link between these tables is not allowed";
        break;
      }
    }

    //-----------Case 4
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (ByTableId === ToTableId) {
        msg = "Link by one table is not allowed";
        break;
      }
    }

    return msg;
  }
  //----------*****************

  function RelationsFlow(
    ByTableId,
    ByTableName,
    ByColumnId,
    ByColumnName,
    Link1,
    ToTableId,
    ToTableName,
    ToColumnId,
    ToColumnName,
    Link2,
    TotalLinks = 0
  ) {
    let Ed = [...edges];
    let aa = [...nodes];

    let res = RelationshipValidation(ByTableId, ToTableId);
    if (res) {
      toast.error(res, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
    //-----------------------------------
    setReload(0);

    let checkTable1 = FindIndexInObj(aa, "id", ByTableId);
    let checkTable2 = FindIndexInObj(aa, "id", ToTableId);

    if (!checkTable1) {
      aa.push({
        id: ByTableId,
        data: {
          label: ByTableName,
          columns: [ByColumnName],
          lastChild: false,
          firstLevel: true,
        },
        sourcePosition: "right",
        targetPosition: "left",
        type: "custom",
        position: { x: 50, y: aa.length * 150 },
      });
    } else {
      if (aa[checkTable1 - 1].data.columns.indexOf(ByColumnName) === -1) {
        aa[checkTable1 - 1].data.columns.push(ByColumnName);
      }
    }

    let lastChild = true;
    if (!checkTable2) {
      if (FindIndexInObj(Ed, "source", ToTableId)) {
        lastChild = false;
      }
      aa.push({
        id: ToTableId,
        data: {
          label: ToTableName,
          columns: [ToColumnName],
          lastChild: lastChild,
          firstLevel: false,
        },
        sourcePosition: "right",
        targetPosition: "left",
        type: "custom",
        position: { x: aa.length * 175, y: aa.length * 100 },
      });
    } else {
      if (FindIndexInObj(Ed, "source", ToTableId)) {
        aa[checkTable2 - 1].data.lastChild = false;
      }
      if (aa[checkTable2 - 1].data.columns.indexOf(ToColumnName) === -1) {
        aa[checkTable2 - 1].data.columns.push(ToColumnName);
      }
    }

    let x = randomNumberInRange(1, 99999999);
    Ed.push({
      id: x,
      source: ByTableId,
      target: ToTableId,
      data: {
        startLabel: "start edge label",
        endLabel: "end edge label",
        ByTableId: ByTableId,
        ToTableId: ToTableId,
        ByColumnId: ByColumnId,
        ToColumnId: ToColumnId,
        From_ViewRelationship: props.From_ViewRelationship,
        removeFromRelationship: removeFromRelationship,
        Link1: "Link1",
        Link2: "Link2",
      },
      type: "start-end",
    });

    setNodes(aa);
    setEdges(Ed);
  }

  useEffect(() => {
    setReload(1);
    console.log(nodes);
  }, [nodes]);
  useEffect(() => {
    setReload(1);
    console.log(edges);
    setDoNext(edges);
  }, [edges]);

  //----------------------------------------------------
  function randomNumberInRange(min, max) {
    //  get number between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  //----------------------------------------------------
  useEffect(() => {
    if (props.From_ViewRelationship) {
      let t = [...props.MainJsonBody];
      for (let index = 0; index < t.length; index++) {
        const item = t[index];

        if (item.reference) {
          for (let i = 0; i < item.reference.length; i++) {
            const item1 = item.reference[i];
            if (item1.target && !item1.Target) {
              for (let j = 0; j < item1.target.length; j++) {
                const item2 = item1.target[j];

                if (
                  FindObj(doNext, "source", item.tableId) &&
                  FindObj(doNext, "target", item2.tableId)
                ) {
                  continue;
                }

                let Link1, Link2;
                if (item2.linkType === 1) {
                  Link1 = "One";
                  Link2 = "One";
                } else if (item2.linkType === 2) {
                  Link1 = "One";
                  Link2 = "Many";
                } else if (item2.linkType === 3) {
                  Link1 = "Many";
                  Link2 = "One";
                } else if (item2.linkType === 4) {
                  Link1 = "Many";
                  Link2 = "Many";
                }

                RelationsFlow(
                  item.tableId,
                  item.tableName,
                  item1.columnId,
                  item1.columnName,
                  Link1,
                  item2.tableId,
                  item2.tableName,
                  item2.columnId,
                  item2.columnName,
                  Link2
                );
              }
            } else {
              for (let j = 0; j < item1.Target.length; j++) {
                const item2 = item1.Target[j];

                let Link1, Link2;
                if (item2.linkType === 1) {
                  Link1 = "One";
                  Link2 = "One";
                } else if (item2.linkType === 2) {
                  Link1 = "One";
                  Link2 = "Many";
                } else if (item2.linkType === 3) {
                  Link1 = "Many";
                  Link2 = "One";
                } else if (item2.linkType === 4) {
                  Link1 = "Many";
                  Link2 = "Many";
                }

                RelationsFlow(
                  item.tableId,
                  item.tableName,
                  item1.ByColumn,
                  item1.ByColumnName,
                  Link1,
                  item2.targetTableId,
                  item2.targetTable,
                  item2.targetColumnId,
                  item2.targetColumn,
                  Link2
                );
              }
            }
          }
        }
      }
    }
  }, [doNext]);
  //----------------------------------------------

  return (
    <div>
      <div className="row p-2 px-4">
        <div className="col-5 pe-0">
          <SelectData
            Changed={Changed}
            setChanged={setChanged}
            ToChangeRight={ToChangeRight}
            ToChangeLeft={ToChangeLeft}
            setFrom={setFrom}
            tables={leftTables}
            ResetNow={props.ResetNow}
            resetFilter={props.resetFilter}
            setResetFilter={props.setResetFilter}
            MainJsonBody={props.MainJsonBody}
            setMainJsonBody={props.setMainJsonBody}
            setRefJson={setRefJson}
            setRefTable={setRefTable}
            setRefTableName={setRefTableName}
            Left
            byColumn={byColumn}
            setByColumn={setByColumn}
            byColumnName={byColumnName}
            setByColumnName={setByColumnName}
            toColumn={toColumn}
            setToColumn={setToColumn}
            setToColumnName={setToColumnName}
            toColumnName={toColumnName}
            linkTableId={linkTableId}
            setLinkTableId={setLinkTableId}
            setLinkTableName={setLinkTableName}
            linkTableName={linkTableName}
            linkType={linkType}
            addJson={addJson}
          />
        </div>
        <div className="col-5 d-flex">
          <div className="d-flex flex-column justify-content-center">
            <img src={rel_arrow} className="black-fill mt-5 me-3" />
          </div>
          <div className="w-100">
            <SelectData
              Changed={Changed}
              setChanged={setChanged}
              ToChangeRight={ToChangeRight}
              ToChangeLeft={ToChangeLeft}
              setFrom={setFrom}
              resetFilter={props.resetFilter}
              setResetFilter={props.setResetFilter}
              tables={rightTables}
              ResetNow={props.ResetNow}
              MainJsonBody={props.MainJsonBody}
              setMainJsonBody={props.setMainJsonBody}
              setRefJson={setRefJson}
              setRefTable={setRefTable}
              setRefTableName={setRefTableName}
              Left={false}
              byColumn={byColumn}
              setByColumn={setByColumn}
              byColumnName={byColumnName}
              setByColumnName={setByColumnName}
              toColumn={toColumn}
              setToColumn={setToColumn}
              setToColumnName={setToColumnName}
              toColumnName={toColumnName}
              linkTableId={linkTableId}
              setLinkTableId={setLinkTableId}
              setLinkTableName={setLinkTableName}
              linkTableName={linkTableName}
              AddLink={AddLink}
              refTable={refTable}
              refJson={refJson}
              linkType={linkType}
              addJson={addJson}
            />
          </div>
        </div>
        <div className="col-2 pe-0">
          <Select
            placeholder="Select Type"
            value={selectedOption}
            options={dataOptions}
            onChange={handleChange}
            className="rounded-pill border mb-3"
            getOptionLabel={(e) => (
              <div
                className="fs-7 py-2"
                style={{ display: "flex", alignItems: "center" }}
              >
                {e.icon}
                <span className="px-1 py-0" style={{ marginLeft: 5 }}>
                  {e.text}
                </span>
              </div>
            )}
          />

          <Button
            className="main-button w-100 fw-semibold rounded-pill py-2 me-4"
            variant=""
            onClick={() => {
              setAddJson(Math.random(103));
            }}
          >
            Add
          </Button>
        </div>
      </div>

      <div className="row overflow-x-auto px-1  w-100 hide">
        <table className=" ">
          {finalAllTables &&
            finalAllTables.map((item, index) => (
              <>
                {item.Links.length > 0 ? (
                  <tr key={index}>
                    <td className="p-3">
                      <MultiLink
                        tableName={item.TableName}
                        Links={item.Links}
                        FirstLevel
                      />
                    </td>
                    {item.Links.length > 0 ? (
                      <td style={{ border: "#FFF solid" }}>
                        {item.Links.length > 0 &&
                          item.Links &&
                          item.Links.map((i, index) => (
                            <tr key={index}>
                              <td className="p-3">
                                <MultiLink
                                  tableName={i.ToTableName}
                                  PrimaryColumn={i.ToColumnName}
                                  Links={i.Links}
                                  FirstLevel={false}
                                  removeFromRelationship={
                                    removeFromRelationship
                                  }
                                  ByTableId={i.ByTableId}
                                  ByColumnId={i.ByColumnId}
                                  ToTableId={i.ToTableId}
                                  ToColumnId={i.ToColumnId}
                                />
                              </td>
                              <td>
                                {i.Links.length > 0 &&
                                  i.Links &&
                                  i.Links.map((a, index1) => (
                                    <tr key={index1}>
                                      <td className="p-3">
                                        <MultiLink
                                          tableName={a.ToTableName}
                                          PrimaryColumn={a.ToColumnName}
                                          Links={a.Links}
                                          FirstLevel={false}
                                          ByTableId={a.ByTableId}
                                          ByColumnId={a.ByColumnId}
                                          ToTableId={a.ToTableId}
                                          ToColumnId={a.ToColumnId}
                                          removeFromRelationship={
                                            removeFromRelationship
                                          }
                                        />
                                      </td>
                                      <td>
                                        {a.Links.length > 0 &&
                                          a.Links &&
                                          a.Links.map((b, index) => (
                                            <tr key={index}>
                                              <td className="p-3">
                                                <MultiLink
                                                  tableName={b.ToTableName}
                                                  PrimaryColumn={b.ToColumnName}
                                                  Links={b.Links}
                                                  FirstLevel={false}
                                                  ByTableId={b.ByTableId}
                                                  ByColumnId={b.ByColumnId}
                                                  ToTableId={b.ToTableId}
                                                  ToColumnId={b.ToColumnId}
                                                  removeFromRelationship={
                                                    removeFromRelationship
                                                  }
                                                />
                                              </td>
                                              <td className="p-3">
                                                {b.Links.length > 0 &&
                                                  b.Links &&
                                                  b.Links.map((c, index) => (
                                                    <tr key={index}>
                                                      <td className="p-3">
                                                        <MultiLink
                                                          tableName={
                                                            c.ToTableName
                                                          }
                                                          PrimaryColumn={
                                                            c.ToColumnName
                                                          }
                                                          Links={c.Links}
                                                          FirstLevel={false}
                                                          ByTableId={
                                                            c.ByTableId
                                                          }
                                                          ByColumnId={
                                                            c.ByColumnId
                                                          }
                                                          ToTableId={
                                                            c.ToTableId
                                                          }
                                                          ToColumnId={
                                                            c.ToColumnId
                                                          }
                                                          removeFromRelationship={
                                                            removeFromRelationship
                                                          }
                                                        />
                                                      </td>
                                                      <td></td>
                                                    </tr>
                                                  ))}
                                              </td>
                                            </tr>
                                          ))}
                                      </td>
                                    </tr>
                                  ))}
                              </td>
                            </tr>
                          ))}
                      </td>
                    ) : null}
                  </tr>
                ) : null}
              </>
            ))}
        </table>
      </div>

      <div>
        <div style={{ height: "800px" }}>
          {reload ? (
            <>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                fitView
                attributionPosition="bottom-right"
                zoomOnScroll={true}
                minZoom="0.5"
                maxZoom="1.3"
              >
                <Background
                  id="2"
                  gap={10}
                  color="#F2F2F2"
                  variant={BackgroundVariant.Lines}
                />

                <Controls position="top-right" />
              </ReactFlow>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CreateReports;
