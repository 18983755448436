import { React, useEffect, useState } from "react";
import TableContainer from "../../../components/Molecules/TableContainer";
import AddEditUser from "../../modals/Users/AddEditUser";
import ResetPasswordUser from "../../modals/Users/ResetPasswordUser";
import ViewUser from "../../modals/Users/ViewUser";

import DeleteUser from "../../modals/Users/DeleteUser";
import ActionBtn from "../../Atoms/ActionBtn";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import EditIcon from "../../../assets/images/Users/EditIcon.svg";
import DeleteIcon from "../../../assets/images/Users/DeleteIcon.svg";
import ResetIcon from "../../../assets/images/Users/ResetIcon.svg";

const TableFields = ({
  data,
  tableHeader,
  selectedUser,
  setSelectedUser,
  rowsCount,
  refetchUsers,
  tablePageSize,
  setTablePageSize,
  setTablePage,
  tablePage,
}) => {
  //------------------------------------------------------

  const [addEditMode, setAddEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [resetPasswordMode, setResetPasswordMode] = useState(false);
  const [viewMode, setViewMode] = useState(false);
  const [pages, setPages] = useState([
    { value: "AI_Tutor", label: "AI Tutor" },
    { value: "AUD_Dashboard", label: "AUD Dashboard" },
    { value: "Canvas_Dashboard", label: "Canvas Dashboard" },
    {
      value: "BlackBoard_Dashboard",
      label: "BlackBoard Dashboard",
    },
  ]);

  //---------------------------------------------------
  useEffect(() => {
    // console.log(selectedUser);
  }, [selectedUser]);
  //---------------------------------------------------
  const handleActionClick = () => {
    setAddEditMode(true);
    setSelectedUser(null);
  };
  //---------------------------------------------------
  return (
    <>
      <TableContainer
        tablePageSize={tablePageSize}
        tablePage={tablePage}
        setTablePageSize={setTablePageSize}
        setTablePage={setTablePage}
        data={data}
        tableHeader={tableHeader}
        withActionColumn="Action"
        normalTable
        headerBtns={[
          <ActionBtn
            variant={"primary"}
            title="Add User"
            rounded
            OnClick={handleActionClick}
            classname={"container-25-50-100"}
          />,
          <ActionBtn
            title={rowsCount + " users"}
            rounded
            bg="#DCEEFF"
            classname={"container-20-25-25"}
          />,
        ]}
        actionBtns={[
          <img
            src={EditIcon}
            className="actionIcon"
            alt="Edit"
            title="Edit User"
            onClick={(event) => {
              setAddEditMode(true);

              // event.stopPropagation();
            }}
          />,
          <img
            src={ResetIcon}
            className="actionIcon"
            alt="Reset"
            title="Reset Password"
            onClick={(event) => {
              setResetPasswordMode(true);

              // event.stopPropagation();
            }}
          />,
          <img
            src={DeleteIcon}
            className="actionIcon"
            alt="Delete"
            title="Delete User"
            onClick={(event) => {
              setDeleteMode(true);

              //  event.stopPropagation();
            }}
          />,
        ]}
        loadingText="Loading Table"
        normalTableName="Users List"
        setSelectedUser={setSelectedUser}
        rowsCount={rowsCount}
        setViewMode={setViewMode}
      />

      {addEditMode ? (
        <AddEditUser
          selectedUser={selectedUser}
          setAddEditMode={setAddEditMode}
          refetchUsers={refetchUsers}
          pages={pages}
        />
      ) : null}
      {deleteMode ? (
        <DeleteUser
          selectedUser={selectedUser}
          setDeleteMode={setDeleteMode}
          refetchUsers={refetchUsers}
        />
      ) : null}
      {resetPasswordMode ? (
        <ResetPasswordUser
          selectedUser={selectedUser}
          setResetPasswordMode={setResetPasswordMode}
        />
      ) : null}

      {viewMode ? (
        <ViewUser selectedUser={selectedUser} setViewMode={setViewMode} />
      ) : null}
    </>
  );
};

export default TableFields;
