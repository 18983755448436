import { React } from "react";
import Table from "../Molecules/Table";
import "react-toastify/dist/ReactToastify.css";
//---------------------------------------
const TableContainer = (props) => {
  //---------------------------------------

  return (
    <Table
      limit={props.tablePageSize}
      tablePage={props.tablePage - 1}
      setTablePageSize={props.setTablePageSize}
      setTablePage={props.setTablePage}
      normalTable={props.normalTable}
      normalTableName={props.normalTableName}
      data={props.data}
      tableHeader={props.tableHeader}
      headerBtns={props.headerBtns}
      actionBtns={props.actionBtns}
      withActionColumn={props.withActionColumn}
      setSelectedUser={props.setSelectedUser}
      rowsCount={props.rowsCount}
      setViewMode={props.setViewMode}
    />
  );
};

export default TableContainer;
