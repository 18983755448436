import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Filter from "./Filter";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import { IndexContext } from "../../components/layout/Layout";
import DropdownWithIcon from "../../components/Atoms/DropdownWithIcon";

function FiltersModal({
  ApplyFilters,
  setFiltersModal,
  Filters,
  setQueryId,
  setFilterIndex,
  setAskQueryModal,
  withQuery,
  selectedFilters,
  setSelectedFilters,
}) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //-------------------------------------------IndexContext

  const handleClose = () => setFiltersModal(false);

  //---------------------------------------------------  (1)
  useEffect(() => {}, []);
  //---------------------------------------------------

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4> Filters </h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          {Filters &&
            Filters.map((item, index) => {
              return item.blue ? null : (
                <div className="w-100 row mt-3 ">
                  <DropdownWithIcon
                    key={index}
                    index={index + 1}
                    setQueryId={setQueryId}
                    icon={item.icon}
                    placeholder={item.placeholder}
                    selected={item.selected}
                    onChange={item.onChange}
                    container_class={item.container_class}
                    options={item.options}
                    withQuery={withQuery}
                    setAskQueryModal={setAskQueryModal}
                    setFilterIndex={setFilterIndex}
                    inputFilterModalGroup
                    setSelectedFilters={setSelectedFilters}
                    selectedFilters={selectedFilters}
                  />

                  {/* <Filter
                    key={index}
                    index={index + 1}
                    setQueryId={setQueryId}
                    icon={item.icon}
                    placeholder={item.placeholder}
                    selected={item.selected}
                    onChange={item.onChange}
                    container_class={item.container_class}
                    options={item.options}
                    withQuery={withQuery}
                    setAskQueryModal={setAskQueryModal}
                    setFilterIndex={setFilterIndex}
                    inputFilterModalGroup
                  /> */}
                </div>
              );
            })}

          <Button
            className="main-button w-100 fw-semibold rounded-pill py-2 mt-4"
            variant=""
            onClick={() => {
              ApplyFilters();
              handleClose();
            }}
          >
            Run Query
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default FiltersModal;
