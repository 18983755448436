import React, { useState, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import canvas from "../../assets/svg/canvas.svg";
import canvas_icon from "../../assets/svg/canvas-icon.svg";
import DirectoryFiles from "./DirectoryFiles";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { IndexContext } from "../layout/Layout";

function Canvas(props) {
  //---------------------------------------------------------Index Style
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //---------------------------------------------------------Index Style
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const Redirect = () => {
    window.location.href = "/Canvas/Platform/Tables";
  };
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [Canvas_ClientID, setCanvas_ClientID] = useState("");
  const [Canvas_RefreshToken, setCanvas_RefreshToken] = useState("");
  const [Canvas_ClientSecret, setCanvas_ClientSecret] = useState("");
  const [Canvas_BaseURL, setCanvas_BaseURL] = useState("");
  const handleChange = (event) => {
    if (event.target.name === "Canvas_ClientID") {
      setCanvas_ClientID(event.target.value);
    } else if (event.target.name === "Canvas_RefreshToken") {
      setCanvas_RefreshToken(event.target.value);
    } else if (event.target.name === "Canvas_ClientSecret") {
      setCanvas_ClientSecret(event.target.value);
    } else if (event.target.name === "Canvas_BaseURL") {
      setCanvas_BaseURL(event.target.value);
    }
  };

  const handleConnection = async () => {
    if (
      !Canvas_ClientID ||
      !Canvas_RefreshToken ||
      !Canvas_ClientSecret ||
      !Canvas_BaseURL
    ) {
      toast.warning("Fill Required Fields");
      return false;
    }
    const ToPost = {
      platformId: 2,
      client_id: Canvas_ClientID,
      refresh_token: Canvas_RefreshToken,
      client_secret: Canvas_ClientSecret,
      baseurl: Canvas_BaseURL,
    };
    const response = await axios
      .patch(
        BaseUrl + "/Platform/UpdateCanvasConnection/" + props.ConnectionId,
        ToPost,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .catch((error) => console.log("Error: ", error));
    if (response.data === true && response.status === 200) {
      toast.success("Updated successfully", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
      props.setReloadTable(Math.random());
      //window.location.href='/DataInput/Directory'
    } else {
      toast.error("Incorrect credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      {props.OnlyButton ? (
        <div className={`w-100  p-1`}>
          <Button
            className="rounded-pill fw-semibold fs-7 mx-1 w-100"
            variant="primary"
            onClick={handleShow}
          >
            Update Connection
          </Button>
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header className="p-3 pb-0" closeButton></Modal.Header>
            <Modal.Body className="px-4">
              <div className="d-flex flex-column align-items-center w-100">
                <img src={canvas} className="w-25 mb-4" />
                <div className="w-100">
                  <Form.Control
                    type="text"
                    className=" p-3 py-2 rounded-pill w-100 mb-3"
                    placeholder="Client ID"
                    name="Canvas_ClientID"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="text"
                    className="p-3 py-2 rounded-pill w-100 mb-4"
                    placeholder="Refresh Token"
                    name="Canvas_RefreshToken"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="text"
                    className="p-3 py-2 rounded-pill w-100 mb-4"
                    placeholder="Client Secret"
                    name="Canvas_ClientSecret"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="text"
                    className="p-3 py-2 rounded-pill w-100 mb-4"
                    placeholder="Base URL"
                    name="Canvas_BaseURL"
                    onChange={handleChange}
                  />
                  <Button
                    className="main-button w-100 fw-semibold rounded-pill py-2"
                    variant=""
                    onClick={handleConnection}
                  >
                    Link Account
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div>
          <Button
            className="p-0"
            variant=""
            onClick={props.IsConnected ? Redirect : handleShow}
          >
            <div className="d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3">
              <div className="rounded-2 d-flex justify-content-center bg-body-secondary w-20 p-3 mb-2">
                <img src={canvas_icon} />
              </div>
              <span className="fs-5 fw-semibold mb-1">Canvas</span>
              <span className="fs-7 text-start">
                A web-based learning management system, or LMS.
              </span>
            </div>
          </Button>
          <Modal centered show={show} onHide={handleClose}>
            <Modal.Header className="p-3 pb-0" closeButton></Modal.Header>
            <Modal.Body className="px-4">
              <div className="d-flex flex-column align-items-center w-100">
                <img src={canvas} className="w-25 mb-4" />
                <div className="w-100">
                  <Form.Control
                    type="text"
                    className=" p-3 py-2 rounded-pill w-100 mb-3"
                    placeholder="Client ID"
                    name="Canvas_ClientID"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="text"
                    className="p-3 py-2 rounded-pill w-100 mb-4"
                    placeholder="Refresh Token"
                    name="Canvas_RefreshToken"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="text"
                    className="p-3 py-2 rounded-pill w-100 mb-4"
                    placeholder="Client Secret"
                    name="Canvas_ClientSecret"
                    onChange={handleChange}
                  />
                  <Form.Control
                    type="text"
                    className="p-3 py-2 rounded-pill w-100 mb-4"
                    placeholder="Base URL"
                    name="Canvas_BaseURL"
                    onChange={handleChange}
                  />
                  <Button
                    className="main-button w-100 fw-semibold rounded-pill py-2"
                    variant=""
                    onClick={handleConnection}
                  >
                    Link Account
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}

export default Canvas;
