import React, { useState } from "react";
import TableXls from "../../components/tablelist/TableXls";
import { Button, Collapse } from "react-bootstrap";
import TableBb from "../tablelist/TableBb";
import TableOffice from "../tablelist/TableOffice";
import TableCanvas from "../tablelist/TableCanvas";
import { Link } from "react-router-dom/cjs/react-router-dom";

const TableList = ({
  setViewDataFilterTable,
  defaultClose,
  setAddTable,
  AddTableToWorkPlace,
  CreateRelationship,
  syncTable,
  tablesList,
  setViewTable,
  setViewDataFieldsTable,
  IsViewTable,
  runValidation,
  setTablesList,
  WithCheckbox,
  TableIds,
  setTablesArray,
  TablesArray,
  setReloadLists,
}) => {
  const [open, setOpen] = useState(defaultClose ? true : false);
  return (
    <div className=" d-flex flex-column justify-content-between h-100 ">
      <Button
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant=""
        className="w-100 p-0"
      >
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className="d-flex align-items-center">
            <button className="btn p-0 arrow-collapse">
              <i className="edu ic-down fs-10 mx-2"></i>{" "}
            </button>
            <span className="fs-7 fw-medium text-dark third">TABLES</span>
          </div>
        </div>
      </Button>
      <Collapse in={!open} className="collapse-content">
        <div className="">
          {tablesList &&
            tablesList.map((item, index) => (
              <TableXls
                key={index}
                setViewDataFieldsTable={setViewDataFieldsTable}
                setViewTable={setViewTable}
                tableId={item.tableId}
                Title={item.tableName}
                IsPlatformTable={item.tableType}
                TableFileType={item.fileType}
                Index={index}
                IsViewTable={IsViewTable}
                runValidation={runValidation}
                syncTable={syncTable}
                setTablesList={setTablesList}
                WithCheckbox={WithCheckbox}
                TableIds={(checked, value) => TableIds(checked, value)}
                setTablesArray={setTablesArray}
                TablesArray={TablesArray}
                setReloadLists={setReloadLists}
                IsGroup={false}
                GId={item.tableId}
                SyncingNow={item.syncing}
                CreateRelationship={CreateRelationship}
                setAddTable={setAddTable}
                AddTableToWorkPlace={AddTableToWorkPlace}
                setViewDataFilterTable={setViewDataFilterTable}
              />
            ))}
        </div>
      </Collapse>
    </div>
  );
};

export default TableList;
