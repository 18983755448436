import React, { useEffect, useState, useContext } from "react";
import Actions from "../components/relationship/NewActions";
import TableList from "../components/UploadedTable/TableList";
import GetBaseUrl from "../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RelationshipWorkSpace from "./RelationshipWorkSpace";
import { IndexContext } from "../components/layout/Layout";

const CreateRelation = () => {
  const IndexProvider = useContext(IndexContext);
  IndexProvider.setPathName("Relationships");

  const [tablesList, setTablesList] = useState([]);
  const [addTable, setAddTable] = useState();
  const [reloadLists, setReloadLists] = useState(true);
  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );
  const [Work_Tables, setWork_Tables] = useState([]);
  const [MainJsonBody, setMainJsonBody] = useState([]);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [newName, setNewName] = useState("");
  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  //----------------------------------------------------
  function FindObj(array, Key, Value) {
    var msg = "";
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        // // console.log('Found: '+array[i].TableTitle );
        //delete array[i]
        array.splice(i, 1);
        msg = "Table is exist";
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------------
  function AddTableToWorkPlace(TableId) {
    let WT = [...Work_Tables];
    let Found = FindObj(WT, "TableId", TableId);

    if (TableId && !Found) {
      axios
        .get(BaseUrl + "/Data/GetColumnsByTable/" + TableId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          WT.push({
            TableId: TableId,
            TableTitle: res.data.tableName,
            TableBody: res.data.columns,
          });
        })
        .finally(() => {
          setWork_Tables(WT);
        });
    } else if (TableId && Found) {
      toast.warning(Found, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  //----------------------------------------------------
  useEffect(() => {
    // // console.log(`MainJsonBody`);
    // // console.log(MainJsonBody);
    // // console.log(`MainJsonBody`);
  }, [MainJsonBody]);
  //----------------------------------------------------

  useEffect(() => {
    let list;
    axios
      .get(BaseUrl + "/Data/GetTablesAll", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        list = res.data.sort((a, b) => a.apiId - b.apiId);
        setTablesList(list);
      })
      .finally(() => {
        setReloadLists(false);
      });
  }, []);

  //----------------------------------------------
  function saveNewRelationship(name) {
    let Body = [...MainJsonBody];
    let obj = {
      relationShipName: name,
      links: Body,
    };

    // console.log(obj);
    if (!name) {
      toast.error("Relationship name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (obj.links.length < 1) {
      toast.error("One Relationship is mandatory at least", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    axios
      .post(BaseUrl + "/Relations/CreateRelationship", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Saved successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setMainJsonBody([]);
        setWork_Tables([]);
        setNewName("");
        setTimeout(function () {
          window.location.href = "/NewRelationships";
        }, 3000);
      });
  }
  //----------------------------------------------
  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-4 col-xl-3 px-0 pe-3">
            <div className="top-bg p-3 rounded-5 min-vh-100">
              <div className="row d-flex flex-column h-100">
                <div className="col-6 w-100">
                  <TableList
                    renderItem={renderItem}
                    tablesList={tablesList}
                    setAddTable={setAddTable}
                    CreateRelationship={true}
                    AddTableToWorkPlace={AddTableToWorkPlace}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-9 px-0 ">
            <div className="rel-header">
              <Actions
                saveNewRelationship={saveNewRelationship}
                newName={newName}
                handleChange={handleChange}
              />
            </div>

            <div className="rel-area">
              <RelationshipWorkSpace
                MainJsonBody={MainJsonBody}
                setMainJsonBody={setMainJsonBody}
                Work_Tables={Work_Tables}
                setWork_Tables={setWork_Tables}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRelation;
