import React, { useEffect, useState, useContext, createContext } from "react";

import styles from "./Layout.module.scss";

import Sidebar from "../sidebar/Sidebar";
import TopNav from "../topnav/TopNav";
import Routes from "../Routes";

import { BrowserRouter, Route, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import GetBaseUrl from "../../functions/SetBaseUrl";
import CheckSession from "../../functions/CheckSession";

import ThemeAction from "../../redux/actions/ThemeAction";
import LoginPage from "../../pages/Login";
import IndexStyles from "../../assets/css/index.module.scss";
import "./layout.css";
import {
  FindMultipleInObj,
  FindObj,
  FindIndexInObj,
  RandomNumberInRange,
  FindDeleteInObj,
} from "../../functions/FunctionsStore";
export const FunctionsStore = createContext();

const AuthToken = CheckSession(GetBaseUrl()[1]);
export const IndexContext = createContext(null);

const Layout = () => {
  const [pathName, setPathName] = useState("Dashboard");

  const themeReducer = useSelector((state) => state.ThemeReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    const themeClass = localStorage.getItem("themeMode", "theme-mode-light");
    const colorClass = localStorage.getItem("colorMode", "theme-mode-light");
    dispatch(ThemeAction.setMode(themeClass));
    dispatch(ThemeAction.setColor(colorClass));
  }, [dispatch]);

  const [collapsedNavbar, setCollapsedNavbar] = useState(
    window.innerWidth > 1200 ? false : true
  );

  window.addEventListener("resize", () => {
    window.innerWidth > 1200
      ? setCollapsedNavbar(false)
      : setCollapsedNavbar(true);
  });
  useEffect(() => {
    //console.log(collapsedNavbar);
  }, [collapsedNavbar]);

  return (
    <BrowserRouter>
      <IndexContext.Provider
        value={{ setPathName: setPathName, indexStyle: IndexStyles }}
      >
        <FunctionsStore.Provider
          value={[
            RandomNumberInRange,
            FindIndexInObj,
            FindObj,
            FindMultipleInObj,
            FindDeleteInObj,
          ]}
        >
          <Route
            render={(props) => (
              <>
                {AuthToken ? (
                  <div
                    className={`${styles["responsive-layout"]} theme-mode-light theme-color-blue`}
                  >
                    <Sidebar
                      collapsedNavbar={collapsedNavbar}
                      setCollapsedNavbar={setCollapsedNavbar}
                      IndexStyles={IndexStyles}
                      {...props}
                    />
                    <div className={`${styles["responsive-layout-content"]}`}>
                      <TopNav
                        pathName={pathName}
                        setCollapsedNavbar={setCollapsedNavbar}
                        collapsedNavbar={collapsedNavbar}
                      />
                      <div
                        className={`${styles["responsive-layout-content-main"]}`}
                      >
                        <Routes />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <LoginPage />
                  </div>
                )}
              </>
            )}
          />
        </FunctionsStore.Provider>
      </IndexContext.Provider>
    </BrowserRouter>
  );
};

export default Layout;
