import React, { useState, useEffect } from "react";
import Select from "react-select";
import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";
const BaseUrl = GetBaseUrl()[0];

function SelectDataTypes(props) {
  const [selectedOption, setSelectedOption] = useState(props.defaultSelected);
  const [dataOptions, setDataOptions] = useState(null);

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  useEffect(() => {
    let tempOptions = [];
    axios
      .get(BaseUrl + "/Settings/GetDataTypes", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        res.data.map((item) =>
          tempOptions.push({
            value: item.dataTypeId,
            text: item.displayName,

            columnId: props.ColumnId,
            dataType: item.dataTypeId,
          })
        );
      })
      .finally(() => {
        setDataOptions(tempOptions);
      });

    axios
      .get(
        BaseUrl +
          "/Data/GetColumnFieldData/" +
          props.TableId +
          "?ColumnId=" +
          props.ColumnId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        res.data.map((item) =>
          // props.LoadDefaultDataProp(item.dataTypeId,item.columnId,item.dataType)
          setSelectedOption({
            value: item.dataTypeId,
            text: item.dataType,
          })
        );
      });
  }, []);

  //------------------------------------------------------
  useEffect(() => {
    // console.log(props.currentColumnDataType);
    let array = [...props.currentColumnDataType];
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element && element.columnId === props.ColumnId) {
        setSelectedOption(element);
      }
    }
  }, [props.currentColumnDataType]);
  // handle onChange event of the dropdown
  const handleChange = (e) => {
    e["restrictionTypes"] = [];
    props.setAssignedColumn(e);

    props.setCurrentColumnDataType([...props.currentColumnDataType, e]);

    axios
      .get(
        BaseUrl +
          "/Data/GetDataPropertiesById/" +
          props.ColumnId +
          "?DataTypeId=" +
          e.value,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.data) {
          props.AllDataProp[props.ColumnId] = res.data;
          props.setByDataType([
            ...props.ByDataType,
            { ColumnId: props.ColumnId, Properties: res.data },
          ]);
          //console.log(res.data);
        }
      })
      .finally(() => {
        // props.setReloadColumnProp(Math.random);
      });
  };

  return (
    <div className="border-0 fs-7 p-0">
      <Select
        placeholder="Select Data Types"
        value={selectedOption}
        options={dataOptions}
        onChange={handleChange}
        menuPosition="fixed"
        getOptionLabel={(e) => (
          <div
            className="fs-7 py-1 dataselect"
            style={{ display: "flex", alignItems: "center" }}
          >
            {e.icon}
            <span className="px-1 py-0" style={{ marginLeft: 5 }}>
              {e.text}
            </span>
          </div>
        )}
      />
    </div>
  );
}

export default SelectDataTypes;
