import { React, useEffect, useState } from "react";
import Output from "./Output";
import ScheduleRun from "../modals/ScheduleRun";

import { Form } from "react-bootstrap";

import SelectRelationship from "../modals/SelectRelationship";

const OutputList = (props) => {
  return (
    <div className=" d-flex flex-column justify-content-between h-100 px-0">
      <div className="">
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className="d-flex align-items-center">
            <span className="fs-7 fw-medium third text-dark">REPORTS</span>
          </div>
          <SelectRelationship
            setSelectedRelation={props.setSelectedRelation}
            setEditReportId={props.setEditReportId}
            setViewTable={props.setViewTable}
          />
        </div>

        {props.tablesList &&
          props.tablesList.map((item, index) => (
            <Output
              key={index}
              setViewTable={props.setViewTable}
              Id={item.relId}
              RId={item.relationshipId}
              Title={item.reportName}
              setReload={props.setReload}
              syncReport={props.syncReport}
              setSelectedRelation={props.setSelectedRelation}
              setEditReportName={props.setEditReportName}
              setEditReportId={props.setEditReportId}
              EditReportIdHead={props.EditReportIdHead}
            />
          ))}
      </div>
    </div>
  );
};

export default OutputList;
