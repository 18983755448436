import React from 'react'
import PlatformFiles from './PlatformFiles'
import blackboard from '../../assets/svg/blackboard.svg'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import Rename from '../modals/Rename'


function FilesBar() {
  return (
    <div className=' d-flex flex-column justify-content-between h-100 px-2'>
    <div className='mb-5'>
            <div className='mb-3'>
                <div className='d-flex flex-column'>
                    <div className='d-flex justify-content-between mb-1'>
                      <img src ={blackboard} className='w-30'/>
                      <div className=''>
                      <Dropdown as={ButtonGroup}>
                      <Dropdown.Toggle split variant="" id="dropdown-split-basic" className='edu ic-more p-0' />
                      <Dropdown.Menu className='fs-7 more-drop'>
                        <Dropdown.Item href="#/action-3" className='p-1 ps-3'><Rename/></Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item href="#/action-4" className=' p-1 ps-3'>Disconnect</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                    </div>
                    <span className='fs-7 third'>8 Files</span>
                </div>
            </div>
           <PlatformFiles/>
           <PlatformFiles/>
           <PlatformFiles/>
           <PlatformFiles/>
           <PlatformFiles/>
           <PlatformFiles/>
           <PlatformFiles/>
           <PlatformFiles/>
     </div>
    </div>
  )
}

export default FilesBar
