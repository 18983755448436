import { React, useEffect, useState } from "react";
import { Form } from "react-bootstrap";

import { ButtonGroup, Dropdown } from "react-bootstrap";
import Rename from "../modals/RenameReport";
import Delete from "../modals/DeleteReport";
import ScheduleRun from "../modals/ScheduleRun";
import Undo from "../modals/Undo";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Output = (props) => {
  const [checked, setChecked] = useState(false);
  //------------------------------------------------------
  const handleChangeOption = (event) => {
    let isChecked = event.target.checked;

    console.log(isChecked);
    setChecked(isChecked);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-content-center my-2 query ">
        <div
          className="d-flex align-items-center w-100 pointer"
          onClick={() => {
            props.setViewTable(props.Id);
          }}
        >
          <i className="edu ic-output-tag p-1 fs-7 "></i>
          <span className="fs-7 " title={props.Title}>
            {" "}
            {props.Title && props.Title.length <= 40
              ? props.Title
              : props.Title && props.Title.substr(0, 40) + "..."}{" "}
          </span>
        </div>
        <div className="d-flex justify-between-end align-items-center">
          <Dropdown>
            <Dropdown.Toggle
              variant=""
              id="dropdown-basic"
              className="edu ic-more p-0"
            />

            <Dropdown.Menu className="fs-7 query-more">
              <Dropdown.Item
                className="p-1  ps-3 w-100"
                onClick={() => {
                  props.setViewTable(props.Id);
                }}
              >
                <i className="edu ic-run"> </i>View Report
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item
                className="p-1  ps-3 w-100"
                onClick={() => {
                  props.setSelectedRelation(props.RId);
                  props.setEditReportName(props.Title);
                  props.setEditReportId(props.Id);
                }}
              >
                <i className="edu ic-edit-query"> </i>Edit Report
              </Dropdown.Item>
              <Dropdown.Divider />
              <div className="p-1  ps-3 w-100">
                <ScheduleRun ReportId={props.Id} />
              </div>
              <Dropdown.Divider />
              <Dropdown.Item
                className="p-1  ps-3 w-100"
                onClick={() => {
                  props.syncReport(props.Id);
                }}
              >
                <i className="edu ic-sync"> </i>Sync
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className="p-1  ps-3 w-100">
                <Rename
                  ReportId={props.Id}
                  setReload={props.setReload}
                  setViewTable={props.setViewTable}
                  Title={props.Title}
                />
              </Dropdown.Item>
              <Dropdown.Divider />
              <Dropdown.Item className=" p-1  ps-3 w-100">
                <Delete
                  Id={props.Id}
                  setReload={props.setReload}
                  setViewTable={props.setViewTable}
                />
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default Output;
