import React, { useState } from "react";

import TablesLink from "../modals/TablesLink";
import AllTablesLinks from "../modals/AllTablesLinks";
import Button from "react-bootstrap/Button";

const Actions = (props) => {
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <input
              className="form-control border rounded-pill fs-6 fw-medium w-85"
              defaultValue={
                props.relationshipId ? props.RelationshipName : props.newName
              }
              type="text"
              placeholder="Add New Relationship Name"
              aria-label="default input example"
              onChange={props.handleChange}
            />
          </div>
          <div className="d-flex w-70 align-items-center justify-content-end">
            <div className="d-flex align-items-centers">
              {props.showLayout ? null : (
                <button
                  className="btn border-button rounded-pill fs-8 fw-semibold"
                  onClick={(e) => props.setResetFilter(Math.random(11))}
                >
                  Reset Filter
                </button>
              )}

              <Button
                className="border-button  mx-2 fs-7 d-flex align-items-center fw-semibold rounded-pill"
                variant=""
                onClick={() => {
                  props.showLayout
                    ? props.setShowLinks(true)
                    : props.setShowLayout(true);
                }}
              >
                <i className="edu ic-data-rel me-2 black-fill fs-6 "></i>{" "}
                {props.showLayout ? `All Links` : `Tables`}
              </Button>
            </div>

            {props.GenerateReport && props.Work_Tables.length > 0 ? (
              <></>
            ) : null}
            <button
              className="btn main-button w-20 p-2 rounded-pill ms-1 fs-7 fw-semibold"
              onClick={() => props.saveNewRelationship(props.newName)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
