import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./MultiBarGraph.module.scss";
import EditQuery from "../../../assets/images/EditQuery.svg";

const ChartsContainer = (props) => {
  const [chartOptions, setChartOptions] = useState({
    series: props.series,

    options: {
      chart: {
        type: props.Gtype,
        height: props.height,
        stacked: props.stacked,
        toolbar: {
          show: props.showtoolbar, // Disable the export and download options
        },
      },

      colors: props.colors, // Custom colors for each series
      plotOptions: {
        bar: {
          horizontal: props.horizontal ?? false,
          columnWidth: props.horizontal ? "2%" : "50%", // Make the bars thinner
          barHeight: props.horizontal ? "50%" : "40%", // Adjust the height percentage to make bars thinner
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: props.categories,
      },
      fill: {
        opacity: 1,
      },
    },
  });

  //-----------------------------------------------------------
  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: props.series,
      options: {
        ...prevOptions.options,
        xaxis: {
          ...prevOptions.options,
          categories: props.categories,
        },
      },
    }));
  }, [props.categories, props.series]);
  //-----------------------------------------------------------
  return (
    <>
      <div className={`${styles.container} ${props.tabs ? styles.tabbed : ""}`}>
        {props.withQuery && (
          <img
            src={EditQuery}
            onClick={() => {
              props.setAskQueryModal(true);
              props.setQueryIndex(props.queryId);
            }}
            className={`${styles["EditQuery"]}`}
            alt="EditQuery"
          />
        )}
        <h4 className={`${styles.title}`}>{props.title}</h4>
        <h6 className={`${styles.subTitle}`}>{props.subTitle}</h6>
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type={props.Gtype}
          height={props.height}
        />
      </div>
    </>
  );
};

export default ChartsContainer;
