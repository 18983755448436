import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  fromValue,
  toValue,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  minCriteriaPlaceholder,
  maxCriteriaPlaceholder,
  setRecallCriteria,
  recallCriteria,
  disabled,
}) {
  const [range, setRange] = useState([
    specialFunc(value, "min"),
    specialFunc(value, "max"),
  ]);

  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;

    let from = range[0];
    let to = range[1];
    let ch = e.target.attributes.rangeCriteriaAttr.value;
    let finalRangeType =
      document.getElementsByClassName("CriteriaType")[0]["name"];

    if (ch === "rangeFrom") {
      setMin(e.target.value);

      if (recallCriteria === "Inbetween" && to && e.target.value) {
        setRange([e.target.value, to]);
        val = e.target.value + "<>" + to;
      } else if (recallCriteria === "Inbetween" && !to && e.target.value) {
        setRange([e.target.value, 0]);
        val = e.target.value + "<>0";
      } else if (recallCriteria === "Inbetween" && !to && !e.target.value) {
        setRange(["", ""]);
        val = "";
      } else if (recallCriteria === "<" && e.target.value) {
        setRange([e.target.value, ""]);
        val = "<" + e.target.value;
      } else if (recallCriteria === ">" && e.target.value) {
        setRange([e.target.value, ""]);
        val = ">" + e.target.value;
      } else if (recallCriteria === "=" && e.target.value) {
        setRange([e.target.value, ""]);
        val = "=" + e.target.value;
      } else {
        setRange(["", ""]);
        val = "";
      }
    } else {
      setRange([from, e.target.value]);
      setMax(e.target.value);

      if (recallCriteria === "Inbetween" && e.target.value === "") {
        val = "";
      } else if ((recallCriteria === "Inbetween" && from) || e.target.value) {
        val = from + "<>" + e.target.value;
      }
    }
    res = name;

    let addObject = [
      {
        dataPropertyId: name,
        propertyValue: val,
      },
    ];
    val === "" &&
      addObject.push({ dataPropertyId: finalRangeType, propertyValue: "" });

    setAssignedDataPropForm(
      LastEdit(AssignedDataPropForm, "dataPropertyId", name, addObject)
    );
  };
  //----------------------------------------LastEdit
  function LastEdit(array, Key, Value, Obj) {
    let arr = [...array];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i][Key] === Value) {
        arr.splice(i, 1);
      }
    }
    return [...arr, ...Obj];
  }
  //----------------------------------------LastEdit

  function specialFunc(params, side = "") {
    let final = 0;

    if (params && recallCriteria === "Inbetween" && side === "min") {
      final = params.split("<>")[0];
    } else if (params && recallCriteria === "Inbetween" && side === "max") {
      final = params.split("<>")[1];
    } else if (params && recallCriteria !== "Inbetween" && side === "min") {
      final = params.substring(1);
    }
    return parseInt(final, 10);
  }

  //---------------------------------------------------
  useEffect(() => {
    // console.log(recallCriteria);

    if (recallCriteria === "none") {
      setMin(specialFunc(value, "min"));
      setMax(specialFunc(value, "max"));
    } else {
      setMin(specialFunc(value, "min"));
      setMax(specialFunc(value, "max"));
    }
  }, [recallCriteria]);

  return (
    <div className={disabled}>
      <div className="row d-flex1 align-items-center1 mb-3">
        <div className={"col-6 hide minCriteria"}>
          <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
            <Form.Control
              className="field-input2 rangeFromCriteria"
              rangeCriteriaAttr="rangeFrom"
              type="number"
              placeholder={minCriteriaPlaceholder}
              value={min}
              onChange={handleChange}
              name={Id}
            />
          </Form.Group>
        </div>

        <div className={"col-6 hide maxCriteria"}>
          <Form.Group className="mb-0" controlId="exampleForm.ControlInput1">
            <Form.Control
              className="field-input2  rangeToCriteria"
              rangeCriteriaAttr="rangeTo"
              type="number"
              placeholder={maxCriteriaPlaceholder}
              value={max}
              onChange={handleChange}
              name={Id}
            />
          </Form.Group>
        </div>
      </div>
    </div>
  );
}
export default Component;
