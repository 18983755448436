import React, { useRef, useState, useEffect } from "react";
import { Form, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import UpdateTable from "../modals/UpdateTable";
import Rename from "../modals/Rename";
import AssignToGroup from "../modals/AssignToGroup";
import Delete from "../modals/Delete";
import Unassign from "../modals/Unassign";
import canvas_ic from "../../assets/svg/canvas-ico.svg";
import office_ic from "../../assets/svg/office-ico.svg";
import bb_ic from "../../assets/svg/bb-ico.svg";
import aud from "../../assets/svg/aud-icon.svg";
import asi from "../../assets/svg/asi-icon.svg";
import { Rings } from "react-loader-spinner";
import csv from "../../assets/svg/csv.svg";
import xls from "../../assets/svg/xls_icon.svg";

const TableXls = ({
  setViewDataFilterTable,
  AddTableToWorkPlace,
  CreateRelationship,
  SyncingNow,
  GId,
  IsPlatformTable,
  setViewTable,
  setViewDataFieldsTable,
  Index,
  Title,
  tableId,
  IsViewTable,
  syncTable,
  runValidation,
  setTablesList,
  WithCheckbox,
  TableIds,
  setTablesArray,
  TablesArray,
  IsGroup,
  setReloadLists,
  TableFileType = "",
}) => {
  const [tableName, setTableName] = useState("");

  const divElement = useRef();
  function RemoveDiv() {
    divElement.current.remove();
  }

  const handleChange = (e) => {
    let a = [...TablesArray];
    let isChecked = e.target.checked;
    let value = e.target.defaultValue;
    if (isChecked) {
      a.push(value);
    } else {
      a = a.filter(function (item) {
        return item !== value;
      });
    }

    setTablesArray(a);
  };

  return (
    <div index={Index} ref={divElement}>
      <div className="d-flex justify-content-between align-content-center tbl my-2 ">
        <div
          className="d-flex align-items-center w-70 pointer"
          onClick={
            !CreateRelationship && !WithCheckbox
              ? () => {
                  setViewTable(tableId);
                  setViewDataFieldsTable("");
                  setViewDataFilterTable("");
                }
              : !CreateRelationship && WithCheckbox
              ? () => {}
              : () => AddTableToWorkPlace(tableId)
          }
        >
          {WithCheckbox ? (
            <>
              <Form.Check
                type="checkbox"
                defaultValue={tableId}
                onChange={(e) => handleChange(e)}
              />
              <span>&nbsp; &nbsp;</span>
            </>
          ) : (
            <>
              {IsPlatformTable === 1 ? (
                <img src={office_ic} className="p-2 w-15" alt="icon" />
              ) : IsPlatformTable === 2 ? (
                <img src={canvas_ic} className="p-2 w-15" alt="icon" />
              ) : IsPlatformTable === 3 ? (
                <img src={bb_ic} className="p-2 w-15" alt="icon" />
              ) : IsPlatformTable === 4 ? (
                <img src={aud} className="p-1 w-15" alt="icon" />
              ) : IsPlatformTable === 5 ? (
                <img src={asi} className="p-1 w-15" alt="icon" />
              ) : (
                <i className="edu ic-tbl fs-7 mx-2"></i>
              )}
            </>
          )}
          <span className="fs-7 text-truncate ">
            {" "}
            {tableName !== "" ? tableName : Title}{" "}
          </span>
        </div>

        {!WithCheckbox ? (
          <div className="d-flex align-items-center">
            <>
              <i>
                {SyncingNow && !CreateRelationship ? (
                  <Rings color="#05BC86" height={22} width={22} />
                ) : null}{" "}
              </i>

              {IsPlatformTable !== 0 ? (
                <i className="edu ic-link mx-1 main-filter fs-9"></i>
              ) : TableFileType === "xlsx" || TableFileType === "xls" ? (
                <img src={xls} className="mx-1" />
              ) : (
                <img src={csv} className="mx-1" />
              )}
            </>

            {!CreateRelationship ? (
              <Dropdown as={ButtonGroup}>
                <Dropdown.Toggle
                  split
                  variant=""
                  id="dropdown-split-basic"
                  className="edu ic-more p-0 d-flex"
                />
                <Dropdown.Menu className="fs-7 more-drop p-0">
                  <>
                    {IsPlatformTable ? (
                      <Dropdown.Item
                        className="p-1 ps-3 pt-2 w-100"
                        onClick={() => syncTable(GId, tableId)}
                      >
                        <i className="edu ic-sync"> </i>Sync
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item className="p-1 ps-3 w-100">
                        {" "}
                        <UpdateTable
                          TableId={tableId}
                          setViewTable={setViewTable}
                        />
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {IsGroup ? (
                      <Dropdown.Item className="p-1 ps-3 w-100">
                        <Unassign
                          TableId={tableId}
                          Group={IsGroup}
                          setReloadLists={setReloadLists}
                          setTableName={setTableName}
                          Title={Title}
                        />
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item className="p-1 ps-3 w-100">
                        <AssignToGroup
                          TableId={tableId}
                          setTableName={setTableName}
                          setReloadLists={setReloadLists}
                          Title={Title}
                        />
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="p-1 ps-3 w-100"
                      onClick={() => {
                        setViewTable(tableId);
                        setViewDataFieldsTable("");
                        setViewDataFilterTable("");
                      }}
                    >
                      <i className="edu ic-table"> </i>View Table
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="p-1 ps-3 w-100"
                      onClick={() => {
                        setViewDataFieldsTable(tableId);
                        setViewTable("");
                      }}
                    >
                      <i className="edu ic-edit-group"> </i>View Data Fields
                    </Dropdown.Item>
                  </>

                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="p-1 ps-3 w-100"
                      onClick={() => runValidation(tableId)}
                    >
                      <i className="edu ic-run"> </i>Run Validation
                    </Dropdown.Item>
                  </>

                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item className="px-0 ps-3">
                      <Rename
                        TableId={tableId}
                        setViewTable={setViewTable}
                        TableName={Title}
                        setTableName={setTableName}
                      />
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item className="px-0 ps-3">
                      <Delete
                        TableId={tableId}
                        setViewTable={setViewTable}
                        setReloadLists={setReloadLists}
                        RemoveDiv={RemoveDiv}
                      />
                    </Dropdown.Item>
                  </>
                </Dropdown.Menu>
              </Dropdown>
            ) : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default TableXls;
