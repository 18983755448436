import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
// import "./modal.css";
import DragDropFile from "../../components/UploadDocs/DragDropFile";

function UpdateTable(props) {
  const [show, setShow] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [updateType, setUpdateType] = useState("OverrideUploadFile");
  const [checked, setChecked] = useState("over");

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    // console.log(checked);
    setChecked("over");
    setUpdateType("OverrideUploadFile");
  }, [show]);

  const handleChange = (e) => {
    let name = e.target.name;
    setChecked(name);
    if (name === "over") {
      setUpdateType("OverrideUploadFile");
    } else {
      setUpdateType("AddOnUploadFile");
    }
  };

  return (
    <div>
      <Button
        className="p-0 fs-7 mt-2 w-100 d-flex align-items-center j"
        variant=""
        onClick={handleShow}
      >
        <i className="edu ic-upload"> </i>
        <span className="mx-1">Update</span>
      </Button>

      <Modal size="lg" centered show={show} onHide={handleClose}>
        <Modal.Header className="p-3 pb-0" closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold">Update Table</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="mb-3 d-flex">
              <Form.Check // prettier-ignore
                type={"radio"}
                label={`Overwrite Data`}
                name="over"
                onChange={handleChange}
                checked={checked === "over" ? true : false}
                value={"over"}
                className="updateTable"
              />

              <Form.Check // prettier-ignore
                type={"radio"}
                label={`Add-on Data`}
                name="add"
                onChange={handleChange}
                checked={checked === "add" ? true : false}
                value={"add"}
                className="mx-2 updateTable"
              />
            </div>
          </div>

          <DragDropFile
            NeedConfirmUpload
            TableId={props.TableId}
            Update
            UpdateType={updateType}
            confirmed={confirmed}
            setConfirmed={setConfirmed}
            setShow={setShow}
            setViewTable={props.setViewTable}
            noDirectory
          />
        </Modal.Body>
        <Modal.Footer className="border-0  d-flex justify-content-end">
          <Button
            className="main-button w-25 fw-semibold rounded-pill py-2 "
            variant=""
            onClick={() => {
              setConfirmed(true);
              handleClose();
            }}
          >
            Update Table
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default UpdateTable;
