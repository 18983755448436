import React, { useEffect, useState, useContext } from "react";
import GetBaseUrl from "../functions/SetBaseUrl";
import styles from "./DashboardElements/Charts/MultiBarGraph.module.scss";

import MultiGraphQuery from "./DashboardElements/Graphs/MultiGraphQuery";
import GraphQuery from "./DashboardElements/Graphs/GraphQuery";
import DashboardHeader from "./DashboardElements/DashboardHeader";
import DashboardStatics from "./DashboardElements/DashboardStatics";
import "./DashboardElements/Dashboard.css";
import { TableComponent } from "./DashboardElements/Charts/Table";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserType1 from "../assets/svg/Dashboard/UserType1.svg";
import UserType2 from "../assets/svg/Dashboard/UserType2.svg";
import UserType3 from "../assets/svg/Dashboard/UserType3.svg";
import UserType4 from "../assets/svg/Dashboard/UserType4.svg";

import ActiveStar from "../assets/svg/Dashboard/ActiveStar.svg";
import InactiveStar from "../assets/svg/Dashboard/InactiveStar.svg";
import { IndexContext } from "../components/layout/Layout";

import axios from "axios";
const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Dashboard = () => {
  const IndexProvider = useContext(IndexContext);
  IndexProvider.setPathName("Dashboard");

  const [tabIndex, setTabIndex] = useState(340);
  //----------------------------------------------------
  const [tabs, setTabs] = useState([
    {
      index: 340,
      title: "Student Feedback",
      width: "18%",
      active: true,
    },
    {
      index: 350,
      title: "Educators Feedback",
      width: "18%",
      active: false,
    },
    {
      index: 370,
      title: "Training Feedback",
      width: "18%",
      active: false,
    },
  ]);

  //---------------------------------------------------
  function setActiveTab(arr, Value) {
    const array = [...arr];
    for (var i = 0; i < array.length; i++) {
      array[i]["active"] = false;
    }

    for (var i = 0; i < array.length; i++) {
      if (array[i].title === Value) {
        array[i].active = true;
        setTabIndex(array[i].index);
        break;
      }
    }

    setTabs(array);
  }

  const [graphs, setGraphs] = useState([]);
  const [multiGraphs, setMultiGraphs] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [reloadDashboard, setReloadDashboard] = useState(0);

  let queryStructures1_1 = `Select
  Count
  Group By - Created_at`;

  let queryStructures1_2 = `Select
  Count
  Group By - Created_at`;

  let queryStructures2_1 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures2_2 = `Select
  Count - Distinct(User_name)
  Group By - Grade_level`;

  let queryStructures3_1 = `Select
  Count
  Group By - visitdate`;

  let queryStructures3_2 = `Select
  Count
  Where - Trainingtype='True'
  Group By - visitdate`;

  let queryStructures4 = `Select -  Visitdate, Schoolname,  SUM(Teachercount,Sltcount,Parentcount,Studentcount) Attendees, Rating
Order By - VisitId`;
  const [queryStructures, setQueryStructures] = useState([
    {
      options: [queryStructures1_1, queryStructures1_2],
    },
    {
      options: [queryStructures2_1, queryStructures2_2],
    },
    {
      options: [queryStructures3_1, queryStructures3_2],
    },
    {
      options: [queryStructures4],
    },
  ]);

  const [queryResult, setQueryResult] = useState([]);
  const [editMode, setEditMode] = useState(false);

  //----------------------------------------------
  const viewQueryResult = () => {
    setGraphs([]);
    getGraphResult([301, 302, 311, 310, 320, 321, 330]);
  };

  const viewMultiQueryResult = (array) => {
    setMultiGraphs([]);
    getGraphResult(array, true);
  };
  //---------------------------------------------------
  const getGraphResult = async (graphIndex, multi = false) => {
    let fullArray = [];
    for (let i = 0; i < graphIndex.length; i++) {
      const element = graphIndex[i];

      let obj = {
        queryId: element,
        // Period: 0,

        filterData: selectedFilters,
      };
      try {
        const response = await axios.post(
          BaseUrl + "/Report/GetDashboardReportQueryData",
          obj,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        );

        fullArray.push({ queryId: element, content: response.data });
      } catch (error) {
        // console.error("Error getGraphResult:", error);
        // throw error;
        fullArray.push({
          queryId: element,
          content: [],
        });
      }
    }
    if (multi) {
      setMultiGraphs(fullArray);
    } else {
      setGraphs(fullArray);
    }
  };
  //----------------------------------------------

  return (
    <div className="rounded-0 Dashboard-container min-vh-85">
      <DashboardHeader
        setEditMode={setEditMode}
        editMode={editMode}
        withQuery={editMode}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        viewQueryResult={viewQueryResult}
        setReloadDashboard={setReloadDashboard}
      />
      <DashboardStatics
        setEditMode={setEditMode}
        editMode={editMode}
        withQuery={editMode}
        selectedFilters={selectedFilters}
        reloadDashboard={reloadDashboard}
        setReloadDashboard={setReloadDashboard}
      />
      <div className="charts-container row">
        <GraphQuery
          height={417}
          title={""}
          widgetType={["LineDateGraph", "LineDateGraph"]}
          data={graphs}
          setEditMode={setEditMode}
          editMode={editMode}
          viewQueryResult={viewQueryResult}
          index={[301, 302]}
          titles={["Count of Prompts", "Count of Conversations"]}
          reloadDashboard={reloadDashboard}
          graphType="area"
          queryStructures={queryStructures[0].options}
          seriesTitles={["Active"]}
          max={100}
        />

        <GraphQuery
          height={417}
          title={""}
          widgetType={["LineStackPercentageGraph", "LineStackGraph"]}
          data={graphs}
          setEditMode={setEditMode}
          editMode={editMode}
          viewQueryResult={viewQueryResult}
          index={[310, 311]}
          titles={["Active Students", "Active Students Count"]}
          reloadDashboard={reloadDashboard}
          graphType="bar"
          queryStructures={queryStructures[1].options}
          seriesTitles={["Active", "total"]}
          max={100}
          stacked
          pre={"Grade"}
        />

        <GraphQuery
          height={417}
          title={""}
          widgetType={["LineDateGraph", "LineDateGraph"]}
          data={graphs}
          setEditMode={setEditMode}
          editMode={editMode}
          viewQueryResult={viewQueryResult}
          queryResult={queryResult}
          index={[320, 321]}
          titles={["Visits Count", "Training Count"]}
          reloadDashboard={reloadDashboard}
          graphType="bar"
          queryStructures={queryStructures[2].options}
          seriesTitles={["Active"]}
          max={10}
        />

        <TableComponent
          tableTitle={"Visits / Contacting List"}
          widgetType="List"
          queryStructures={queryStructures[3].options}
          withQuery={editMode}
          index={[330]}
          viewQueryResult={viewQueryResult}
          queryResult={graphs}
        />

        {tabs && (
          <div className={`${styles.tabsContainer} row`}>
            {tabs.map((tab) => {
              return (
                <div
                  key={tab.title}
                  className={`${styles.tab} ${
                    tab.active ? styles.active : ""
                  } `}
                  // style={{ width: tab.width }}
                  onClick={() => setActiveTab(tabs, tab.title)}
                >
                  <span className={`${tab.active ? styles.active : ""}`}>
                    {tab.title}
                  </span>
                </div>
              );
            })}
          </div>
        )}

        <MultiGraphQuery
          height={417}
          setEditMode={setEditMode}
          editMode={editMode}
          viewQueryResult={viewQueryResult}
          viewMultiQueryResult={viewMultiQueryResult}
          data={multiGraphs}
          tabIndex={tabIndex}
          reloadDashboard={reloadDashboard}
        />
      </div>
    </div>
  );
};

export default Dashboard;
