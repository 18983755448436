import React, { useEffect, useState, useContext } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-toastify";
import logo from "../../src/assets/svg/logo.svg";
import bigLogo from "../../src/assets/svg/bigLogo.svg";
import poweredBy from "../../src/assets/svg/poweredBy.svg";
import GetBaseUrl from "../functions/SetBaseUrl";
import SaveSession from "../functions/SaveSession";
import CheckSession from "../functions/CheckSession";
import styles from "./Styles/LoginPage/Login.module.scss";

const AuthToken = CheckSession(GetBaseUrl()[1]);

const schema = yup.object().shape({
  password: yup.string().required("Enter password"),
});

const LoginPage = ({ setAuth }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const [u, setU] = useState("");
  const [p, setP] = useState("");
  const handleChange = (event, type) => {
    if (type === "u") setU(event.target.value);
    if (type === "p") setP(event.target.value);
  };

  const onSubmit = (data) => {
    const BaseUrl = GetBaseUrl()[2];

    axios
      .post(BaseUrl + "/auth/login", data)
      .then((res) => {
        if (res.data.authenticated) {
          // console.log(res.data);
          SaveSession(
            GetBaseUrl()[1],
            res.data.fullName,
            res.data.token,
            res.data.tokenExpiry,
            res.data.companyId,
            res.data.role,
            res.data.dashboardsPermissions,
            res.data.username

          );

          var filePath = "/";
          var link = document.createElement("a");
          link.href = filePath;
          link.click();
        } else {
          toast.error("Faild");
        }
      })

      .catch((e) => {
        toast.error("Username or Password is wrong");
      });
  };

  const keyboardEvents = (event) => {
    event.persist();

    if (event.keyCode === 13) onSubmit({ username: u, password: p });
  };

  return (
    <>
      {!AuthToken ? (
        <div className={`${styles["row"]}`}>
          <div className={`${styles["w-40"]}`}>
            <div className={`d-flex flex-column ${styles["form-container"]}`}>
              <img alt="logo" className={`${styles["Logo"]}`} src={logo} />
              <h3>Login to get started</h3>
              <div className={`${styles["input-container"]}`}>
                <h6>Email Address</h6>

                <Input
                  placeholder="Username"
                  {...register("username")}
                  className="rounded-pill p-4"
                  onChange={(e) => handleChange(e, "u")}
                  onKeyUp={keyboardEvents}
                />
                {errors.email && (
                  <p className="error-msg text-danger fs-8">
                    {errors?.username?.message}
                  </p>
                )}
              </div>

              <div className={`${styles["input-container"]}`}>
                <h6>Password</h6>
                <Input
                  placeholder="Password"
                  {...register("password")}
                  type="password"
                  error={!!errors.password}
                  className="rounded-pill p-4 "
                  onChange={(e) => handleChange(e, "p")}
                  onKeyUp={keyboardEvents}
                />
                {errors.password && (
                  <p className="error-msg text-danger fs-8">
                    {errors?.password?.message}
                  </p>
                )}
              </div>

              <button
                className="main-button p-2 fw-semibold rounded-pill"
                onClick={handleSubmit(onSubmit)}
              >
                Login
              </button>
              <h6 className={`${styles["forget-password"]}`}>
                Forgot Password?
              </h6>
              <div className={`${styles["poweredBy"]}`}>
                <h6>Powered By</h6>
                <img
                  alt="logo"
                  className={`${styles["poweredBy"]}`}
                  src={poweredBy}
                />
              </div>
            </div>
          </div>
          <div className={`${styles["w-60"]}`}>
            <img alt="logo" className={`${styles["bigLogo"]}`} src={bigLogo} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default LoginPage;

const MainWrapper = styled.section`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginBox = styled.div`
  background-color: rgba(0, 0, 0, 0.08);
  padding: 40px;
  border-radius: 10px;
  width: 90%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    width: 40vw;
  }

  img {
    margin-bottom: 20px;
  }

  button {
    background-color: #6366f2;
    width: 100%;
    text-align: center;
    padding: 10px 10px;
    font-size: 16px;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
  }

  .error-msg {
    font-size: 14px;
    padding: 0px;
    margin: 0px;
    color: red;
    text-align: left;
    width: 100%;
  }
`;

const Input = styled.input`
  width: 100%;
  height: 40px;
  margin-bottom: ${(props) => (props.error ? "0px" : "16px")};
  padding: 0px 10px;
  border-radius: 4px;
  outline: none;
  border: 1px solid grey;
`;
