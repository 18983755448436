import React, { useRef, useState, useEffect } from "react";
import { Form, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import Rename from "../../components/modals/Rename";
import Delete from "../../components/modals/Delete";
import canvas_ic from "../../assets/svg/canvas-ico.svg";
import office_ic from "../../assets/svg/office-ico.svg";
import bb_ic from "../../assets/svg/bb-ico.svg";
import aud from "../../assets/svg/aud-icon.svg";
import asi from "../../assets/svg/asi-icon.svg";

const PlatformTableItem = ({
  IsPlatformTable,
  setViewTable,
  setViewDataFieldsTable,
  Index,
  Title,
  tableId,
  IsViewTable,
  runValidation,
  setTablesList,
  WithCheckbox,
  TableIds,
  setTablesArray,
  IsGroup,
  setReloadLists,
  setViewConnection,
}) => {
  const handleChange = (e) => {};

  return (
    <div index={Index}>
      <div className="d-flex justify-content-between align-content-center tbl my-2">
        <div
          className="d-flex align-items-center w-70 pointer"
          onClick={() => {
            setViewTable(tableId);
          }}
        >
          <>
            {IsPlatformTable === "1" ? (
              <img src={office_ic} className="p-2" alt="icon" />
            ) : IsPlatformTable === "2" ? (
              <img src={canvas_ic} className="p-2" alt="icon" />
            ) : IsPlatformTable === "3" ? (
              <img src={bb_ic} className="p-2" alt="icon" />
            ) : IsPlatformTable === "4" ? (
              <img src={aud} className="p-1 w-15" alt="icon" />
            ) : IsPlatformTable === "5" ? (
              <img src={asi} className="p-1 w-15" alt="icon" />
            ) : (
              <i className="edu ic-tbl fs-7 mx-2"></i>
            )}
          </>

          <span className="fs-7 text-truncate "> {Title}</span>
        </div>

        <div className="d-flex align-items-center">
          <i className="edu ic-link mx-1 main-filter fs-9"></i>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              split
              variant=""
              id="dropdown-split-basic"
              className="edu ic-more p-0"
            />
            <Dropdown.Menu className="fs-7 more-drop">
              <Dropdown.Item
                className="p-1 ps-3"
                onClick={() => {
                  setViewTable(tableId);
                }}
              >
                <i className="edu ic-run"> </i>View Table
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default PlatformTableItem;
