import React, { useEffect, useState, useContext } from "react";
import DragDropFile from "../components/UploadDocs/DragDropFile";
import { IndexContext } from "../components/layout/Layout";

const UploadDocuments = () => {
  const IndexProvider = useContext(IndexContext);
  IndexProvider.setPathName("Upload Documents");

  return (
    <div className="rounded-5 small-pad-bg main-bg h-100 ">
      <DragDropFile
        NeedConfirmUpload={false}
        TableId=""
        Update={false}
        UpdateType=""
        confirmed={true}
      />
    </div>
  );
};

export default UploadDocuments;
