import {React, useEffect, useState } from "react";
import header from '../../assets/svg/header.svg'
import { Button } from 'react-bootstrap'

function MultiLink(props) {

  const [arr, setArr] = useState([]);
  let tableName = props.tableName

useEffect(() => { 

  let array=[...arr]
  if(props.Links){

        for (let i = 0; i < props.Links.length; i++) {
                const element = props.Links[i];
                if(element.ByColumnName && array.indexOf(element.ByColumnName) === -1){ 
                    array.push(element.ByColumnName)
                }
         }

    setArr(array)
    }



if(props.tableName && props.tableName.length>20) tableName= props.tableName.substring(0,20) +' ...'
 
},[props.Links])




  return (

    <div className='rel-card w-100 row rounded-1 '>
        <div className='rel-card_header card_header d-flex align-items-center justify-content-between pointer'>
          <span className='fw-semibold text-truncate fs-7 ' title={props.tableName}>{tableName}</span>
        </div>
        {!props.FirstLevel ?
        <div className='p-3 pt-2 fw-medium text-truncate fs-7'><img src = {header} className='me-2'/>{props.PrimaryColumn}
        <Button variant='' className='p-0 float-right'  onClick={() => props.removeFromRelationship(props.ByTableId, props.ByColumnId, props.ToTableId, props.ToColumnId)}><i className='edu ic-close fs-8'></i></Button>
        </div>
        :null}
        {arr && arr.map((a,index) => (
            <div key={index} className='p-3 pt-2 fw-medium text-truncate fs-7'><img src = {header} className='me-2'/>{a}
            </div>
        ))}                                                                                        
    </div>


  )
}

export default MultiLink
