import React, { useState, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import blackboard from '../../assets/images/blackboard.png'
import office from '../../assets/images/office360.png'
import directory from '../../assets/svg/directory.svg'
import canvas from '../../assets/images/canvas.png'
import Blackboard from './Blackboard';
import Office360 from './Office360';
import Canvas from './Canvas';
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


function ChooseDirectory() {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState(""); 
  
    const [Bb_Connection, setBb_Connection] = useState('');
    const [Canvas_Connection, setCanvas_Connection] = useState('');
    const [Office_Connection, setOffice_Connection] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");



useEffect(() => {
  
        axios
        .get( BaseUrl  +   '/Platform/GetPlatforms', {
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
        })
        .then((res) => {

          setBb_Connection(res.data[2].connected);
          setBb_Connection(true);
          setCanvas_Connection(res.data[1].connected);
          setOffice_Connection(res.data[0].connected);
          setOffice_Connection(true);
        });
       
}, []);
    
    

  return (
        <div>
            <Button className="shadow p-5 rounded-4 d-flex flex-row align-items-center justify-content-center mx-1 h-100 w-100" variant=""  >
                        <img src = {directory}/>
                        <span className='fw-medium fs-5 mx-3'>Choose Directory</span>
            </Button>
            <Modal centered size='xl' show={show} onHide={handleClose}>
                <Modal.Header className='p-3 pb-0' closeButton>
                <Modal.Title className='fs-5 fw-semibold'>Choose Directory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                      <div className='col-4'>
                      <Blackboard IsConnected={Bb_Connection} setBb_Connection={setBb_Connection}/>
                      </div>
                      <div className='col-4'>
                      <Office360 IsConnected={Office_Connection} setOffice_Connection={setOffice_Connection} />
                      </div>
                      <div className='col-4'>
                      <Canvas IsConnected={Canvas_Connection} setCanvas_Connection={setCanvas_Connection} />
                      </div>

                        
                    </div>
              
                </Modal.Body>
            </Modal>
        </div>
  )
}



export default ChooseDirectory
