import GetBaseUrl from "./SetBaseUrl";
import axios from "axios";

const API_BASE_URL = GetBaseUrl()[0];

export const GetAPIList = async (type, orderBy='') => {
    let url="";
    if(type==='tables'){ url="/Data/GetTables"; }
    if(type==='reports'){ url="/Report/GetSavedReports"; }
    let arr=[]
    let newArr=[]
    try {
        const response = await axios.get(`${API_BASE_URL}${url}`);
                if(orderBy){
        arr = response.data.sort((a, b) => a[orderBy] - b[orderBy]);
        }
        else{
         arr = response.data
        }
        arr.map((item) =>{
          type==='reports' ? newArr.push({Id: item.relId, Name:item.reportName})   : newArr.push({Id: item.tableId, Name:item.tableName})

        })
        return newArr;
    } catch (error) {
        console.error('Error fetching:', error);
        throw error;
    }
};


export const GetAPIById = async (type, id, pageIndex=1 , pageSize=10) => {
    let url="";
    if(type==='tables'){ url=`/Data/GetDataByTable/${id}/${pageIndex}/${pageSize}`; }
    if(type==='reports'){ url="/Report/GetReportById/"+id; }
    let arr=[]
    try {
        const response = await axios.get(`${API_BASE_URL}${url}`);
         arr = response.data
        return arr;
    } catch (error) {
        console.error('Error BYId:', error);
        throw error;
    }
};



export const GetReportColumnsById = async (id, pageIndex=1 , pageSize=1) => {
    let url="";
    url=`/Report/GetReportById/${id}/${pageIndex}/${pageSize}`   ;
    let arr=[]
    try {
        const response = await axios.get(`${API_BASE_URL}${url}`);
         arr = response.data.columns
         
        return arr;
    } catch (error) {
        console.error('Error BYId:', error);
        throw error;
    }
};



//-------------------------------------------------------
export const GetAPISavedStaticsQuery = async () => {

                let url= `/Report/GetReportQuery/statistics`; 
                try {
                    const response = await axios.get(`${API_BASE_URL}${url}`);

                  return response.data;
                  
                } catch (error) {
                    console.error('Error BYId:', error);
                    throw error;
                }    
};
export const GetAPISavedFilterQuery = async () => {

                let url= `/Report/GetReportQuery/table`; 
                try {
                    const response = await axios.get(`${API_BASE_URL}${url}`);

                  return response.data;
                  
                } catch (error) {
                    console.error('Error BYId:', error);
                    throw error;
                }    
};
export const GetAPISavedGraphQuery = async () => {

                let url= `/Report/GetReportQuery/report`; 
                try {
                    const response = await axios.get(`${API_BASE_URL}${url}`);

                  return response.data;
                  
                } catch (error) {
                    console.error('Error BYId:', error);
                    throw error;
                }    
};
//-------------------------------------------------------



export const GetAPIFilterData = async (filterColumn, pageIndex=1 , pageSize=10000) => {

    let url= `/Data/GetDistinctColumns/${filterColumn}/${pageIndex}/${pageSize}`; 
                let arr=[]
                try {
                    const response = await axios.get(`${API_BASE_URL}${url}`);
                    // console.log(response.data.data);
                    response.data.data.map((item) => 

                      item &&  arr.push({value: item, label: item})
                   )
                   return arr;
                  
                } catch (error) {
                    console.error('Error BYId:', error);
                    throw error;
                }    
};



