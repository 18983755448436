import React ,{useRef, useState, useEffect} from 'react'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import Rename from '../modals/RenameRelationship'
import AssignToProject from '../modals/AssignToProject'
import Delete from '../modals/DeleteRelationship'

const List = (props) => {



  return (
    <div  >
      <div className='d-flex justify-content-between align-content-center my-2 list'>
           <div className='d-flex align-items-center w-75 pointer' >
                <i className='edu ic-rel fs-11 mx-2'></i> 
                <span className='fs-7 text-truncate'>{props.RelationshipName}</span>
            </div>

           </div>
           
    </div>
  )
}

export default List
