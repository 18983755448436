import React, { useRef, useState, useEffect } from "react";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import Rename from "../modals/RenameRelationship";
import AssignToProject from "../modals/AssignToProject";
import Delete from "../modals/DeleteRelationship";

const List = (props) => {
  const divElement = useRef();
  function RemoveDiv() {
    divElement.current.remove();
  }

  return (
    <div ref={divElement}>
      <div className="d-flex justify-content-between align-content-center my-2 list">
        <div
          className="d-flex align-items-center w-100 pointer"
          onClick={() => props.viewRelationship(props.R_Id)}
        >
          <i className="edu ic-rel fs-11 mx-2"></i>
          <span className="fs-7 text-truncate">{props.Title}</span>
        </div>
        <div className="">
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle
              split
              variant=""
              id="dropdown-split-basic"
              className="edu ic-more p-0"
            />
            <Dropdown.Menu className="fs-7 more-drop">
              <Dropdown.Item
                className="p-1 ps-3 w-100"
                onClick={() => {
                  props.viewRelationship(props.R_Id);
                }}
              >
                <i className="edu ic-run"> </i>View
              </Dropdown.Item>
              <Dropdown.Divider />
              {/* <Dropdown.Item   className='p-1 ps-3 w-100'><i className='edu ic-upload'> </i>Create Output</Dropdown.Item>
                  <Dropdown.Divider /> */}

              <Dropdown.Item className="p-1 ps-3 w-100">
                <AssignToProject
                  R_Id={props.R_Id}
                  setNewName={props.setNewName}
                  Title={props.Title}
                />
              </Dropdown.Item>

              <Dropdown.Divider />

              <>
                <Dropdown.Item className="p-1 ps-3 w-100">
                  <Rename
                    R_Id={props.R_Id}
                    Title={props.Title}
                    setNewName={props.setNewName}
                    setRelationshipName={props.setRelationshipName}
                    setReload={props.setReload}
                  />
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="p-1 ps-3 w-100">
                  <Delete
                    setReload={props.setReload}
                    R_Id={props.R_Id}
                    setRelationshipName={props.setRelationshipName}
                    setMainJsonBody={props.setMainJsonBody}
                    setWork_Tables={props.setWork_Tables}
                    setNewName={props.setNewName}
                    RemoveDiv={RemoveDiv}
                    setRelationshipId={props.setRelationshipId}
                  />
                </Dropdown.Item>
              </>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default List;
