import { React, useEffect, useState } from "react";
import Actions from "../components/relationship/Actions";
import TableList from "../components/UploadedTable/TableList";
import AllTablesLinksContainer from "../components/modals/AllTablesLinksContainer";
import RelationshipLayout from "../components/relationship/RelationshipLayout";
import GetBaseUrl from "../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const CreateRelation = () => {
  const [groupsList, setGroupsList] = useState([]);
  const [tablesList, setTablesList] = useState([]);
  const [addTable, setAddTable] = useState();
  const [reloadLists, setReloadLists] = useState(true);
  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );
  const [tableName, setTableName] = useState("");
  const [tableHead, setTableHead] = useState([]);
  const [tableHeadIds, setTableHeadIds] = useState([]);
  const [Work_Tables, setWork_Tables] = useState([]);
  const [MainJsonBody, setMainJsonBody] = useState([]);

  const [showLayout, setShowLayout] = useState(true);
  const [showLinks, setShowLinks] = useState(false);
  const [resetFilter, setResetFilter] = useState(0);
  const [ResetNow, setResetNow] = useState(0);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [newName, setNewName] = useState("");
  const handleChange = (event) => {
    setNewName(event.target.value);
  };

  useEffect(() => {
    if (showLayout) setShowLinks(false);
  }, [showLayout]);
  useEffect(() => {
    if (showLinks) setShowLayout(false);
  }, [showLinks]);

  //----------------------------------------------------
  function FindDeleteObj(array, Key, Value) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        array.splice(i, 1);
      }
    }
  }
  //----------------------------------------------------
  function FindObj(array, Key, Value) {
    var msg = "";
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        // console.log('Found: '+array[i].TableTitle );
        //delete array[i]
        array.splice(i, 1);
        msg = "Table is exist";
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------------
  function AddTableToWorkPlace(TableId) {
    let tableHead = [];
    let tableHeadIds = [];
    let Sequence = 0;
    let TN = "";
    let WT = [...Work_Tables];
    let MJB = [...MainJsonBody];
    let Found = FindObj(WT, "TableId", TableId);

    if (TableId && !Found) {
      axios
        .get(BaseUrl + "/Data/GetColumnsByTable/" + TableId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          TN = res.data.tableName;

          res.data.columns.map((item, index) => tableHead.push(item));
          res.data.columns.map((item, index) => tableHeadIds.push(item));
        })
        .finally(() => {
          setTableHead(tableHead);
          setTableHeadIds(tableHeadIds);

          Sequence = MainJsonBody.length + 1;
          MJB.push({
            tableId: TableId,
            tableName: TN,
            columnId: "TN1",
            reference: [],
            tableColumns: tableHeadIds,
            Sequence: Sequence,
          });

          setMainJsonBody(MJB);

          WT.push({
            TableId: TableId,
            TableTitle: TN,
            TableHeader: tableHead,
            TableHeaderIds: tableHeadIds,
            Sequence: Sequence,
          });
          setWork_Tables(WT);
        });
    } else if (TableId && Found) {
      toast.warning(Found, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  //----------------------------------------------------
  useEffect(() => {
    console.log(`MainJsonBody`);
    console.log(MainJsonBody);
    console.log(`MainJsonBody`);
  }, [MainJsonBody]);
  //----------------------------------------------------

  //----------------------------------------------------
  useEffect(() => {
    setResetNow(resetFilter);
  }, [resetFilter]);

  useEffect(() => {
    axios
      .get(BaseUrl + "/Data/GetTablesAll", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setTablesList(res.data);
      })
      .finally(() => {
        setReloadLists(false);
      });
  }, []);

  //----------------------------------------------
  function saveNewRelationship(name) {
    let Body = [...MainJsonBody];
    const BaseUrl = GetBaseUrl()[0];
    var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
    let newBody = [];

    for (var j = 0; j < Body.length; j++) {
      delete Body[j].tableColumns;
      delete Body[j].columnId;
      delete Body[j].tableName;
      delete Body[j].Sequence;
      for (var i = 0; i < Body[j].reference.length; i++) {
        delete Body[j].reference[i].Target;
        delete Body[j].reference[i].ByColumnName;
        delete Body[j].reference[i].ByColumn;
      }
      if (Body[j].reference.length !== 0) {
        newBody.push(Body[j]);
      }
    }

    let obj = {
      relationShipName: name,
      tables: newBody,
    };

    console.log(obj);
    if (!name) {
      toast.error("Relationship name is mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (obj.tables.length < 1) {
      toast.error("Add Tables to Relationship", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    axios
      .post(BaseUrl + "/Relations2/CreateRelationship", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Saved successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        setMainJsonBody([]);
        setWork_Tables([]);
        setNewName("");
      });
  }
  //----------------------------------------------
  function removeFromRelationship(TableId) {
    let WT = [...Work_Tables];
    let MJB = [...MainJsonBody];

    FindDeleteObj(WT, "TableId", TableId);
    FindDeleteObj(MJB, "tableId", TableId);

    setMainJsonBody(MJB);
    setWork_Tables(WT);
  }
  //----------------------------------------------

  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-4 col-xl-3 px-0 pe-3">
            <div className="top-bg p-3 rounded-5 min-vh-100">
              <div className="row d-flex flex-column h-100">
                <div className="col-6 w-100">
                  <TableList
                    renderItem={renderItem}
                    tablesList={tablesList}
                    setAddTable={setAddTable}
                    CreateRelationship={true}
                    AddTableToWorkPlace={AddTableToWorkPlace}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-9 px-0 ">
            <div className="rel-header">
              <Actions
                saveNewRelationship={saveNewRelationship}
                newName={newName}
                handleChange={handleChange}
                MainJsonBody={MainJsonBody}
                setMainJsonBody={setMainJsonBody}
                setShowLinks={setShowLinks}
                setShowLayout={setShowLayout}
                showLayout={showLayout}
                resetFilter={resetFilter}
                setResetFilter={setResetFilter}
              />
            </div>

            <div className="rel-area">
              {showLayout ? (
                <RelationshipLayout
                  TableId={addTable}
                  setAddTable={setAddTable}
                  HeaderType="ViewTable"
                  addTable={addTable}
                  Work_Tables={Work_Tables}
                  setMainJsonBody={setMainJsonBody}
                  MainJsonBody={MainJsonBody}
                  removeFromRelationship={removeFromRelationship}
                  AddNew={true}
                />
              ) : (
                <AllTablesLinksContainer
                  MainJsonBody={MainJsonBody}
                  setMainJsonBody={setMainJsonBody}
                  setShowLinks={setShowLinks}
                  setShowLayout={setShowLayout}
                  ResetNow={ResetNow}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRelation;
