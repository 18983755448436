import { React, useEffect, useRef, useState, useContext } from "react";
import Table from "../table/Table";
import NTable from "../table/NTable";
import Modal from "../modals/NewTable";
import { Form, Button, Overlay, Tooltip, Dropdown } from "react-bootstrap";
import delete_gif from "../../assets/gif/delete.gif";
import { Link } from "react-router-dom";
import CanvasModal from "../modals/Canvas";
import Office360Modal from "../modals/Office360";
import BlackBoardModal from "../modals/Blackboard";
import AUDModal from "../modals/AUD";
import ASIModal from "../modals/ASI";

import * as Loader from "react-loader-spinner";
import { Rings } from "react-loader-spinner";
// import filter_selected from '../../assets/svg/filter.svg'
import filter from "../../assets/svg/filter-blk.svg";
import active_filter from "../../assets/svg/filtered.svg";
import active_findreplace from "../../assets/svg/active-find.svg";
// import find_replace_selected from '../../assets/svg/find.svg'
import find_replace from "../../assets/svg/find-blk.svg";
import FIndNReplace from "../../components/modals/FIndNReplace";

import Disconnect from "../modals/Disconnect";
import back from "../../assets/svg/back.svg";
import note from "../../assets/svg/header_note.svg";

import arrowUp from "../../assets/svg/arrowUp.svg";
import arrowDown from "../../assets/svg/arrowDown.svg";
import inactive_arrow from "../../assets/svg/inactive_arrow.svg";

import axios from "axios";
import GetBaseUrl from "../../functions/SetBaseUrl";
import FieldProperties from "../modals/FieldProperties";
import FieldProperties1 from "../modals/FieldPropertiesPage";
import SelectDataTypes from "../table/SelectDataTypes";
import ErrorList from "../modals/ErrorList";
import FullTable from "../modals/FullTable";
import download_file from "../../assets/svg/download-file.svg";
import CustomHeader from "../modals/CustomHeader";
import OutputHeader from "../output/OutputHeader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IndexContext } from "../layout/Layout";
import "./main.css";
//---------------------------------------
const PublicTable = (props) => {
  //---------------------------------------------------------Index Style
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //---------------------------------------------------------Index Style

  let AllDataProp = {};

  const [show, setShow] = useState(false);

  const [sorting, setSorting] = useState([]);
  const [up, setUp] = useState(false);
  const [activeColumn, setActiveColumn] = useState();

  const target = useRef(null);

  //-----------------------------------------
  useEffect(() => {
    if (!props.sortBy && props.sortType) {
      if (props.firstColumn) {
        props.setSortBy(props.TableHead[1]);
        setActiveColumn(props.TableHead[1]);
      } else if (props.ReportColumn) {
        // console.log(props.TableHead);

        props.setSortBy(
          props.TableHead &&
            props.TableHead.length > 0 &&
            props.TableHead[0].columnId
        );
        setActiveColumn(
          props.TableHead &&
            props.TableHead.length > 0 &&
            props.TableHead[0].columnId
        );
      }
    }
  }, [props.TableHead]);
  //-----------------------------------------
  useEffect(() => {
    if (props.sortType === "ASC") {
      setUp(true);
    } else {
      setUp(false);
    }
  }, [props.sortType]);

  //-----------------------------------------
  useEffect(() => {
    if (props.EditableHeader) {
      // console.log(props.TableHead);
      for (let index = 0; index < [...props.TableHead].length; index++) {
        const element = [...props.TableHead][index];

        const aa = getObjByIndex(props.selectedATH, element.columnId)
          ? getObjByIndex(props.selectedATH, element.columnId)
          : element.columnName;

        document.getElementById(element.columnId + "__" + index).value = aa;
      }
    }
  }, [props.EditableHeader]);

  //******************** */
  const getObjByIndex = (array, i) => {
    const item = array && array.find((element) => element.columnId === i);
    // console.log(item);

    return item && item.renamedColumnName
      ? item.renamedColumnName
      : item && !item.renamedColumnName
      ? item.columnName
      : "";
  };
  //---------------------------------------
  const TableHead = props.TableHead;
  const renderHead = (item, index) => (
    <>
      {props.firstColumn && !index ? (
        <th scope="col" className="fw-semibold" key={index} align="center">
          {" "}
          <i className="edu ic-error-line"></i>{" "}
        </th>
      ) : props.checkboxTable && !index ? (
        <th
          width={"5%"}
          scope="col"
          className="fw-semibold"
          key={index}
          align={"center"}
        >
          <input
            id="all"
            type="checkbox"
            label=""
            className="mb-2 w-100 text-truncate fff"
            onChange={props.handleChangeAll}
            defaultChecked={props.AllColumnsFilter}
          />
        </th>
      ) : props.firstColumn && index ? (
        <th
          scope="col"
          className="fw-semibold "
          key={index - 1}
          style={{ position: "relative", paddingRight: "100px" }}
        >
          <span
            className="pointer"
            onClick={() => {
              props.setSortBy(item);
              setActiveColumn(item);
            }}
          >
            {" "}
            {item}
          </span>

          <img
            className="pointer"
            style={{ position: "absolute", right: "5%", top: "35%" }}
            src={
              activeColumn === item && up
                ? arrowUp
                : activeColumn === item && !up
                ? arrowDown
                : inactive_arrow
            }
            alt="By"
            onClick={() => {
              activeColumn === item &&
                props.setSortType(props.sortType === "ASC" ? "DESC" : "ASC");
              props.setSortBy(item);
              setActiveColumn(item);
            }}
          />
        </th>
      ) : props.ReportColumn ? (
        <th
          scope="col"
          title={"Original Name: " + item.columnName}
          className="fw-semibold"
          key={index}
          style={{
            position: "relative",
            width: "90px",
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center "
            style={{
              position: "relative",
            }}
          >
            <input
              idx={index}
              name={item.columnName}
              id={item.columnId + "__" + index}
              columnId={item.columnId}
              className={
                props.EditableHeader ? "field-input" : "field-input hide"
              }
              type="text"
              defaultValue={
                props.ToEdit && getObjByIndex(props.selectedATH, item.columnId)
                  ? getObjByIndex(props.selectedATH, item.columnId)
                  : item.columnName
              }
              onChange={props.ChangeHeader}
            />
            <span
              id={
                (props.ToEdit && getObjByIndex(props.selectedATH, item.columnId)
                  ? getObjByIndex(props.selectedATH, item.columnId)
                  : item.columnName) +
                "_" +
                index
              }
              className={props.EditableHeader ? "hide" : " pointer"}
              style={{
                width: "100%",
                border: "0px solid blue",
                marginRight: "75px",
                "white-space": "nowrap",
              }}
              onClick={() => {
                props.setSortBy(item.columnId);
                setActiveColumn(item.columnId);
              }}
            >
              {props.ToEdit && getObjByIndex(props.selectedATH, item.columnId)
                ? getObjByIndex(props.selectedATH, item.columnId)
                : item.columnName}
            </span>
            {!props.ToEdit ? (
              <img
                className="pointer"
                style={{ position: "absolute", right: "5%", top: "35%" }}
                src={
                  activeColumn === item.columnId && up
                    ? arrowUp
                    : activeColumn === item.columnId && !up
                    ? arrowDown
                    : inactive_arrow
                }
                alt="By"
                onClick={() => {
                  activeColumn === item.columnId &&
                    props.setSortType(
                      props.sortType === "ASC" ? "DESC" : "ASC"
                    );
                  props.setSortBy(item.columnId);
                  setActiveColumn(item.columnId);
                }}
              />
            ) : null}
            {props.TableOriginalHead &&
            props.TableOriginalHead[0] !== "" &&
            props.TableOriginalHead.length > 0 &&
            props.TableOriginalHead[index].columnName &&
            props.TableOriginalHead[index].columnName !== item.columnName ? (
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="default" className=" p-0">
                    <img src={note} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="fs-7 query-more"
                    style={{ marginTop: "-40px" }}
                  >
                    <Dropdown.Item className="p-1  ps-3 w-100">
                      <i className="edu ic-tbl me-1" />
                      <span className="fw-medium text-secondary me-1">
                        {props.TableOriginalHead[index].tableName}:
                      </span>
                      <span className="fw-semibold text-">
                        {props.TableOriginalHead[index].columnName}
                      </span>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : null}
          </div>
        </th>
      ) : (
        <th scope="col" className="fw-semibold" key={index - 1}>
          {item}
        </th>
      )}
    </>
  );

  const renderBody = (item, index) => (
    <tr key={index}>
      {props.firstColumn && (
        <>
          <td align="center">
            {item["hasError"] ? <i className="edu ic-error-fill"></i> : null}{" "}
          </td>
        </>
      )}

      {props.checkboxTable && (
        <>
          <td align="center">
            <input
              id={index + 1}
              type="checkbox"
              label=""
              className="mb-2 w-100 text-truncate aaa"
              onChange={props.handleChange}
              value={arr[index]}
              table={props.TableId}
              defaultChecked={
                arr[index] && props.ColumnsFilter.indexOf(arr[index]) > -1
                  ? true
                  : false
              }
            />
          </td>
        </>
      )}

      {props.Internal && !props.PlatformTable && item["columnData"]
        ? item["columnData"].map((item1, index1) => (
            <td
              key={index1}
              className={
                item1["hasError"] ? "table-danger error fw-medium" : ""
              }
              style={{ "white-space": "nowrap" }}
            >
              {item1["rowData"] && item1["rowData"].length > 100
                ? item1["rowData"].substring(0, 100) + " ..."
                : item1["rowData"]}
            </td>
          ))
        : !props.Internal && props.PlatformTable && item["rows"]
        ? props.BodyCol.map((name) => (
            <td key={name} className="">
              {item[name]}
            </td>
          ))
        : props.Internal && props.ReportTable
        ? item &&
          item.map((name, index) =>
            props.onlyColumns.indexOf(name.columnId) !== -1 ? (
              <td key={index} className="" style={{ "white-space": "nowrap" }}>
                {name.rowData && name.rowData.length > 100
                  ? name.rowData.substring(0, 100) + " ..."
                  : name.rowData}
              </td>
            ) : null
          )
        : // props.Internal && props.ReportTable
        // ? props.TableOriginalHead.map((index) => (
        //     <td key={index} className="">
        //       {item[index].columnName}
        //     </td>
        //   ))
        props.checkboxTable
        ? props.BodyCol.map((name) => (
            <td key={name} className="">
              {item}
            </td>
          ))
        : props.BodyCol.map((name) => (
            <td key={name} className="">
              {item[name]}
            </td>
          ))}

      {props.DataTypeComponent ? (
        <td>
          <SelectDataTypes
            TableId={props.TableId}
            ColumnId={item["columnId"]}
            setAssignedColumn={props.setAssignedColumn}
            setColumnsOptions={props.setColumnsOptions}
            AllDataProp={props.ADP}
            ColumnsOptions={props.ADP[item.columnId]}
            reloadColumnProp={props.reloadColumnProp}
            setReloadColumnProp={props.setReloadColumnProp}
            ByDataType={props.ByDataType}
            setByDataType={props.setByDataType}
            setFieldPropsIcon={props.setFieldPropsIcon}
            defaultSelected={props.defaultSelected}
            setDefaultSelected={props.setDefaultSelected}
            setCurrentColumnDataType={props.setCurrentColumnDataType}
            currentColumnDataType={props.currentColumnDataType}
          />
        </td>
      ) : null}
      {props.RestrictionsComponent ? (
        <td className="d-flex justify-content-between align-items-center">
          <Button
            className="p-0 mt-2 "
            variant=""
            onClick={() => {
              props.setColumnId(item["columnId"]);
              props.setViewDataFieldPropTable(true);
              props.setColumnName(item["columnName"]);
            }}
          >
            <i className="edu ic-more black-filter fs-5"></i>
          </Button>
          <div className="d-flex ">
            <Button
              className="filter-btn rounded-circle me-2"
              variant={item["isFiltered"] ? "primary" : ""}
              onClick={() => {
                props.setViewDataFilterTable([
                  item["columnId"],
                  item["columnName"],
                ]);
                props.setViewDataFieldsTable("");
              }}
            >
              <img src={filter} />
            </Button>
            <FIndNReplace
              onlyIcon
              ColumnId={item["columnId"]}
              TableId={props.TableId}
              isFiltered={item["isFiltered"]}
              isFindReplace={item["isFindReplace"]}
              className="rounded-circle"
            />
          </div>
        </td>
      ) : null}
      {props.PlatformDisconnect ? (
        <td align="center">
          <div
            className="d-flex align-items-center row"
            style={{ position: "relative" }}
          >
            <div className="view-btn">
              <Button
                variant="primary"
                className={`rounded-pill fw-semibold fs-7 mx-1 w-100`}
                onClick={() => {
                  props.setViewConnection(item["connectionId"]);
                  props.setPlatformTableId(item["platformId"]);
                }}
              >
                View
              </Button>
            </div>
            <div className="update-btn">
              {item["platformId"] === 1 ? (
                <Office360Modal
                  OnlyButton
                  ConnectionId={item["connectionId"]}
                  setReloadTable={props.setReloadTable}
                />
              ) : item["platformId"] === 2 ? (
                <CanvasModal
                  OnlyButton
                  ConnectionId={item["connectionId"]}
                  setReloadTable={props.setReloadTable}
                />
              ) : item["platformId"] === 3 ? (
                <BlackBoardModal
                  OnlyButton
                  ConnectionId={item["connectionId"]}
                  setReloadTable={props.setReloadTable}
                />
              ) : item["platformId"] === 4 ? (
                <AUDModal
                  OnlyButton
                  ConnectionId={item["connectionId"]}
                  ConnectionName={item["connectionName"]}
                  setReloadTable={props.setReloadTable}
                />
              ) : item["platformId"] === 5 ? (
                <ASIModal
                  OnlyButton
                  ConnectionId={item["connectionId"]}
                  ConnectionName={item["connectionName"]}
                  setReloadTable={props.setReloadTable}
                />
              ) : null}
            </div>
            <div className="disconnect-btn">
              <Disconnect
                PlatformId={item["platformId"]}
                ConnectionId={item["connectionId"]}
                setReloadTable={props.setReloadTable}
              />
            </div>
          </div>
        </td>
      ) : null}
      {props.lastColumn ? (
        <td>
          <Modal
            size="xl"
            headerTitle="Modal Title"
            contentType={props.modalContentType}
            Form={item}
            btnTitle="View"
            saveBtnTitle="Save All Changes"
            closeBtnTitle="Cancel and Close"
            Details={props.AllowEdit}
            InputsTitle={props.InputsTitle}
            InputsType={props.InputsType}
          />
        </td>
      ) : null}
    </tr>
  );

  const removeDuplicates = (arr) => {
    const seen = new Set();
    return arr.filter((item) => {
      const duplicate = !seen.has(item.id);
      seen.add(item.id);
      return !duplicate;
    });
  };

  //console.log(props.TableId1);
  const [arr, setArr] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // console.log(props.TableOriginalHead);
    setLoading(true);
    AllDataProp = [];

    var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
    if (props.TableMethod === "post") {
      if (props.TableId1.RelationshipId && props.TableId1.Tables.length) {
        // const uniqueArray = removeDuplicates(props.TableId1.Tables);

        axios
          .post(
            props.TableUrl,
            {
              RelationshipId: props.TableId1.RelationshipId,
              Tables: props.TableId1.Tables,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "application/json",
              },
            }
          )
          .then((res) => {
            props.Internal
              ? setArr(props.onlyColumns ? res.data.rows : res.data.rows)
              : props.InternalColumns
              ? setArr(res.data.columns)(res.data.columns).map(
                  (item) => (AllDataProp[item.columnId] = [])
                )
              : props.PlatformTable
              ? setArr(res.data.rows)
              : props.ReportTable
              ? setArr(props.onlyColumns ? res.data.rows : res.data.rows)
              : setArr(res.data);
          })
          .catch((error) => {
            toast.error("" + error, {
              position: toast.POSITION.TOP_RIGHT,
            });
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      axios
        .get(props.TableUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (props.withHeader === "ViewTable") {
            props.setHasError(false);
            for (let i = 0; i < res.data.rows.length; i++) {
              const element = res.data.rows[i];
              if (element.hasError) {
                props.setHasError(true);
                break;
              }
            }
          }

          props.Internal
            ? setArr(res.data.rows)
            : props.InternalColumns
            ? setArr(res.data.columns)(res.data.columns).map(
                (item) => (AllDataProp[item.columnId] = [])
              )
            : props.PlatformTable
            ? setArr(res.data.rows)
            : props.checkboxTable
            ? setArr(res.data.data)
            : setArr(res.data);
        })
        .catch(() => {})
        .finally(() => {
          setLoading(false);
        });
      //
    }
  }, [props.TableUrl, props.ReloadTable]);
  //--------------------------------------
  const [searchTerm, setSearchTerm] = useState(props.searchText);
  const handleTableSearchChange = (event) => {
    setSearchTerm(event.target.value);
    props.setSearchText(event.target.value);
  };
  //--------------------------------------

  return (
    <div>
      {props.withHeader === "Search" ? (
        <div className=" d-flex justify-content-between align-items-center mb-2">
          <Form.Group className="w-50" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold">{props.TableTitle}</Form.Label>
            <Form.Control
              className="inp-input p-2 fs-7 fw-medium"
              type="text"
              placeholder="Enter Table Name"
            />
          </Form.Group>
          <button className="btn main-button rounded-pill fw-semibold">
            Custom Header
          </button>
        </div>
      ) : props.withHeader === "Save" && props.saveAssignedColumns ? (
        <div className=" d-flex justify-content-between align-items-center mb-0">
          <Form.Group className="w-50" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold">{props.TableTitle}</Form.Label>
          </Form.Group>
          <div className="">
            <button
              className="btn main-button rounded-pill fw-semibold me-2"
              onClick={() => props.saveAssignedColumns("")}
            >
              Save
            </button>
            <button
              className="btn main-button rounded-pill fw-semibold"
              onClick={() => props.saveAssignedColumns("run")}
            >
              Save & Run Validation
            </button>
          </div>
        </div>
      ) : props.withHeader === "SaveFilter" ? (
        <div className="row">
          <div className="col-12 px-0 ">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="d-flex align-items-center">
                <Button
                  className="py-0"
                  variant=""
                  onClick={() => {
                    props.setViewDataFilterTable("");
                    props.setViewDataFieldsTable(props.TableId);
                  }}
                >
                  <img src={back} />
                </Button>
                <span className="fw-semibold fs-5">{props.TableTitle}</span>
              </div>

              <div className="d-flex align-items-center">
                <FIndNReplace />
                <Button
                  className="main-button fw-semibold rounded-pill"
                  onClick={() => props.saveColumnFilter()}
                >
                  Save
                </Button>
              </div>
            </div>

            <input
              type="text"
              className="w-50 me-3 border border-dark-subtle rounded-pill p-3 py-2"
              placeholder="Search"
            />
          </div>
        </div>
      ) : props.withHeader === "ViewReportTable" ? (
        <div className="   mb-0">
          <OutputHeader
            ExportReport={props.ExportReport}
            TableHead={props.TableHead}
            TableName={props.TableTitle}
            TableId={props.TableId}
            LastUpdate={props.LastUpdate}
          />
        </div>
      ) : props.withHeader === "ViewTable" ? (
        <div className="d-flex flex-column justify-content-between">
          <div className="mb-1">
            <h4 className="fw-semibold mb-0 fs-5">
              <div className="d-flex justify-content-between">
                {props.TableTitle}
                <div className="d-flex align-items-center mt-0 mb-0">
                  {props.Syncing || props.isValidating ? (
                    <Rings color="#05BC86" height={50} width={50} />
                  ) : null}
                  <span
                    className="fw-normal mb-0 fs-7"
                    style={{ float: "right" }}
                  >
                    {" "}
                    {props.LastUpdate
                      ? "Last Update: " +
                        props.LastUpdate.substring(0, 19).replace("T", "   ")
                      : ""}{" "}
                  </span>
                </div>
              </div>
            </h4>
            {props.HasError ? (
              <Modal
                size="xl"
                TableId={props.TableId}
                headerTitle={props.TableTitle}
                contentType="Table"
                btnTitle="errors found"
                saveBtnTitle=""
                closeBtnTitle="Close"
                withIcon
                viewErrorTable
                btnClass="p-0 d-flex align-items-center"
                spanClass="text-danger text-decoration-underline fs-8 fw-medium"
                iconClass="edu ic-error-fill me-1"
                exportTable={props.exportTable}
                HasError={props.HasError}
                setHasError={props.setHasError}
              />
            ) : null}
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant=""
              className="main-button p-0 px-3 py-2 fw-semibold rounded-pill fs-8"
              onClick={() => {
                props.setViewDataFieldsTable(props.TableId);
                props.setViewTable("");
              }}
            >
              Edit Data Field
            </Button>
            <Modal
              TableId={props.TableId}
              headerTitle={props.TableTitle}
              contentType="Table"
              saveBtnTitle=""
              closeBtnTitle="Close"
              FullScreen
              viewTable={props.TableId}
            />
          </div>
        </div>
      ) : props.withHeader === "ViewErrorTable" ? (
        <div className=" d-flex justify-content-between align-items-center mb-0"></div>
      ) : props.withHeader === "Nothing" ? (
        <></>
      ) : props.withHeader === "ViewPlatformTable" ? (
        <div className="d-flex flex-column mb-3">
          <div className="mb-2">
            <h5 className="fw-semibold mb-0">{props.TableTitle}</h5>
          </div>
          <div>
            <div className="d-flex justify-content-end">
              <CustomHeader
                TableHead={props.TableHead}
                TableId={props.TableId}
                ConnectionId={props.ConnectionId}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className=" d-flex justify-content-between align-items-center mb-0">
          <Form.Group className="w-50" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold">{props.TableTitle}</Form.Label>
          </Form.Group>
        </div>
      )}

      <div className="row">
        <div className="col-12 px-0">
          <div className={props.withOutBorder ? "card" : null}>
            <div className={props.withOutBorder ? "card__body" : null}>
              {/* //-------------------------NEW */}
              {props.headerBtns && (
                <>
                  <div className="w-50  noPadding tableTitle">
                    {props.normalTableName}
                  </div>
                  <div className="w-50  noPadding">
                    {props.headerBtns && props.headerBtns.map((item) => item)}
                  </div>
                </>
              )}

              {!props.ToEdit ? (
                <div className="w-50 pt-2  noPadding">
                  <input
                    type="text"
                    value={searchTerm}
                    onChange={handleTableSearchChange}
                    placeholder={
                      props.ReportTable
                        ? "| Search in: " +
                          getObjByIndex(props.TableHead, props.sortBy)
                        : "| Search in: " + props.sortBy
                    }
                    className={
                      props.Internal || props.normalTable
                        ? "TableSearchInput"
                        : "TableSearchInput hide"
                    }
                  />
                </div>
              ) : null}
              {/* //-------------------------NEW */}

              {loading ? (
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <Rings color="#05BC86" height={150} width={150} />{" "}
                  {props.loadingText}...
                </div>
              ) : (
                <>
                  <NTable
                    limit={props.defaultRows ? props.defaultRows : "10"}
                    headData={TableHead}
                    Method={props.TableMethod ? props.TableMethod : "get"}
                    renderHead={(item, index) => renderHead(item, index)}
                    bodyData={arr}
                    renderBody={(item, index) => renderBody(item, index)}
                    firstColumn={props.firstColumn}
                    TableId={props.TableId}
                    tableRef={props.tableRef}
                    BodyJsonItem={props.BodyJsonItem}
                    Internal={props.Internal}
                    ReportTable={props.ReportTable}
                    normalTable={props.normalTable}
                    normalTableName={props.normalTableName}
                    data={props.data}
                    tableHeader={props.tableHeader}
                    headerBtns={props.headerBtns}
                    actionBtns={props.actionBtns}
                    withActionColumn={props.withActionColumn}
                    setSelectedUser={props.setSelectedUser}
                    hidePagination={props.hidePagination ?? false}
                    pageIndex={props.pageIndex && props.pageIndex}
                    pageSize={props.pageSize && props.pageSize}
                    setPageIndex={props.setPageIndex}
                    setPageSize={props.setPageSize}
                    totalRows={props.totalRows && props.totalRows}
                    searchText={props.searchText}
                    setSearchText={props.setSearchText}
                    sortBy={props.sortBy}
                    setSortBy={props.setSortBy}
                    sortType={props.sortType}
                    setSortType={props.setSortType}
                    // searchInput={props.searchInput}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicTable;
