import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap';
import filter from '../../assets/svg/filter-blk.svg'
import prev from '../../assets/svg/prev.svg'
import next from '../../assets/svg/next.svg'


function SetFilter() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>

        <Button className='d-flex align-items-center px-2' variant='' onClick={handleShow}>
          <img src={filter}/>
          <span className='ms-1 fw-semibold'>Filter</span>
        </Button>
  
        <Modal dialogClassName="ms-0 my-0" contentClassName = "min-vh-100" className="pe-0"  show={show} onHide={handleClose}>
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold fs-7'>Filter</Modal.Title>
         
        </div>
        </Modal.Header>
          <Modal.Body>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7 mb-0">Search</Form.Label>
              <input className="form-control border rounded-pill fs-6 fw-medium w-100" type="text" placeholder="Enter" aria-label="default input example" />
              </Form.Group>

       
        


          </Modal.Body>
          <Modal.Footer className='d-flex justify-content-center'>
            <Button className='main-button rounded-pill fw-semibold w-45 mx-1' variant="" onClick={handleClose}>
              Show Results
            </Button>
            <Button className='main-button rounded-pill fw-semibold w-45' variant="" onClick={handleClose}>
              Reset Filter
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
}

export default SetFilter
