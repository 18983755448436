import { React, useEffect, useState } from "react";
import "./QueryArea.css";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Button, Collapse } from "react-bootstrap";
import run from "../../assets/svg/run.svg";
const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const QueryArea = (props) => {
  const [Json, setJson] = useState({});
  const [NameOfReport, setNameOfReport] = useState(props.newName);
  const handleChange = (event) => {
    setNameOfReport(event.target.value);
    if (event.target.value) {
      props.obj.reportName = event.target.value;
    } else {
      props.obj.reportName = "";
    }
  };

  function saveReport(Json) {
    // console.log(Json);
    let id;
    if (Json.tables) {
      if (Json.tables.length === 0) {
        toast.error("Selet Relationship", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
    }

    if (!Json.reportName) {
      toast.error("Report Name is Mandatory", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    //  return false;

    Json.reportName = NameOfReport;
    axios
      .post(BaseUrl + "/Report/SaveReportStructure", Json, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        PrepareJson(props.obj, res.data);
        toast.success("Saved Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });

        id = res.data;
      })
      .catch((error) => {
        toast.error("Error: " + error, { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {
        setTimeout(function () {
          props.setSelectedRelation("");
          props.setViewTable(id);
          props.setReload(Math.random(120));
          setNameOfReport("");
        }, 2000);
      });
  }

  //----------------------------------------------------
  function FindIndexInObj(array, Key, Value) {
    var msg = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i + 1;
        break;
      }
    }
    return msg;
  }
  //***********************************************
  function PrepareJson(obj, Id = "") {
    // console.log(obj.Tables);
    if (obj.Tables) {
      let TablesArr = [];
      let activeColumns = [];
      for (let i = 0; i < obj.Tables.length; i++) {
        let arr = [];

        //------------add Only Visible Column
        if (props.EditReportId) {
          for (let j = 0; j < obj.Tables[i].columns.length; j++) {
            // if (
            //   props.EditReportIdHead.indexOf(
            //     props.obj.Tables[i].columns[j].columnId
            //   ) > -1 &&
            //   props.obj.Tables[i].columns[j].isVisible
            // ) {
            //   arr.push(props.obj.Tables[i].columns[j].columnId);
            // }

            if (
              props.toShowColumnArray.indexOf(
                obj.Tables[i].columns[j].columnId
              ) > -1
            ) {
              arr.push(obj.Tables[i].columns[j].columnId);
            }
          }
        } else {
          for (let j = 0; j < obj.Tables[i].columns.length; j++) {
            if (
              props.toShowColumnArray.indexOf(
                obj.Tables[i].columns[j].columnId
              ) > -1
            ) {
              arr.push(obj.Tables[i].columns[j].columnId);
            }
          }
        }
        //------------add Only Visible Column
        //------------add NewNames and sequence
        // console.log(props.allTableHead);

        let newNamesArr = [];
        let orderArr = [];
        for (let a = 0; a < props.allTableHead.length; a++) {
          let Item = props.allTableHead[a];

          if (
            obj.Tables[i].tableId === Item.tableId &&
            arr.indexOf(Item.columnId) > -1
          ) {
            newNamesArr.push({
              columnId: Item.columnId,
              columnNewName: Item.renamedColumnName ?? Item.columnName,
            });
            orderArr.push({
              columnId: Item.columnId,
              position: FindIndexInObj(props.dragDropList, "id", Item.columnId),
            });
          }
        }
        //------------add NewNames and sequence
        let TableJson = {
          tableId: obj.Tables[i].tableId,
          columns: arr,
          columnsNewNames: newNamesArr,
          columnsNewPosition: orderArr,
        };
        TablesArr.push(TableJson);
        // console.log(newNamesArr);
      }

      if (props.EditReportId) {
        setJson({
          reportId: props.EditReportId,
          reportName: NameOfReport,
          RelationshipId: obj.RelationshipId,
          tables: TablesArr,
        });
      } else {
        setJson({
          reportName: NameOfReport,
          RelationshipId: obj.RelationshipId,
          tables: TablesArr,
        });
      }
    }
  }
  //***********************************************
  useEffect(() => {
    //console.log(Json);
  }, [Json]);

  useEffect(() => {
    if (props.obj) {
      PrepareJson(props.obj);
    }
  }, [props.obj]);
  //***********************************************
  useEffect(() => {
    // console.log(props.allTableHead);

    if (props.obj) {
      if (props.EditReportId) {
        PrepareJson(props.obj, props.EditReportId);
      } else {
        PrepareJson(props.obj);
      }
    }
  }, [
    props.ChangeColumns,
    props.allTableHead,
    NameOfReport,
    props.dragDropList,
    props.EditReportIdHead,
  ]);

  //***********************************************
  useEffect(() => {}, [Json]);

  const [open, setOpen] = useState(false);
  return (
    <div>
      <div className="w-100 d-flex justify-content-between align-items-center mb-3">
        <input
          className="form-control border rounded-pill fs-6 fw-medium w-85"
          value={NameOfReport}
          type="text"
          placeholder="Add Report Name"
          aria-label="default input example"
          onChange={handleChange}
        />
        <button
          className="btn main-button p-2 px-4  rounded-pill fw-semibold fs-6"
          onClick={() => saveReport(Json)}
        >
          Save
        </button>
      </div>
      <Button
        className="d-flex align-items-center justify-content-between rounded-pill py-2 px-2 bg-body-tertiary mb-3 w-100"
        variant=""
        onClick={() => setOpen(!open)}
      >
        <h4 className="fw-semibold mb-0 fs-6">Add Query</h4>
        <Button className="rounded-pill main-button fw-semibold fs-7">
          <img src={run} className="white-fill" /> <span>Run</span>
        </Button>
      </Button>
      <Collapse in={open} className="collapse-content">
        <div className="">
          <textarea
            className="form-control code-area "
            id="exampleFormControlTextarea1"
            rows="7"
            placeholder="Type Query here"
          ></textarea>
        </div>
      </Collapse>
    </div>
  );
};

export default QueryArea;
