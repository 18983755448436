import React, { useState } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import GetBaseUrl from "../../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

function Blackboard(props) {
  const handleClose = () => props.setResetPasswordMode(false);
  const [selected, setSelected] = useState(props.selectedUser);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const handleChange = (event) => {
    let obj = { ...selected };
    obj["newPassword"] = event.target.value;
    setSelected(obj);
  };

  //---------------------------------------------------
  const handleUpdate = () => {
    if (!selected.newPassword) {
      toast.warning("Fill The New Password");
      return false;
    }
    // console.log(selected);
    axios
      .post(
        BaseUrl +
          "/UserManagement/ResetPassword/" +
          selected.userId +
          "/" +
          selected.newPassword,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        toast.success("Password has been updated", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <Modal
      centered
      show
      onHide={handleClose}
      size="md"
      dialogClassName="custom-modal"
    >
      <Modal.Header className="p-4 pb-0 fs-7 fw-bold" closeButton>
        <h4>Reset User Password</h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <label className="fs-7 fw-semibold"> Full Name</label>

            <Form.Control
              type="text"
              className="p-3 py-2 rounded-pill w-100 mb-4"
              placeholder="Full Name"
              defaultValue={
                selected && selected.firstName + " " + selected.lastName
              }
              readOnly
            />
            <label className="fs-7 fw-semibold"> User Name</label>
            <Form.Control
              type="text"
              className="p-3 py-2 rounded-pill w-100 mb-4"
              placeholder="User Name"
              defaultValue={selected && selected.username}
              readOnly
            />
            <label className="fs-7 fw-semibold"> New Password</label>

            <Form.Control
              type="password"
              className="input-bordered p-3 py-2 rounded-pill w-100 mb-4"
              placeholder="Set New Password"
              name="password"
              onChange={handleChange}
              value={selected && selected.password}
            />

            <div className="d-flex justify-content-center w-100">
              <Button
                className=" w-50 fw-semibold rounded-pill py-2 mx-2"
                variant="secondary"
                onClick={handleClose}
              >
                Discard
              </Button>
              <Button
                className="main-button w-50 fw-semibold rounded-pill py-2"
                variant=""
                onClick={handleUpdate}
              >
                {"Save Change"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Blackboard;
