import React, { useEffect, useState, useContext } from "react";
import bb_icon from "../../assets/svg/blackboard-icon.svg";
import office_icon from "../../assets/svg/office-icon.svg";
import canvas_icon from "../../assets/svg/canvas-icon.svg";
import aud_icon from "../../assets/svg/aud-icon.svg";
import asi_icon from "../../assets/svg/asi-icon.svg";
import { Button } from "react-bootstrap";
import Disconnect from "../modals/Disconnect";
import Blackboard from "../modals/Blackboard";
import Office360 from "../modals/Office360";
import Canvas from "../modals/Canvas";
import { Link } from "react-router-dom";

import GetBaseUrl from "../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { IndexContext } from "../../components/layout/Layout";

function ConnectedDirectory() {
  //---------------------------------------------------------Index Style
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //---------------------------------------------------------Index Style

  const [Bb_Connection, setBb_Connection] = useState("");
  const [Canvas_Connection, setCanvas_Connection] = useState("");
  const [Office_Connection, setOffice_Connection] = useState("");

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  useEffect(() => {
    axios
      .get(BaseUrl + "/Platform/GetPlatforms", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        setBb_Connection(res.data[2].connected);
        setCanvas_Connection(res.data[1].connected);
        setOffice_Connection(res.data[0].connected);
      });
  }, []);

  return (
    <div>
      <div className="row mb-4">
        <div className={`${PIS["container-33-50-100"]} mt-4`}>
          <div className="d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3">
            <div className="rounded-2 d-flex justify-content-center bg-body-secondary w-20 p-3 mb-2 ">
              <img src={bb_icon} />
            </div>
            <span className="fs-5 fw-semibold mb-1">Blackboard</span>
            <span className="fs-7 text-start mb-2">
              A leading global educational technology solution.
            </span>
            <div className="w-100">
              <Link to="/ConnectDirectory/Blackboard">
                <Button className="main-button w-100 fw-semibold rounded-pill py-2">
                  New Connection
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${PIS["container-33-50-100"]} mt-4`}>
          <div className="d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3">
            <div className="rounded-2 d-flex justify-content-center bg-body-secondary w-20 p-3 mb-2 ">
              <img src={office_icon} />
            </div>
            <span className="fs-5 fw-semibold mb-1">Office365</span>
            <span className="fs-7 text-start mb-2">
              A cloud-powered productivity platform by Microsoft
            </span>
            <div className="w-100">
              <Link to="/ConnectDirectory/Office">
                <Button className="main-button w-100 fw-semibold rounded-pill py-2">
                  New Connection
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${PIS["container-33-50-100"]} mt-4`}>
          <div className="d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3">
            <div className="rounded-2 d-flex justify-content-center bg-body-secondary w-20 p-3 mb-2">
              <img src={canvas_icon} />
            </div>
            <span className="fs-5 fw-semibold mb-1">Canvas</span>
            <span className="fs-7 text-start mb-2">
              A web-based learning management system, or LMS.
            </span>
            <div className="w-100">
              <Link to="/ConnectDirectory/Canvas">
                <Button className="main-button w-100 fw-semibold rounded-pill py-2">
                  New Connection
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${PIS["container-33-50-100"]} mt-4`}>
          <div className="d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3">
            <div className="rounded-2 d-flex justify-content-center bg-body-secondary w-30 p-3 mb-2">
              <img src={aud_icon} />
            </div>
            <span className="fs-5 fw-semibold mb-1">AUD</span>
            <span className="fs-7 text-start mb-2">
              One of the most established universities in the region.
            </span>
            <div className="w-100">
              <Link to="/ConnectDirectory/AUD">
                <Button className="main-button w-100 fw-semibold rounded-pill py-2">
                  New Connection
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className={`${PIS["container-33-50-100"]} mt-4`}>
          <div className="d-flex flex-column align-items-start justify-content-start border border-black rounded-3 p-3">
            <div className="rounded-2 d-flex justify-content-center bg-body-secondary w-30 p-3 mb-2">
              <img src={asi_icon} />
            </div>
            <span className="fs-5 fw-semibold mb-1">ASI</span>
            <span className="fs-7 text-start mb-2">
              ASI.tech's AI tutor delivers personalized instruction .
            </span>
            <div className="w-100">
              <Link to="/ConnectDirectory/ASI">
                <Button className="main-button w-100 fw-semibold rounded-pill py-2">
                  New Connection
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConnectedDirectory;
