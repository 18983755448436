import { React, useEffect, useState } from "react";
import { Button, ButtonGroup, Collapse, Dropdown } from 'react-bootstrap'
import TableGroup from "../../components/tablelist/TableGroup";
import CreateGroup from "../modals/CreateGroup";

const GroupList = ({setViewDataFilterTable, setReload, syncTable, GroupsList, setViewTable, setViewDataFieldsTable, IsViewTable, runValidation, setReloadLists, runValidations, syncTables}) => { 
  const [open, setOpen] = useState(false);
  return (
    <div className=" d-flex flex-column justify-content-between h-100 ">
      <div className="border-top mt-3">
      <Button onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant=''
        className='w-100 p-0'>
         <div className='d-flex justify-content-between align-items-center my-3'>
                <div className='d-flex align-items-center'>
                    <button className='btn p-0'><i className='edu ic-down fs-10 mx-2'></i></button>
                    <span className='fs-7 fw-medium text-dark third'>GROUPS</span>
                </div>
                <CreateGroup setReloadLists = {setReloadLists} />
            </div> 
      </Button>
      <div className="h-75">
      <Collapse in={!open} className='collapse-content'>
      <div className="">
      {GroupsList &&
          GroupsList.map((item, index) => (
            <TableGroup
            GId={item.groupId}
            GName={item.groupName}
            setReload={setReload}

            key={index}
            Title={item.groupName}
              Index={index}
              EachGroupTables={item.groupedTables}
              setViewTable={setViewTable}
              setViewDataFieldsTable={setViewDataFieldsTable}
              IsViewTable= {IsViewTable}

              runValidations = {runValidations}
              syncTables = {syncTables}

              runValidation = {runValidation}
              syncTable = {syncTable}
              setReloadLists = {setReloadLists}

              setViewDataFilterTable={setViewDataFilterTable}

            />
          ))}
      </div>
      </Collapse>
      </div>
       
      </div>
    </div>
  );
};

export default GroupList;
