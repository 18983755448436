import { React, useEffect, useState } from "react";
import QueryArea from "../components/query/QueryArea";
import Result from "../components/TableResults/Result";
import GenerateDashboard from "../components/modals/GenerateDashboard";
import xls from "../assets/svg/xls_1.svg";
import pdf from "../assets/svg/pdf.svg";
import OutputTableList from "../components/output/OutputTableList";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import FirstReportResult from "../components/table/FirstReportResult";
import back from "../assets/svg/back.svg";
import { Button, Form, Collapse, Dropdown } from "react-bootstrap";
import OutputXls from "../components/output/OutputXls";
import SetFilter from "../components/modals/Filter";
import export_icon from "../assets/svg/export.svg";
import edit_header from "../assets/svg/editHeader.svg";
import GetBaseUrl from "../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import HeaderOrder from "../components/dropdown/HeaderOrder";
const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

function EditReport(props) {
  const [viewTable, setViewTable] = useState();
  const [viewReport, setViewReport] = useState(null);
  const [obj, setObj] = useState(null);
  const [Title, setTitle] = useState("");
  const [ChangeColumns, setChangeColumns] = useState(null);
  const [showR, setShowR] = useState(null);
  const [changeR, setChangeR] = useState(null);
  const [tablesList, setTablesList] = useState([]);
  const [relationShipName, setRelationshipName] = useState("");
  const [relationShipJson, setRelationshipJson] = useState({});
  const [EditableHeader, setEditableHeader] = useState(false);
  const [EditableHeaderPosition, setEditableHeaderPosition] = useState(false);
  const [allTableHead, setAllTableHead] = useState([]);
  const [TableHeadOrder, setTableHeadOrder] = useState([]);
  const [dragDropList, setDragDropList] = useState(props.TableHeadOrder);
  const [newName, setNewName] = useState(props.EditReportName);
  const [open, setOpen] = useState(true);

  const [reportSelectedColumns, setReportSelectedColumns] = useState([]);

  //----------------------------------------------------
  // const [toShowColumnArray, setToShowColumnArray] = useState([]);

  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );

  function ExportReport(Json) {
    // console.log(Json);
    axios
      .post(BaseUrl + "/Report/GetFullReportByRelationId", Json, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        window.location.href = res.data;
        // console.log(res.data);
      })

      .catch((e) => {
        toast.error("Error: " + e, { position: toast.POSITION.TOP_RIGHT });
      });
  }

  //----------------------------------------------------
  useEffect(() => {
    if (props.EditReportName) {
      setNewName(props.EditReportName);
    } else {
      setNewName("");
    }
  }, []);

  //---------------------------
  function FindIndexInObj(array, Key, Value) {
    let index = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        index = i;
        break;
      }
    }
    return index;
  }

  //----------------------------------------------------

  useEffect(() => {
    let a = [];
    axios
      .get(
        BaseUrl +
          "/Data/GetRelationshipStructureById/" +
          props.selectedRelation,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setRelationshipName(res.data.relationshipName);

        for (let i = 0; i < res.data.tables.length; i++) {
          const element = res.data.tables[i];

          if (FindIndexInObj(a, "tableId", element.tableId) > -1) {
            continue;
          }
          a.push({
            tableId: element.tableId,
            tableName: element.tableName,
            columns: element.columns,
          });
        }
      })
      .catch(() => {})
      .finally(() => {
        for (let i = 0; i < a.length; i++) {
          const element = a[i];
          for (let j = 0; j < element.columns.length; j++) {
            element.columns[j].isVisible = true;
          }
        }
        setTablesList(a);
        setViewReport(Math.random(500));
      });
  }, [props.selectedRelation]);

  //----------------------------------------------------
  useEffect(() => {
    setObj({
      RelationshipId: props.selectedRelation,
      ReportId: props.EditReportId,
      Tables: tablesList,
    });
    // console.log(tablesList);
  }, [tablesList]);

  //---------------------------------------------------

  useEffect(() => {
    // console.log(props.toShowColumnArray);
  }, [props.toShowColumnArray]);

  useEffect(() => {
    // console.log(obj);
  }, [obj]);
  //------------------------------------------------------

  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-4 col-xl-3 px-0 pe-2">
            <div className="d-flex">
              <div className="top-bg p-4 w-100 rounded-5 min-vh-100">
                <div className="mb-3">
                  <span className="fs-7 fw-medium third text-dark">Tables</span>
                  <Button
                    className="p-0 float-right"
                    variant=""
                    onClick={() => {
                      props.setSelectedRelation(null);
                      props.setViewTable(props.EditReportId);
                      props.setEditReportId(0);
                      props.setEditReportName("");
                    }}
                  >
                    <img src={back} /> All Reports
                  </Button>
                </div>

                <Collapse in={open} className="collapse-content">
                  <div className="">
                    {tablesList &&
                      tablesList.map((item, index) => (
                        <OutputXls
                          key={index}
                          setRelationshipJson={setObj}
                          relationShipJson={obj}
                          setShowR={setShowR}
                          tableId={item.tableId}
                          tableName={item.tableName}
                          setTitle={setTitle}
                          setChangeColumns={setChangeColumns}
                          EditReportIdHead={props.EditReportIdHead}
                          setEditReportIdHead={props.setEditReportIdHead}
                          allTableHead={allTableHead}
                          setAllTableHead={setAllTableHead}
                          setNewName={setNewName}
                          obj={obj}
                          setObj={setObj}
                          setToShowColumnArray={props.setToShowColumnArray}
                          toShowColumnArray={props.toShowColumnArray}
                          viewReport={viewReport}
                          reportSelectedColumns={reportSelectedColumns}
                        />
                      ))}
                  </div>
                </Collapse>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-xl-9 px-0 ">
            <div className="top-bg rounded-5 mb-4">
              <QueryArea
                obj={obj}
                allTableHead={allTableHead}
                dragDropList={dragDropList}
                EditReportIdHead={props.EditReportIdHead}
                viewReport={viewReport}
                setNewName={setNewName}
                newName={newName}
                ChangeColumns={ChangeColumns}
                EditReportId={props.EditReportId}
                setViewTable={props.setViewTable}
                setSelectedRelation={props.setSelectedRelation}
                setReload={props.setReload}
                toShowColumnArray={props.toShowColumnArray}
              />
            </div>
            <div className="top-bg p-4 rounded-5 min-vh-75">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div
                  className="d-flex align-items-center justify-content-between rounded-pill py-2 px-2 bg-body-tertiary mb-3 w-100"
                  variant=""
                >
                  <h4 className="fw-semibold mb-0 text-black ps-2 fs-6">
                    {Title}
                  </h4>
                  <div className="d-flex align-items-center">
                    <HeaderOrder
                      TableHeadOrder={TableHeadOrder}
                      dragDropList={dragDropList}
                      setDragDropList={setDragDropList}
                    />
                    <Button
                      className="d-flex align-items-center px-2"
                      variant=""
                      onClick={() => setEditableHeader(!EditableHeader)}
                    >
                      <img src={edit_header} />
                      <span className="ms-1 fw-semibold">
                        {EditableHeader ? "Save Header" : "Edit Header"}
                      </span>
                    </Button>

                    <SetFilter />
                    <Button
                      className="rounded-pill main-button fw-semibold fs-7"
                      onClick={() => ExportReport(obj)}
                    >
                      <img src={export_icon} className="white-fill" />{" "}
                      <span>Export</span>
                    </Button>
                  </div>
                </div>
              </div>

              {viewReport ? (
                <FirstReportResult
                  obj={obj}
                  viewReport={viewReport}
                  setViewTable={setViewTable}
                  HeaderType="ViewReportTable"
                  tableRowsDefault="10"
                  setTitle={setTitle}
                  setNewName={setNewName}
                  newName={newName}
                  EditReportId={props.EditReportId}
                  setEditReportIdHead={props.setEditReportIdHead}
                  EditableHeader={EditableHeader}
                  setEditableHeader={setEditableHeader}
                  EditableHeaderPosition={EditableHeaderPosition}
                  setAllTableHead={setAllTableHead}
                  allTableHead={allTableHead}
                  setTableHeadOrder={setTableHeadOrder}
                  toShowColumnArray={props.toShowColumnArray}
                  setToShowColumnArray={props.setToShowColumnArray}
                  setReportSelectedColumns={setReportSelectedColumns}
                />
              ) : (
                <h3 className="text-center">No Results</h3>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditReport;
