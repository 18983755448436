import { React, useEffect, useState, useRef } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { useLottie } from "lottie-react";
import loading_lottie from "../../assets/lottie/loading.json";

import axios from "axios";

const TableResult = (props) => {
  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [tableOriginalHead, setTableOriginalHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);

  const [tableHeadPass, setTableHeadPass] = useState([]);
  const [tableOriginalHeadPass, setTableOriginalHeadPass] = useState([]);
  const [bodyColPass, setBodyColPass] = useState([]);

  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  const [LastUpdate, setLastUpdate] = useState("");
  const [ReloadTable, setReloadTable] = useState("");
  const [aTH, setATH] = useState([]);
  const [selectedATH, setSelectedATH] = useState([]);
  const [NewValue, setNewValue] = useState(null);

  const [method, setMethod] = useState("post");

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [tableHeadIds, setTableHeadIds] = useState([]);
  const [tableSelectedHeadIds, setTableSelectedHeadIds] = useState([]);
  //---------------------------------------------------------
  const [renameInputValue, setRenameInputValue] = useState("");
  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState(0);
  const [sortType, setSortType] = useState("ASC");

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [allRecords, setAllRecords] = useState(10000000); // For fetch all records by pagination
  //---------------------------------------------------------
  const options = {
    animationData: loading_lottie,
    loop: true,
  };

  const { View } = useLottie(options);

  // useEffect(() => {
  //   setPageIndex(1);
  //   setPageSize(10);
  //   setSortBy("");

  // }, [props.viewReport]);
  //-------------------------------------------
  useEffect(() => {
    let order = [];
    let tableHead = [];
    let tableOriginalHead = [];
    let tablebodyCol = [];
    let tableHeadIds = [];
    let tableSelectedHeadIds = [];

    if (props.viewReport) {
      setTAbleUrl(BaseUrl + "/Report/GetReportByRelationId");

      // if (props.EditReportId) {
      // let queryParam = ``;
      // if (searchText && sortBy) {
      //   queryParam = `?SearchQuery=${searchText}&SelectedColumn=${sortBy}&SortDirection=${sortType}`;
      // } else if (searchText && !sortBy) {
      //   queryParam = `?SearchQuery=${searchText}`;
      // } else if (!searchText && sortBy) {
      //   queryParam = `?SelectedColumn=${sortBy}&SortDirection=${sortType}`;
      // }

      // setMethod("get");
      // setTAbleUrl(
      //   `${BaseUrl}/Report/GetReportById/${props.EditReportId}/${pageIndex}/${
      //     pageSize !== -1 ? pageSize : allRecords
      //   }${queryParam}`
      // );

      // axios
      //   .get(
      //     `${BaseUrl}/Report/GetReportById/${
      //       props.EditReportId
      //     }/${pageIndex}/${
      //       pageSize !== -1 ? pageSize : allRecords
      //     }${queryParam}`,
      //     {
      //       headers: {
      //         Authorization: `Bearer ${token}`,
      //         "content-type": "application/json",
      //       },
      //     }
      //   )
      //   .then((res) => {
      //     setTotalRows(res.data.totalCount);
      //     console.log(res.data);
      //     setATH(res.data.columns);

      //     res.data.columns.map((item) => {
      //       tableHeadIds.push(item["columnId"]);
      //       tableHead.push({
      //         tableId: item["tableId"],
      //         columnName: item["columnName"],
      //         columnId: item["columnId"],
      //       });

      //       tablebodyCol.push({
      //         tableId: item["tableId"],
      //         columnName: item["originalName"],
      //         columnId: item["columnId"],
      //       });

      //       tableOriginalHead.push({
      //         tableName: item["tableName"],
      //         columnName: item["originalName"],
      //       });

      //       order.push({
      //         id: item["columnId"],
      //         label: item["columnName"],
      //       });
      //     });
      //   })
      //   .catch(() => {})
      //   .finally(() => {
      //     setTableHeadIds(tableHeadIds);

      //     props.setTableHeadOrder(order);
      //     setTableHead(tableHead);
      //     setTableOriginalHead(tableOriginalHead);
      //     setBodyCol(tablebodyCol);

      //     setTableHeadPass(tableHead);
      //     setTableOriginalHeadPass(tableOriginalHead);
      //     setBodyColPass(tablebodyCol);

      //     props.setEditReportIdHead(tableHeadIds);
      //     setReloadTable(Math.random(400));
      //   });
      // } else {
      setMethod("post");

      // console.log(props.obj);
      axios
        .post(BaseUrl + "/Report/GetReportByRelationId", props.obj, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          // console.log(res.data);
          props.setReportSelectedColumns(res.data.selectedColumns);

          setATH(res.data.columns);
          setSelectedATH(res.data.selectedColumns);
          res.data.selectedColumns.map((item) => {
            tableSelectedHeadIds.push(item["columnId"]);
          });

          res.data.columns.map((item) => {
            tableHeadIds.push(item["columnId"]);
            tableHead.push({
              tableId: item["tableId"],
              columnName: item["columnName"],
              columnId: item["columnId"],
            });

            tablebodyCol.push({
              tableId: item["tableId"],
              columnName: item["columnName"],
              columnId: item["columnId"],
            });

            tableOriginalHead.push({
              tableName: item["tableName"],
              columnName: item["columnName"],
            });

            order.push({ id: item["columnId"], label: item["columnName"] });
          });
        })
        .catch(() => {})
        .finally(() => {
          setTableHeadIds(tableHeadIds);
          setTableSelectedHeadIds(tableSelectedHeadIds);
          props.setTableHeadOrder(order);

          setTableHead(tableHead);
          setTableOriginalHead(tableOriginalHead);
          setBodyCol(tablebodyCol);

          setTableHeadPass(tableHead);
          setTableOriginalHeadPass(tableOriginalHead);
          setBodyColPass(tablebodyCol);

          setReloadTable(Math.random(400));
        });
      // }
    }
  }, [props.viewReport, pageIndex, pageSize, sortBy, sortType, searchText]);

  //-----------------------------------------

  useEffect(() => {
    props.setToShowColumnArray(tableHeadIds);
  }, [tableHeadIds]);

  useEffect(() => {
    props.setToShowColumnArray(tableSelectedHeadIds);
  }, [tableSelectedHeadIds]);
  //-----------------------------------------
  useEffect(() => {
    props.setTitle(props.newName);
  }, [props.newName]);

  useEffect(() => {
    // console.log(aTH);
    // console.log(selectedATH);
    let arr = [];
    for (let index = 0; index < [...aTH].length; index++) {
      const element = [...aTH][index];
      let found = false;
      for (let i = 0; i < [...selectedATH].length; i++) {
        const elementI = [...selectedATH][i];
        if (elementI.columnId === element.columnId) {
          arr.push(elementI);
          found = true;
          break;
        }
      }
      if (!found) {
        arr.push(element);
      }
    }
    props.setAllTableHead(arr);
  }, [aTH]);
  //-----------------------------------------------
  useEffect(() => {
    let order = [];
    let tableHead = [];
    let tableOriginalHead = [];
    let tablebodyCol = [];
    let tableHeadIds = [];
    // console.log(aTH);
    // console.log(props.toShowColumnArray);
    props.toShowColumnArray &&
      props.toShowColumnArray.length &&
      [...aTH].map((item) => {
        if (props.toShowColumnArray.indexOf(item["columnId"]) > -1) {
          tableHeadIds.push(item["columnId"]);
          tableHead.push({
            tableId: item["tableId"],
            columnName: item["columnName"],
            columnId: item["columnId"],
          });
          tablebodyCol.push({
            tableId: item["tableId"],
            columnName: item["columnName"],
            columnId: item["columnId"],
          });
          tableOriginalHead.push({
            tableName: item["tableName"],
            columnName: item["columnName"],
          });
          order.push({ id: item["columnId"], label: item["columnName"] });
        }
      });

    props.setTableHeadOrder(order);
    setTableHead(tableHead);

    setTableOriginalHead(tableOriginalHead);
    setBodyCol(tablebodyCol);
    setTableHeadPass(tableHead);
    setTableOriginalHeadPass(tableOriginalHead);
    setBodyColPass(tablebodyCol);

    // console.log(tableHead);
    props.setEditableHeader(false);
  }, [props.toShowColumnArray]);

  useEffect(() => {
    // console.log(props.allTableHead);
  }, [props.allTableHead]);
  //------------------------
  //----------------------------------------------------
  function FindIndexInObj(array, Key, Value) {
    var msg;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i;
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------------

  const ChangeHeader = (event) => {
    // let arr = [...selectedATH];
    let arr = [...props.allTableHead];
    let columnId = event.target.attributes.columnId.value;
    let Idx = event.target.attributes.idx.value;
    let name = event.target.defaultValue;

    // document.getElementById(name + "__" + Idx).value = event.target.value;
    document.getElementById(name + "_" + Idx).innerHTML = event.target.value;
    arr[FindIndexInObj(arr, "columnId", columnId)].renamedColumnName =
      event.target.value;
    // console.log(arr);

    props.setAllTableHead(arr);
  };
  //------------------------

  useEffect(() => {
    let newTableHead = [...tableHead];
    let newTableOriginalHead = [...tableOriginalHead];
    let newBodyCol = [...bodyCol];

    for (let i = 0; i < props.obj.Tables.length; i++) {
      const element = props.obj.Tables[i].columns;
      const tableName = props.obj.Tables[i].tableName;
      const tableId = props.obj.Tables[i].tableId;
      for (let j = 0; j < element.length; j++) {
        const EachColumn = element[j];
        let idx1 = newTableHead.findIndex(
          (item) =>
            item.tableId === tableId &&
            item.columnName === EachColumn.columnName
        );
        let idx2 = newTableOriginalHead.findIndex(
          (item) =>
            item.tableName === tableName &&
            item.columnName === EachColumn.columnName
        );
        let idx3 = newBodyCol.findIndex(
          (item) =>
            item.tableId === tableId &&
            item.columnName === EachColumn.columnName
        );
        if (!EachColumn.isVisible) {
          // console.log(EachColumn.columnName);

          if (idx1 > -1) {
            newTableHead.splice(idx1, 1);
          }
          if (idx2 > -1) {
            newTableOriginalHead.splice(idx2, 1);
          }
          if (idx3 > -1) {
            newBodyCol.splice(idx3, 1);
          }
        }
      }
    }

    setTableHeadPass(newTableHead);
    setTableOriginalHeadPass(newTableOriginalHead);
    setBodyColPass(newBodyCol);
  }, [props.obj, ReloadTable]);

  //--------------------------------------

  return (
    <>
      {tableUrl || bodyColPass ? (
        <PublicTable
          selectedATH={selectedATH}
          loading={View}
          TableTitle={props.newName}
          TableId="Report"
          onlyColumns={props.toShowColumnArray}
          TableId1={props.obj}
          TableUrl={tableUrl}
          TableMethod={method}
          TableHead={tableHeadPass}
          BodyCol={bodyColPass}
          lastColumn={false}
          withOutBorder={false}
          defaultRows="10"
          withHeader="Nothing"
          firstColumn={false}
          Internal={true}
          ReportTable
          ToEdit
          ReloadTable={ReloadTable}
          NewValue={NewValue}
          EditReportId={props.EditReportId}
          ReportColumn
          TableOriginalHead={tableOriginalHeadPass}
          EditableHeader={props.EditableHeader}
          EditableHeaderPosition={props.EditableHeaderPosition}
          allTableHead={props.allTableHead}
          ChangeHeader={ChangeHeader}
          pageIndex={props.EditReportId && pageIndex}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalRows={totalRows}
          searchText={searchText}
          setSearchText={setSearchText}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortType={sortType}
          setSortType={setSortType}
        />
      ) : null}
    </>
  );
};

export default TableResult;
