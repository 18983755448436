import React from "react";
import start_date from "../../assets/svg/start-date.svg";
import end_date from "../../assets/svg/end-date.svg";
import retry from "../../assets/svg/retry.svg";
import { Button } from "react-bootstrap";
import "./jobs.css";

function ExportCard(props) {
  let startDateTime =
    props.createdOn.substring(0, 10) +
    " T " +
    props.createdOn.split("T")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[2].substring(0, 2);
  let endDateTime =
    props.createdOn.substring(0, 10) +
    " T " +
    props.createdOn.split("T")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[2].substring(0, 2);
  return (
    <div className="">
      <div className="report-carda p-3 ">
        <div className="d-flex justify-content-between align-items-end">
          <div className="export-tag py-0 d-flex justify-content-center flex-column rounded-pill px-2">
            <span className="fw-semibold fs-9">{props.jobType}</span>
          </div>
        </div>
        <div className="">
          <h4
            title={props.reportName}
            className="fs-6 fw-semibold mt-3 mb-0 jobTitle"
          >
            {props.reportName && props.reportName.length > 35
              ? props.reportName.substring(0, 35) + "..."
              : props.reportName}
          </h4>
          <div className="d-flex align-items-center justify-content-between">
            {props.jobStatus === "Completed" ? (
              <>
                <h5 className="fs-7 fw-semibold text-success mt-2 mb-0">
                  {props.jobStatus}
                </h5>

                {props.ReportData ? (
                  <a
                    className="ms-2 btn btn-outline-success py-0 fs-7 rounded-pill fw-semibold "
                    href={props.ReportData}
                  >
                    <i className="fas fa-download"></i> Download
                  </a>
                ) : null}
              </>
            ) : (
              <>
                <h5 className="fs-7 fw-semibold text-info mt-2 mb-0">
                  {props.jobStatus}
                </h5>
                <Button
                  className="ms-2 btn-outline-danger py-0 fs-7 rounded-pill fw-semibold"
                  variant=""
                >
                  <img src={retry} className="me-2" />
                  Retry
                </Button>{" "}
              </>
            )}
          </div>

          <div className="d-flex justify-content-between mt-2">
            <div className="">
              <img src={start_date} />
              <span className="ms-1 fs-8 fw-normal light-dark">
                Start Date and Time <br></br>
                {startDateTime.split(" T ")[0]} <br></br>{" "}
                {startDateTime.split(" T ")[1]}
              </span>
            </div>
            <div className="">
              <img src={end_date} />
              <span className="ms-1 fs-8 fw-normal light-dark">
                End Date and Time <br></br>
                {endDateTime.split(" T ")[0]} <br></br>{" "}
                {endDateTime.split(" T ")[1]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExportCard;
