import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardHeader.module.scss";
import Select, { components } from "react-select";
import EmiratesIcon from "../../assets/svg/EmiratesIcon.svg";
import SchoolIcon from "../../assets/svg/schoolIcon.svg";
import EditQuery from "../../assets/images/FilterEditIcon.svg";

import {
  comprehensiveStylesBlue,
  comprehensiveStyles,
} from "./Styles/HeaderStyles";

const Filter = ({
  placeholder,
  withQuery,
  setAskQueryModal,
  options,
  icon,
  container_class,
  blue = false,
  setFilterIndex,
  index,
  setQueryId,
  onChange,
  selectedFilters,
  setSelectedFilters,
  inputFilterModalGroup = false,
}) => {
  const [filterValue, setFilterValue] = useState(null);
  //----------------------------------------------------
  function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }

  const handleChange = (e) => {
    // console.log(e);
    onChange(e.value);
    setFilterValue({ value: e.value, label: e.value });

    let newArr = [
      ...selectedFilters,
      {
        filterValue: e.value,
        queryId: index + 100,
      },
    ];

    let arr = [];
    for (let i = newArr.length - 1; i >= 0; i--) {
      const element = newArr[i];

      if (!FindObj(arr, "queryId", element.queryId)) {
        arr.push(element);
      }
    }

    setSelectedFilters(arr);
  };

  return (
    <div className={`${styles[container_class]}  fw-bold`}>
      <div
        className={`${
          inputFilterModalGroup
            ? styles.inputFilterModalGroup
            : styles.inputGroup
        }  row`}
      >
        {withQuery && (
          <img
            src={EditQuery}
            onClick={() => {
              setAskQueryModal(true);
              setFilterIndex(index);
              setQueryId(index + 100);
            }}
            className={`${styles["EditQuery"]}`}
            alt="EditQuery"
          />
        )}
        <img
          src={icon}
          alt="EmiratesIcon"
          className={` ${styles[blue ? "inputIconBlue" : "inputIcon"]}`}
        />
        <Select
          styles={blue ? comprehensiveStylesBlue : comprehensiveStyles}
          options={options}
          placeholder={placeholder}
          className={`${styles["input"]}  `}
          value={filterValue}
          onChange={(e) => handleChange(e)}
        />
      </div>
    </div>
  );
};

export default Filter;
