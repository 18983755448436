import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import note from "../../../assets/svg/note-sm.svg";

function Component({
  Id,
  name,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  inputType = "",
  affected,
  disabled,
}) {
  const [inputValue, setInputValue] = useState();

  const handleChange = (e) => {
    let res, val;
    let name = e.target.name;

    inputType === "number"
      ? setInputValue(parseFloat(e.target.value))
      : setInputValue(e.target.value);

    if (e.target.attributes.LettersRestrict) {
      if (e.target.attributes.LettersRestrict.value !== "") {
        let LettersRestrict =
          e.target.attributes.LettersRestrict.value.split(",");
        let newText = e.target.value;
        e.target.value = newText.replace(
          new RegExp(`[^${LettersRestrict.join("")}]`, "g"),
          ""
        );
        e.target.value = e.target.value.replace("l", "L");
      }
    }

    res = name;
    val = e.target.value;

    setAssignedDataPropForm(
      LastEdit(AssignedDataPropForm, "dataPropertyId", name, {
        dataPropertyId: name,
        propertyValue: val,
      })
    );
  };

  //----------------------------------------LastEdit
  function LastEdit(array, Key, Value, Obj) {
    let arr = [...array];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i][Key] === Value) {
        arr.splice(i, 1);
      }
    }
    return [...arr, Obj];
  }

  useEffect(() => {
    if (value) {
      inputType === "number"
        ? setInputValue(parseFloat(value))
        : setInputValue(value);
    } else {
      setInputValue("");
    }
  }, [value]);
  //----------------------------------------LastEdit
  return (
    <div className={disabled}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="fw-semibold fs-7 mb-0">{name}</Form.Label>
        <Form.Control
          className={
            name === "Characters Restrictions"
              ? "field-input RestrictionsChar"
              : "field-input"
          }
          type={inputType}
          placeholder={inputType === "number" ? "Enter Number" : "Enter Text"}
          LettersRestrict={name === "Data Format" ? "0,l,L,_,-" : ""}
          value={inputValue}
          onChange={handleChange}
          name={Id}
        />
        {name === "Data Format" ? (
          <Form.Label className="d-flex align-items-center fs-9 mb-0 mt-2 col-12 px-0">
            <img src={note} className="me-1" />
            special characters accepted: (- , _) L: Letter 0: Number
          </Form.Label>
        ) : null}
      </Form.Group>
    </div>
  );
}
export default Component;
