import React from 'react'
import QueryList from '../components/query/QueryList'
import QueryHeader from '../components/query/QueryHeader'
import Result from '../components/TableResults/Result'

const SavedQuery = () => {
  return (
    <div>
    <div className = "rounded-5 main-bg p-35 min-vh-85">
    <div className='row'>
    <div className = "col-lg-4 col-xl-3 px-0 pe-3">
    <div className = "top-bg p-4 rounded-5 min-vh-85">
    <QueryList/> 
    </div>
    </div>

    <div className = "col-lg-8 col-xl-9 px-0 ">
    <div className = "top-bg rounded-5 min-vh-85">
    <QueryHeader/>
    <Result/>
    </div>
    </div>
    
    </div>
    </div>

    </div>
  )
}

export default SavedQuery
