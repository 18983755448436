import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import undo_gif from '../../assets/gif/undo.gif'
import delete_user from '../../assets/svg/delete.svg'

function Undo() {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <div>
        <Button className="p-0 fs-7" variant="" onClick={handleShow}>
        <i className='edu ic-undo'> </i>Undo
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className='p-0 pb-0 pt-3 px-3' closeButton>
        </Modal.Header>
        <Modal.Body className='p-2 mb-3'>
            <div className='d-flex flex-column align-items-center p-2'>
                <img className='w-30 mb-2' src={undo_gif}/>
                <span className='fw-medium fs-5'>Are you sure you want to undo the last run?</span>
            </div>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex '>
        <div className='d-flex justify-content-center w-100'>
        <Button className=' w-30 fw-semibold rounded-pill py-2 mx-2' variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button className='main-button w-30 fw-semibold rounded-pill py-2' variant="" onClick={handleClose}>
            Yes
          </Button>
        </div>
        </Modal.Footer>
        </Modal>
    
    </div>
  )
}

export default Undo
