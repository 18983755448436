import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardHeader.module.scss";
import Select, { components } from "react-select";
import EmiratesIcon from "../../assets/svg/EmiratesIcon.svg";
import SchoolIcon from "../../assets/svg/schoolIcon.svg";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import more from "../../assets/svg/more.svg";
import GetBaseUrl from "../../functions/SetBaseUrl";

import {
  comprehensiveStylesBlue,
  comprehensiveStyles,
} from "./Styles/HeaderStyles";

const Toggle = ({ container_class, setEditMode, editMode }) => {
  var role = localStorage.getItem(GetBaseUrl()[1] + "_Role");
  // console.log(role);

  return (
    <div className={`${styles[container_class]}`}>
      <Dropdown as={ButtonGroup}>
        <Dropdown.Toggle
          split
          variant=""
          id="dropdown-split-basic"
          className=""
        >
          <img src={more} alt="aa" id="dropdown-split-basic" />
        </Dropdown.Toggle>
        <Dropdown.Menu className="fs-7 more-drop p-0">
          <>
            {role === "Admin" ? (
              <Dropdown.Item
                className="p-1 ps-3 pt-2 w-100"
                onClick={() => setEditMode(!editMode)}
              >
                <i className="edu ic-sync"> </i>Edit Mode
              </Dropdown.Item>
            ) : null}

            <Dropdown.Item className="p-1 ps-3 pt-2 w-100">
              <i className="edu ic-sync"> </i>Export
            </Dropdown.Item>

            <Dropdown.Item>
              <i className="edu ic-edit-group"> </i>Print
            </Dropdown.Item>
          </>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Toggle;
