import React, { useEffect, useState, useContext } from "react";
import styles from "./DropdownWithIcon.module.scss";
import { Placeholder } from "react-bootstrap";

const DropdownWithIcon = ({
  placeholder,
  withQuery,
  setAskQueryModal,
  options,
  icon,
  container_class,
  blue = false,
  setFilterIndex,
  index,
  setQueryId,
  onChange,
  selectedFilters,
  setSelectedFilters,
  inputFilterModalGroup = false,
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  //----------------------------------------------------
  function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }

  const handleSelectChange = (e) => {
    // console.log(e.target.value);
    onChange(e.target.value);
    setSelectedOption(e.target.value);

    let newArr = [
      ...selectedFilters,
      {
        filterValue: e.target.value,
        queryId: index + 100,
      },
    ];

    let arr = [];
    for (let i = newArr.length - 1; i >= 0; i--) {
      const element = newArr[i];

      if (!FindObj(arr, "queryId", element.queryId)) {
        arr.push(element);
      }
    }

    setSelectedFilters(arr);
  };

  //   const handleSelectChange = (event) => {
  //     setSelectedOption(event.target.value);
  //   };

  useEffect(() => {
    // console.log(options);
  }, [options]);

  return (
    // <div style={{ display: "flex", alignItems: "center" }}>
    <div className={`${styles["inputFilterModalGroup"]}  fw-bold`}>
      <img
        src={icon}
        alt="EmiratesIcon"
        className={` ${styles["inputIcon"]}`}
      />
      <select
        value={selectedOption}
        onChange={handleSelectChange}
        className={`${styles["input"]}  `}
      >
        <option value="" disabled>
          {placeholder}
        </option>
        {options &&
          options.map((item) => {
            return <option value={item.value}>{item.label}</option>;
          })}
      </select>
    </div>
  );
};

export default DropdownWithIcon;
