import { TimePicker } from "antd";
import {
  Fragment,
  React,
  useEffect,
  useState,
  useReducer,
  useRef,
  Children,
  cloneElement,
} from "react";
import { Button } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { date } from "yup";

import "../FreeExample/tablePagination.css";
import { style } from "@mui/system";

// If you are using JavaScript/ECMAScript modules:
import Dropzone from "dropzone";

const Item = (props) => (
  <Fragment>
    <label>First Name :</label> <h3>Ahmad Monajjed</h3>
    <label>Job :</label> <h3>Developer</h3>
  </Fragment>
);

function Ex(props) {
  const [greeting, setGreeting] = useState({ greet: "Hello, World" });
  console.log(greeting, setGreeting);

  function updateGreeting() {
    greeting.greet = "Hello, World-Wide Web";
    setGreeting(greeting);
  }

  const getIsFormValid = () => {
    return (
      "firstName" &&
      "validateEmail(email)" &&
      "password.value".length >= 8 &&
      "role" !== "role"
    );
  };

  return (
    <div>
      <Item />
      <h1>{greeting.greet}</h1>
      <button onClick={updateGreeting}>Update greeting</button>
    </div>
  );
}

function App() {
  const [giftCard, setGiftCard] = useState({
    firstName: "Jennifer",
    lastName: "Smith",
    text: "Free dinner for 4 guests",
    valid: true,
    instructions: "To use your coupon, click the button below.",
  });

  function spendGiftCard() {
    setGiftCard((prevState) => {
      return {
        ...prevState,
        text: "Your coupon has been used.",
        valid: false,
        instructions: "Please visit our restaurant to renew your gift card.",
      };
    });
  }

  function spendGiftCard1() {
    setGiftCard({
      ...giftCard,
      text: "Your coupon has been used.",
      valid: false,
      instructions: "Please visit our restaurant to renew your gift card.",
    });
  }

  const [hi, setHi] = useState("");

  return (
    <div style={{ padding: "40px" }}>
      <h1>Gift Card Page</h1>
      <h2>
        Customer: {giftCard.firstName} {giftCard.lastName}
      </h2>
      <h3>{giftCard.text}</h3>
      <p>{giftCard.instructions}</p>
      {giftCard.valid && (
        <button onClick={spendGiftCard1}>Spend Gift Card</button>
      )}
    </div>
  );
}

//--------------------------------------------------------------------
const reducer = (state, action) => {
  if (action.type === "+") return { money: state.money + 10 };
  if (action.type === "-") return { money: state.money - 10 };
  return state;
};
function ReducerComponent() {
  const initial = { money: 700 };
  const ref = useRef(null);
  const Focus = () => {
    console.log(ref.current.text);
  };
  const [state, dispatch] = useReducer(reducer, initial);

  return (
    <div style={{ padding: "40px" }}>
      <h1 ref={ref}>Money: {state.money}</h1>
      <button
        className="btn btn-primary w-10"
        onClick={() => dispatch({ type: "+" })}
      >
        +
      </button>
      &nbsp; &nbsp;
      <button
        className="btn btn-primary w-10"
        onClick={() => dispatch({ type: "-" })}
      >
        -
      </button>
      &nbsp; &nbsp;
      <button className="btn btn-primary w-10" onClick={() => Focus()}>
        Focus
      </button>
    </div>
  );
}
//--------------------------------------------------------------------

function FetchComponent() {
  const [user, setUser] = useState([]);

  const fetchData = () => {
    fetch("https://randomuser.me/api/?results=1")
      .then((Response) => Response.json())
      .then((data) => setUser(data));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return Object.keys(user).length > 0 ? (
    <div style={{ padding: "40px" }}>
      <h1>Customer data</h1>
      <h2>Name: {user.results[0].name.first}</h2>
      <img src={user.results[0].picture.medium} />
    </div>
  ) : (
    <h1>Data pending...</h1>
  );
}
//--------------------------------------------------------------------

function usePrevious(val) {
  console.log(val);
  const ref = useRef();
  useEffect(() => {
    ref.current = val;
  }, [val]);
  return ref.current;
}
function AA() {
  const [day, setDay] = useState("Monday");
  const prevDay = usePrevious(day);
  const getNextDay = () => {
    if (day === "Monday") {
      setDay("Tuesday");
    } else if (day === "Tuesday") {
      setDay("Wednesday");
    } else if (day === "Wednesday") {
      setDay("Thursday");
    } else if (day === "Thursday") {
      setDay("Friday");
    } else if (day === "Friday") {
      setDay("Monday");
    }
  };

  useEffect(() => {
    console.log(prevDay);
  }, [prevDay]);
  return (
    <div style={{ padding: "40px" }}>
      <h1>
        Today is: {day}
        <br />
        {prevDay && <span>Previous work day was: {prevDay}</span>}
      </h1>
      <button onClick={getNextDay}>Get next day</button>
    </div>
  );
}
//------------------------------------------------------------------

function useOnlineStatus(rand) {
  const [isOnline, setIsOnline] = useState(true);
  useEffect(() => {
    function handleOnline() {
      setIsOnline(true);
    }
    function handleOffline() {
      setIsOnline(false);
    }
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return isOnline;
}

function StatusBar() {
  const isOnline = useOnlineStatus();
  return <h1>{isOnline ? "✅ Online" : "❌ Disconnected"}</h1>;
}

function SaveButton() {
  let rand = Math.ceil(Math.random() * (4000 - 100) + 6);
  const isOnline = useOnlineStatus(rand);

  function handleSaveClick() {
    console.log("✅ Progress saved :" + isOnline);
  }

  return (
    <button disabled={!isOnline} onClick={handleSaveClick}>
      {isOnline ? "Save progress" : "Reconnecting..."}
    </button>
  );
}

function custom() {
  return (
    <>
      <SaveButton />
      <StatusBar />
    </>
  );
}

//export default AA;

//--------------------------------------------------------------------------

function Btn({ children, bg }) {
  return <button style={bg}>{children}</button>;
}

function Alert({ children }) {
  return (
    <>
      <div className="overlay">1111111111111111111111</div>
      <div className="alert">{children}</div>
    </>
  );
}

const DeleteBtn = () => {
  return <Btn bg={{ backgroundColor: "red" }}> Deletion </Btn>;
};

function App2(props) {
  return (
    <div className="App">
      <header>Welcome to here</header>
      <Alert>
        <h3>Title</h3>
        <p>text is here</p>
        <DeleteBtn />
      </Alert>
    </div>
  );
}
//export default App2;

//------------------------------------------Table Pagination
// src/mockData.js
const data = new Array(100).fill(null).map((_, index) => ({
  id: index + 1,
  name: `Item ${index + 1}`,
  value: `Value ${index + 1}`,
}));

// src/DataTable.js
const DataTable = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Calculate the currently displayed items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((item) => (
            <tr key={item.id}>
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <Pagination
        itemsPerPage={itemsPerPage}
        totalItems={data.length}
        paginate={paginate}
      />
    </div>
  );
};

const Pagination = ({ itemsPerPage, totalItems, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number} className="page-item">
            <a onClick={() => paginate(number)} href="!#" className="page-link">
              {number}
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

function App3() {
  return (
    <div className="App">
      <h1>React Table with Pagination</h1>
      <DataTable />
    </div>
  );
}

//------------------------------------------Table Pagination
//export default App2;

//-------------------------------------------------React.cloneElement   &&   React.children                        @@@@Important@@@@
const Row = ({ children, color }) => {
  const childStyle = {
    border: `1px solid ${color}`,
    width: "55%",
  };
  return (
    <>
      <b>Title is here</b>
      <div>
        {Children.map(children, (child, index) => {
          return cloneElement(child, {
            style: {
              ...child.props.style,
              ...(index ? childStyle : {}),
            },
          });
        })}
      </div>
    </>
  );
};

function App4(params) {
  return (
    <Row color="purple">
      <h1>11</h1>
      <h2>22</h2>
      <h3>33</h3>
      <h4>44</h4>
      <h5>55</h5>
    </Row>
  );
}

//export default App4
//-------------------------------------------------React.cloneElement   &&   React.children

//-------------------------------------------------React.cloneElement   &&   React.children

export const RadioGroup = ({ onChange, selected, children }) => {
  const RadioOptions = Children.map(children, (child) => {
    return cloneElement(child, {
      onChange,
      checked: child.props.value === selected,
    });
  });
  return <div className="RadioGroup">{RadioOptions}</div>;
};

export const RadioOption = ({ value, checked, onChange, children }) => {
  return (
    <div className="RadioOption">
      <input
        id={value}
        type="radio"
        name={value}
        value={value}
        checked={checked}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <label htmlFor={value}>{children}</label>
    </div>
  );
};
//-------------------------

function App5() {
  const [selected, setSelected] = useState("");

  return (
    <div className="App">
      <h2>How did you hear about Little Lemon?</h2>
      <RadioGroup onChange={setSelected} selected={selected}>
        <RadioOption value="social_media">Social Media</RadioOption>
        <RadioOption value="friends">Friends</RadioOption>
        <RadioOption value="advertising">Advertising</RadioOption>
        <RadioOption value="other">Other</RadioOption>
      </RadioGroup>
      <button disabled={!selected}>Submit</button>
    </div>
  );
}

//export default App5;

//-------------------------------------------------React.cloneElement   &&   React.children

//-------------------------

// This is the HOC
function withLoadingIndicator(WrappedComponent) {
  // Return a new component
  return function WithLoading(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [number, setNumber] = useState(0);

    // Simulate data fetching
    useEffect(() => {
      const timer = setTimeout(() => setIsLoading(false), 2000); // Simulate a 2s loading time
      return () => clearTimeout(timer);
    }, []);

    if (isLoading) {
      return <div>Loading...</div>;
    }

    // Provide `setIsLoading` to the wrapped component in case it needs to control the loading state.
    return (
      <WrappedComponent
        {...props}
        setIsLoading={setIsLoading}
        setNumber={setNumber}
        number={number}
      />
    );
  };
}

const ItemListWithLoading = withLoadingIndicator(ItemList);

function ItemList({ setIsLoading, setNumber, number }) {
  return (
    <ul>
      <li onClick={() => setNumber(number++)}>{"Item" + number}</li>
      <li>Item 2</li>
      <li>Item 3</li>
    </ul>
  );
}

function App6() {
  return (
    <div>
      <h1>My App</h1>
      <ItemListWithLoading />
    </div>
  );
}

export default App6;
