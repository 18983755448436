import React, { useState, useEffect, useContext } from "react";
import { Modal, Form, InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import blackboard from "../../../assets/svg/blackboard.svg";
import bb_icon from "../../../assets/svg/blackboard-icon.svg";
import DirectoryFiles from "../DirectoryFiles";
import GetBaseUrl from "../../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Select from "react-select";
import MultipleSelect from "../../Atoms/MultipleSelect";
import { IndexContext } from "../../layout/Layout";

function Blackboard(props) {
  //-------------------------------------------IndexContext
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;

  const [selectedMultiOptions, setSelectedMultiOptions] = useState([]);

  // const handleMultiSelectChange = (event) => {
  //   const options = Array.from(
  //     event.target.selectedOptions,
  //     (option) => option.value
  //   );
  //   setSelectedMultiOptions(options);
  // };

  //-------------------------------------------IndexContext

  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");
  const handleClose = () => props.setAddEditMode(false);
  const handleShow = () => setShow(true);
  const Redirect = () => {
    window.location.href = "/BlackBoard/Platform/Tables";
  };
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const companyId = localStorage.getItem(GetBaseUrl()[1] + "_CompanyId");

  const [selected, setSelected] = useState(
    !props.selectedUser ? { isActive: true } : props.selectedUser
  );

  const handleChange = (event) => {
    let obj = { ...selected };
    obj[event.target.name] = event.target.value;
    setSelected(obj);
  };
  //---------------------------------------------------
  useEffect(() => {
    // console.log(selected && selected.dashboardsPermissions);
    // console.log(selected);
  }, [selected]);
  //---------------------------------------------------
  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  //---------------------------------------------------
  const handleSave = () => {
    if (!selected.firstName || !selected.lastName || !selected.displayEmail) {
      toast.warning("Fill Required Fields");
      return false;
    }
    if (!isValidEmail(selected.displayEmail)) {
      toast.warning("Invalid Email");
      return false;
    }
    let obj = { ...selected };
    obj.dashboardsPermissions = selectedMultiOptions.toString();
    // obj.isActive = obj.isActive === null ? true : false;
    // console.log(obj);

    axios
      .post(BaseUrl + "/UserManagement/CreateUser/" + companyId, obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data) {
          toast.success("User has been created", {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.refetchUsers();
          handleClose();
        } else {
          toast.warning("Email or Username is exist", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        // toast.success("User has been created", {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
        // props.refetchUsers();
        // handleClose();
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  //---------------------------------------------------
  const handleUpdate = () => {
    if (!selected.firstName || !selected.lastName || !selected.displayEmail) {
      toast.warning("Fill Required Fields");
      return false;
    }
    if (!isValidEmail(selected.displayEmail)) {
      toast.warning("Invalid Email");
      return false;
    }

    let obj = { ...selected };
    obj.dashboardsPermissions = selectedMultiOptions.toString();

    // console.log(obj);

    axios
      .patch(BaseUrl + "/UserManagement/UpdateUser", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        if (res.data) {
          toast.success("User has been updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
          props.refetchUsers();
          handleClose();
        } else {
          toast.warning("Email or Username is exist", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  //---------------------------------------------------

  return (
    <Modal
      animation
      centered
      show
      onHide={handleClose}
      size="lg"
      dialogClassName="custom-modal"
    >
      <Modal.Header
        className="p-4 pb-0 fw-bold"
        closeButton
        style={{ borderRadius: "20px" }}
      >
        <h4>{selected && selected.userId ? "Edit" : "Add"} User</h4>
      </Modal.Header>
      <Modal.Body className="px-4">
        <div className="d-flex flex-column align-items-center w-100">
          <div className="w-100 row ">
            <div className={`${PIS["container-50-100-100"]}`}>
              <label className="fs-7 fw-semibold"> First Name</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill  mb-4"
                placeholder="First Name"
                name="firstName"
                onChange={handleChange}
                value={selected && selected.firstName}
              />
            </div>
            <div className={`${PIS["container-50-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Last Name</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill mb-4"
                placeholder="Last Name"
                name="lastName"
                onChange={handleChange}
                value={selected && selected.lastName}
              />
            </div>
            <div className={`${PIS["container-50-100-100"]}`}>
              <label className="fs-7 fw-semibold"> User Name</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill mb-4"
                placeholder="User Name"
                name="username"
                onChange={handleChange}
                value={selected && selected.username}
              />
            </div>

            {!selected || (selected && !selected.userId) ? (
              <>
                <div className={`${PIS["container-50-100-100"]}`}>
                  <label className="fs-7 fw-semibold">Password</label>
                  <Form.Control
                    type="password"
                    className="input-bordered p-3 py-2 rounded-pill  mb-4"
                    placeholder="Password"
                    name="password"
                    onChange={handleChange}
                    value={selected && selected.password}
                  />
                </div>
                <div className={`${PIS["container-100-100-100"]}`}>
                  <label className="fs-7 fw-semibold w-100">
                    {" "}
                    Email Address
                  </label>
                  <Form.Control
                    type="email"
                    className="input-bordered p-3 py-2 rounded-pill  mb-4"
                    placeholder="Email"
                    name="displayEmail"
                    onChange={handleChange}
                    value={selected && selected.displayEmail}
                  />
                </div>
              </>
            ) : (
              <div className={`${PIS["container-50-100-100"]}`}>
                <label className="fs-7 fw-semibold w-100"> Email Address</label>
                <Form.Control
                  type="email"
                  className="input-bordered p-3 py-2 rounded-pill  mb-4"
                  placeholder="Email"
                  name="displayEmail"
                  onChange={handleChange}
                  value={selected && selected.displayEmail}
                />
              </div>
            )}

            <div className={`${PIS["container-50-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Mobile Number</label>
              <Form.Control
                type="text"
                className="input-bordered p-3 py-2 rounded-pill mb-4"
                placeholder="Mobile"
                name="mobile"
                onChange={handleChange}
                value={selected && selected.mobile}
              />
            </div>
            <div className={`${PIS["container-50-100-100"]}`}>
              <label className="fs-7 fw-semibold"> Role</label>
              <Form.Select
                className="input-bordered field-input mb-4 "
                aria-label="Default select example "
                onChange={handleChange}
                name="role"
                value={
                  selected && !selected.role
                    ? "selected"
                    : selected && selected.role
                }
              >
                <option>Select Role</option>
                <option value="Admin">Admin</option>
                <option value="User">User</option>
              </Form.Select>
            </div>

            <div className={`${PIS["container-100-100-100"]}`}>
              <label className="fs-7 fw-semibold">
                {" "}
                Dashboards Permissions
              </label>

              <MultipleSelect
                title={"Select"}
                setSelectedMultiOptions={setSelectedMultiOptions}
                selectedOptions={
                  selected && selected.dashboardsPermissions
                    ? selected.dashboardsPermissions.split(",")
                    : []
                }
                options={props.pages}
              />
            </div>

            <div className="d-flex justify-content-center w-100 mt-4">
              <Button
                className=" w-50 fw-semibold rounded-pill py-2 mx-2"
                variant="secondary"
                onClick={handleClose}
              >
                Discard
              </Button>
              <Button
                className="main-button w-50 fw-semibold rounded-pill py-2"
                variant=""
                onClick={
                  selected && selected.userId ? handleUpdate : handleSave
                }
              >
                {selected && selected.userId ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default Blackboard;
