import {React, useEffect, useState, useRef } from "react";
import PublicTable from '../../components/API_Table/main'
import GetBaseUrl from '../../functions/SetBaseUrl'
import axios from "axios";

const DirectoryTable = ({ PlatformTableId , setViewConnection, setPlatformTableId}) => {


  const BaseUrl = GetBaseUrl()[0]   ;
  const [tableHead, setTableHead] = useState(['Directory','Name','Status','']);
  const [bodyCol, setBodyCol] = useState(['platformName','connectionName','connectedStatus']);
  const [tableUrl, setTAbleUrl] = useState('')
  const [tableName, setTableName] = useState('')
  var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");

  const [ReloadTable, setReloadTable] = useState(false)

     
useEffect(() => {

    setTAbleUrl(BaseUrl  +  '/Platform/GetConnectedPlatforms/'+ PlatformTableId)

}, [])








return (
<>  
{tableUrl
  ?
    <PublicTable
    loadingText='Loading...'
    TableTitle={tableName}
    TableId= {PlatformTableId}  
    TableUrl= {tableUrl}  
    TableHead= {tableHead}      
    BodyCol=  {bodyCol}
    lastColumn={false}
    withOutBorder={false}
    defaultRows='10'
    PlatformDisconnect
    setViewConnection={setViewConnection}
    setPlatformTableId={setPlatformTableId}
    setReloadTable={setReloadTable}
    ReloadTable={ReloadTable}
    />
  :
null
}

  
  </>

  )
}

export default DirectoryTable

