import {React, useEffect, useState } from "react";
import './relationship.css'
import header from '../../assets/svg/header.svg'
const RelationshipCardColumn = (props) => {

//----------------------------------------------------
function SetCol() {

    let array=[...props.MainJsonBody]
      for (var i = 0; i < array.length; i++){
          if (array[i]['Sequence']===props.Sequence){
                    array[i]['columnId'] = props.ColumnId

                    for (var j = 0; j < array[i]['tableColumns'].length; j++){
                        array[i]['tableColumns'][j].Selected=false
                        if(array[i]['tableColumns'][j].columnId===props.ColumnId){ array[i]['tableColumns'][j].Selected=true }
                    }
                    props.setColumnsCells(array[i]['tableColumns'])
                    props.setUpdateColumnSelection(true)
          }
      }  
      props.setMainJsonBody(array)

      
}
//----------------------------------------------------




  return (
    <>
        <li className='rel-card_child-last ps-2 pointer d-flex' onClick={() => SetCol()}>
            { props.Selected ? <div className="main-color rounded-circle rel-selected d-flex"><i className='edu ic-add-rel rel-icon white-fill fs-10'></i></div> 
             : <img src = {header}/> }
            
            <span className='ms-2 fw-medium fs-7 text-truncate'>{props.name}  </span>
        </li>
    </>
  )
}

export default RelationshipCardColumn
