import React from 'react'

const Result = () => {
  return (
    <div>
        <table className="table table-bordered mt-4">
    <thead>
        <tr className='fs-7 fw-semibold'>
        <th scope="col" className='fw-semibold'>ID</th>
        <th scope="col" className='fw-semibold'>Name</th>
        <th scope="col" className='fw-semibold'>Email</th>
        <th scope="col" className='fw-semibold'>Mobile</th>
        <th scope="col" className='fw-semibold'>Address</th>
        <th scope="col" className='fw-semibold'>Order Number</th>
        </tr>
    </thead>
    <tbody className='fs-8'>
        <tr>
        <td>1</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td >Khalidiya, Abu Dhabi</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>2</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td>Downtown, Dubai</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>3</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td>Downtown, Dubai</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>4</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td >Khalidiya, Abu Dhabi</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>5</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td >Khalidiya, Abu Dhabi</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>1</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td >Khalidiya, Abu Dhabi</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>2</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td>Downtown, Dubai</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>3</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td>Downtown, Dubai</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>4</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td >Khalidiya, Abu Dhabi</td>
        <td>234567890</td>
        </tr>
        <tr>
        <td>5</td>
        <td>Mark Tylers</td>
        <td>mark@.com</td>
        <td>+971562345678</td>
        <td >Khalidiya, Abu Dhabi</td>
        <td>234567890</td>
        </tr>
         
    </tbody>
    </table>
    </div>
  )
}

export default Result
