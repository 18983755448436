import { React, useEffect, useState, useRef } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";

import axios from "axios";

const TableResult = ({
  TableId,
  tableRowsDefault,
  setViewDataFieldsTable,
  setViewTable,
  OnlyErrorRows,
  HeaderType,
  DownloadExcel,
  tableRef,
}) => {
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [allRecords, setAllRecords] = useState(10000000); // For fetch all records by pagination

  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  useEffect(() => {
    if (OnlyErrorRows) {
      let tableHead = [""];
      let tablebodyCol = [];

      setTAbleUrl(
        `${BaseUrl}/Data/GetErrorDataByTable/${TableId}/${pageIndex}/${
          pageSize !== -1 ? pageSize : allRecords
        }`
      );
      axios
        .get(
          `${BaseUrl}/Data/GetErrorDataByTable/${TableId}/${pageIndex}/${
            pageSize !== -1 ? pageSize : allRecords
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setTotalRows(res.data.totalRecords);

          setTableName(res.data.tableName);
          res.data.columns.map((item, index) =>
            tableHead.push(item["columnName"])
          );
          res.data.columns.map((item, index) => tablebodyCol.push("rowData"));
        })
        .finally(() => {
          setTableHead(tableHead);
          setBodyCol(tablebodyCol);
        });
    }
  }, [TableId, pageIndex, pageSize]);

  return (
    <>
      {tableUrl ? (
        <PublicTable
          loadingText="Loading Table"
          TableTitle={tableName}
          TableId={TableId}
          TableUrl={tableUrl}
          TableHead={tableHead}
          BodyCol={bodyCol}
          lastColumn={false}
          withOutBorder={false}
          defaultRows={tableRowsDefault ? tableRowsDefault : "10"}
          withHeader={HeaderType}
          firstColumn
          Internal={true}
          setViewDataFieldsTable={setViewDataFieldsTable}
          setViewTable={setViewTable}
          DownloadExcel={DownloadExcel}
          tableRef={tableRef}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalRows={totalRows}
        />
      ) : null}
    </>
  );
};

export default TableResult;
