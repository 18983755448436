import React from 'react'
import UsersTable from '../components/users/UsersTable'
import Pagination from '../components/pagination/Pagination'
import AddUser from '../components/modals/AddUser'
import { Button, Form } from 'react-bootstrap'
import back from '../assets/svg/back.svg'
import filter from '../assets/svg/filter-blk.svg'
import sort from '../assets/svg/sort.svg'
import FIndNReplace from '../components/modals/FIndNReplace'
import SetFilter from '../components/modals/Filter'
import SelectRelationship from '../components/modals/SelectRelationship'



const FilterColumn = () => {
  return (
    
    <div className = "rounded-5 main-bg p-35 min-vh-85">
       <div className='row'>
       <div className = "col-lg-4 col-xl-3 px-0 pe-3 table-scroll">
          <div className = "top-bg p-4 rounded-5 min-vh-100">
            </div>
            </div>
            <div className = "col-lg-8 col-xl-9 px-0 ">
    <div className = "top-bg rounded-5 min-vh-100">
    <div className=' d-flex justify-content-between align-items-center'>
      <div className='d-flex align-content-center'>
      <Button className='py-0' variant=''><img src ={back}/></Button>
      <span className='fw-semibold fs-5'>Student Data</span>
      </div>
      <div className='d-flex align-items-center'>
       <FIndNReplace/>
        <Button className='main-button fw-semibold rounded-pill'>Save</Button>
      </div>
    </div>
    <div className="mt-2 d-flex align-items-center">
                <input type="text" className='w-50 me-3 border rounded-pill p-3 py-2' placeholder='Search' />
            </div>
    <div> 
    <table className="table table-bordered mt-3">
    <thead>
    <tr className='fs-7 fw-semibold'>
    <th scope="col" className='fw-semibold text-center w-5'><Form.Check aria-label="option 1" className='select-header'/></th>
    <th scope="col" className='fw-semibold text-center fs-6 d-flex align-items-center justify-content-center'>Student Name <Button className='py-0 px-2' variant=''><img src = {sort}/></Button></th>

    </tr>
    </thead>
    <tbody className='fs-8 text-center'>
    <tr>
    <td className='text-center'>
    <Form.Check aria-label="option 1" className='select-header'/>
    </td>
    <td className='text-center'>Mark Tylers</td>
    </tr>
    <tr>
    <td className='text-center'>
    <Form.Check aria-label="option 1" className='select-header'/>
    </td>
    <td className='text-center'>Mark Tylers</td>
    </tr>
    <tr>
    <td className='text-center'>
    <Form.Check aria-label="option 1" className='select-header'/>
    </td>
    <td className='text-center'>Mark Tylers</td>
    </tr>
    <tr>
    <td className='text-center'>
    <Form.Check aria-label="option 1" className='select-header'/>
    </td>
    <td className='text-center'>Mark Tylers</td>
    </tr>
    <tr>
    <td className='text-center'>
    <Form.Check aria-label="option 1" className='select-header'/>
    </td>
    <td className='text-center'>Mark Tylers</td>
    </tr>
    <tr>
    <td className='text-center'>
    <Form.Check aria-label="option 1" className='select-header'/>
    </td>
    <td className='text-center'>Mark Tylers</td>
    </tr>
   
  
     
</tbody>
</table>
    </div>
    </div>


    </div>

    </div>
    </div>
  )
}

export default FilterColumn
