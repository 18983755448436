import { React, useEffect, useState } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";

import FieldProperties from "../modals/FieldProperties";
import SelectDataTypes from "./SelectDataTypes";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TableFields = ({
  TableId,
  ColumnId,
  ColumnName,
  FilterTableId,
  tableRowsDefault,
  setViewDataFieldsTable,
  setViewDataFilterTable,
}) => {
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");

  const [ColumnsFilter, setColumnsFilter] = useState([]);
  const [Distinct, setDistinct] = useState([]);
  const [Values, setValues] = useState([]);
  const [Json, setJson] = useState(null);
  const [All, setAll] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalRows, setTotalRows] = useState(0);
  const [allRecords, setAllRecords] = useState(10000000); // For fetch all records by pagination
  //----------------------------------------------
  useEffect(() => {
    let aa;
    if (Values.length) {
      aa = "";
    } else {
      aa = "0";
    }

    Values.map((item, index) => {
      index === Values.length - 1
        ? (aa += "'" + item + "'")
        : (aa += "'" + item + "',");
    });

    setJson({
      TableId: FilterTableId,
      ColumnId: ColumnId,
      FilteredData: aa,
    });
  }, [Values]);

  const handleChangeAll = (e) => {
    let isChecked = e.target.checked;

    if (isChecked) {
      setValues(Distinct);
      setAll("true");
    } else {
      setValues([]);
      setAll("false");
    }

    const selectItems = document.getElementsByClassName("aaa");

    if (isChecked) {
      for (let item of selectItems) {
        item.checked = true;
      }
    } else {
      for (let item of selectItems) {
        item.checked = false;
      }
    }
  };

  const handleChange = (e) => {
    let a = [...Values];
    let isChecked = e.target.checked;
    let value = e.target.value;

    if (isChecked) {
      a.push(value);
      setValues(a);
    } else {
      var i = a.indexOf(value);
      if (i !== -1) {
        a.splice(i, 1);
      }
      setValues(a);
    }
  };

  //--------
  function saveColumnFilter() {
    //console.log(Json);
    try {
      axios({
        method: "post",
        url: BaseUrl + "/Data/PostColumnFilters",
        data: Json,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          toast.success("Data Filter has been saved", {
            position: toast.POSITION.TOP_RIGHT,
          });

          setViewDataFilterTable("");
          setViewDataFieldsTable(FilterTableId);
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        });
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  //------------------------------------------------------

  useEffect(() => {
    if (ColumnId) {
      setTAbleUrl(
        `${BaseUrl}/Data/GetDistinctColumns/${ColumnId}/${pageIndex}/${
          pageSize !== -1 ? pageSize : allRecords
        }`
      );

      //-------------------------
      axios
        .get(
          `${BaseUrl}/Data/GetDistinctColumns/${ColumnId}/${pageIndex}/${
            pageSize !== -1 ? pageSize : allRecords
          }`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setTotalRows(res.data.totalItems);

          setDistinct(res.data.data);
          setTableName(res.data.tableName);
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        })
        .finally(() => {});
      //--------------------------
      axios
        .get(BaseUrl + "/Data/GetColumnFilters/" + ColumnId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          // console.log('-------------');
          // console.log(res.data);
          // console.log('-------------');

          if (res.status === 200) {
            setColumnsFilter(res.data.filteredData);
          }
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        })
        .finally(() => {});
      //-------------------------

      setTableHead(["", ColumnName]);
      setBodyCol(["data"]);
    }
  }, [ColumnId, pageIndex, pageSize]);

  return (
    <>
      {tableUrl ? (
        <PublicTable
          loadingText="Loading Table"
          TableTitle={tableName + ""}
          TableId={FilterTableId}
          ColumnId={ColumnId}
          TableUrl={tableUrl}
          TableHead={tableHead}
          BodyCol={bodyCol}
          lastColumn={false}
          withOutBorder={false}
          defaultRows={tableRowsDefault ? tableRowsDefault : "10"}
          withHeader="SaveFilter"
          saveColumnFilter={saveColumnFilter}
          checkboxTable
          handleChangeAll={handleChangeAll}
          handleChange={handleChange}
          checked={All}
          ColumnsFilter={ColumnsFilter}
          // AllColumnsFilter={ColumnsFilter.length===Distinct.length ? true : false}

          setViewDataFieldsTable={setViewDataFieldsTable}
          setViewDataFilterTable={setViewDataFilterTable}
          TableClassName="table table-bordered mt-4 border-2 border-dark"
          TableHeadTrClassName="fs-7"
          TableThClassName="fw-semibold p-3"
          TableBodyTrClassName=""
          TableTdClassName="d-flex justify-content-between align-items-center ps-1"
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalRows={totalRows}
        />
      ) : null}
    </>
  );
};

export default TableFields;
