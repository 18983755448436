import React, { useEffect, useState, useContext } from "react";
import ReportCard from "../components/report-cards/ReportCard";
import { Link } from "react-router-dom";
import GetBaseUrl from "../functions/SetBaseUrl";
import "react-toastify/dist/ReactToastify.css";

import { IndexContext } from "../components/layout/Layout";

const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Home = (props) => {
  const IndexProvider = useContext(IndexContext);
  IndexProvider.setPathName("All Dashboards");

  const [dashboards, setDashboards] = useState([{}]);

  const [HomePage, setHomePage] = useState(
    GetBaseUrl()[1] + "_DefaultHomePage"
  );
  const [viewReport, setViewReport] = useState();
  const [tablesList, setTablesList] = useState([]);
  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );

  useEffect(() => {}, []);

  return (
    <div className="rounded-5 main-bg min-vh-85">
      <div className="top-bg rounded-5 min-vh-85">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="">
            <h5 className="fw-semibold mb-0">Dashboards</h5>
            <span className="fs-7">{1} results</span>
          </div>
        </div>

        <div className="row">
          <div className="col-3">
            <Link to="/AI_Tutor">
              <ReportCard
                setViewTable={setViewReport}
                Id={1}
                Title={"AI Tutor Dashboard"}
                LastEdit={"ASI"}
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
