import React ,{useState, useRef, useEffect} from 'react'
import { Modal, Form } from 'react-bootstrap'
import Result from '../TableResults/Result1';
import Button from 'react-bootstrap/Button';

import TableResult from '../../components/table/TableResult'
import ErrorTableResult from '../../components/table/ErrorTableResult'

import GetBaseUrl from '../../functions/SetBaseUrl'
import download_file from '../../assets/svg/download-file.svg'
import full from '../../assets/svg/full.svg'
import ExportTest from './exportTest'
import axios from "axios";
import { useDownloadExcel } from 'react-export-table-to-excel';



const Form_Modal = props => {

  const [tableName, setTableName] = useState('Export Excel')

  const tableRef = useRef(null);




  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const BaseUrl = GetBaseUrl()[0]   ;
  var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");


 



return (
    <>




{!props.FullScreen  ?

        <Button className={props.btnClass ? props.btnClass  : 'btn btn-sm main-button rounded-pill fw-semibold'} variant={props.variant ? props.variant : 'default'} onClick={handleShow}>
              
        {props.withIcon ?
            <><i className={props.iconClass}></i><span className={props.spanClass}>{props.btnTitle}  {props.tableRowsDefault}</span></>      
        :
        <> {props.btnTitle} </>
        }

        </Button>

:
      <img src = {full}  onClick={handleShow} className='btn   fw-semibold'/>
}


 <Modal centered fullscreen={props.FullScreen} size={props.size} show={show} onHide={handleClose}>
     

        <Modal.Header closeButton>
          <Modal.Title>{props.headerTitle}  
            {props.viewErrorTable ? 
            <Button variant='' style={{float: 'right'}}   onClick={() => props.exportTable(props.TableId,true)}  ><img src = {download_file}/></Button> 
            : null}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>


          {props.HtmlElement ? props.HtmlElement : ''}

          {props.contentType === 'Form'
          ?
          <Form>
            {props.Details && props.Details.map(Idx => (  

                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1" key={Idx}>
                    {props.InputsType[Idx] && ['number','text'].includes(props.InputsType[Idx]) ?
                    <>
                        <Form.Label>{(props.InputsTitle)[Idx]}</Form.Label>
                        <Form.Control
                          type={props.InputsType[Idx]}
                          placeholder="..."
                          // autoFocus
                          name={ Object.keys(props.Form)[Idx] }
                          defaultValue={ props.Form[Object.keys(props.Form)[Idx]]}
                          />
                    </>
                    :props.InputsType[Idx] && ['textarea'].includes(props.InputsType[Idx]) ?
                    <>
                          <Form.Label>{(props.InputsTitle)[Idx]}</Form.Label>
                          <Form.Control as="textarea" rows={3} value={props.Form[Object.keys(props.Form)[Idx]]} />
                    </>
                    :
                    null
                    }                      
                    </Form.Group>

            ))}            
          </Form>
          :props.contentType === 'Table'
          ?
                    <>
                          {props.viewTable ?
                                <>
                                <TableResult
                                   TableId={props.viewTable}
                                   tableRowsDefault={props.tableRowsDefault}
                                   
                                />    

                                </>                      
                          :props.viewErrorTable ?
                                <>
 
                                <ErrorTableResult
                                   TableId={props.TableId}
                                   tableRowsDefault={props.tableRowsDefault}
                                   OnlyErrorRows
                                   HeaderType='ViewErrorTable'
                                   DownloadExcel
                                   tableRef={tableRef}
                                   HasError={props.HasError}
                                /> 

                                </>   
                          :
                               null
                          }


                    </>
          :props.contentType === 'Confirmation'
          ?
          <div className='d-flex flex-column align-items-center p-2'>
            <img className='w-30 mb-2' src={props.confirmationImg}/>
            <span className='fw-medium fs-5'>{props.confirmationMsg}</span>
          </div>          
          
          :
          null
          }

        </Modal.Body>
        <Modal.Footer>
          {props.closeBtnTitle &&
           <Button variant="secondary" onClick={handleClose}>{props.closeBtnTitle}</Button>
          }
          
          {props.saveBtnTitle && 
            <Button variant="success" onClick={handleClose}> {props.saveBtnTitle}</Button>
          }
         
        </Modal.Footer>
      </Modal>
    </>
  );
}



export default Form_Modal;