import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import delete_gif from '../../assets/gif/delete.gif'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import GetBaseUrl from '../../functions/SetBaseUrl'
import axios from "axios";


function Delete(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");

    
function handleDelete(Id) {

    axios
    .delete( BaseUrl  +  '/Report/DeleteReportById/'+ Id, {
        headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        },
    })
    .then((res) => {


      
    }).catch(()=>{

        toast.error('An Error has been occured during Deletion', {
            position: toast.POSITION.TOP_RIGHT
          });

    }).finally(()=>{

            toast.success('Report has been deleted', {
              position: toast.POSITION.TOP_RIGHT
            });
            handleClose();
            props.setReload(Math.random());
            props.setViewTable('')

    });

}

  return (
    <div>
       <Button className="p-0 px-0 fs-7 w-100 d-flex align-items-center" variant="" onClick={handleShow} >
       <i className='edu ic-delete me-2'> </i>Delete
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className='p-0 pb-0 pt-3 px-3' closeButton>
        </Modal.Header>
        <Modal.Body className='p-2 mb-3'>
            <div className='d-flex flex-column align-items-center p-2'>
                <img className='w-30 mb-2' src={delete_gif}/>
                <span className='fw-medium fs-5'>Are you sure you want to delete this Report?</span>
            </div>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex '>
        <div className='d-flex justify-content-center w-100'>
        <Button className=' w-30 fw-semibold rounded-pill py-2 mx-2' variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button className='main-button w-30 fw-semibold rounded-pill py-2' variant="" onClick={() => handleDelete(props.Id)} >
            Yes
          </Button>
        </div>
        </Modal.Footer>
        </Modal>
    
    </div>
  )
}

export default Delete
