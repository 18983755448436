import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";

function Component({
  Id,
  name,
  value,
  AssignedDataPropForm,
  setAssignedDataPropForm,
  setAssignedDataProp,
  setMinCriteriaPlaceholder,
  affected,
  recallCriteria,
  setRecallCriteria,
  disabled,
}) {
  const handleChange = (e) => {
    let res, val;
    let resetRange = false;
    let name = e.target.name;
    let canReadCriteria = e.target.attributes.rangeCriteriaAttr;
    let inputType = e.target.type;
    // console.log(e.target);

    let finalRangeName =
      document.getElementsByClassName("rangeToCriteria")[0]["name"];

    if (canReadCriteria) {
      let CriteriaType =
        document.getElementsByClassName("CriteriaType")[0]["value"];
      let rangeFrom =
        document.getElementsByClassName("rangeFromCriteria")[0]["value"];
      let rangeTo =
        document.getElementsByClassName("rangeToCriteria")[0]["value"];
      let range;

      let CT;
      if (CriteriaType !== "Inbetween") {
        if (CriteriaType === "Select") {
          val = "";
          resetRange = true;
          document
            .querySelectorAll(".minCriteria")
            .forEach((el) => (el.style.display = "none"));
          document
            .querySelectorAll(".maxCriteria")
            .forEach((el) => (el.style.display = "none"));
          setRecallCriteria("none");
          // return false;
        } else {
          resetRange = false;

          val = e.target.value.toString();
          CT = CriteriaType;
          setMinCriteriaPlaceholder("set Value");
          document.querySelectorAll(".minCriteria").forEach((el) => {
            el.style.display = "initial";
          });
          document
            .querySelectorAll(".maxCriteria")
            .forEach((el) => (el.style.display = "none"));
        }
      } else if (CriteriaType === "Inbetween") {
        val = e.target.value.toString();
        resetRange = false;

        setMinCriteriaPlaceholder("min");
        CT = "";
        document.querySelectorAll(".minCriteria").forEach((el) => {
          el.style.display = "initial";
        });
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "initial"));
      }
    }

    res = name;
    let addObject = [
      {
        dataPropertyId: name,
        propertyValue: val,
      },
    ];
    resetRange &&
      addObject.push({ dataPropertyId: finalRangeName, propertyValue: "" });
    setAssignedDataPropForm(
      LastEdit(AssignedDataPropForm, "dataPropertyId", name, addObject)
    );

    setRecallCriteria(e.target.value);
  };

  //----------------------------------------LastEdit
  function LastEdit(array, Key, Value, Obj) {
    let arr = [...array];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] && arr[i][Key] === Value) {
        arr.splice(i, 1);
      }
    }
    return [...arr, ...Obj];
  }
  //----------------------------------------LastEdit
  //---------------------------------------------------
  useEffect(() => {
    let CriteriaType =
      document.getElementsByClassName("CriteriaType")[0]["value"];
    if (CriteriaType !== "Inbetween") {
      if (CriteriaType === "Select") {
        document
          .querySelectorAll(".minCriteria")
          .forEach((el) => (el.style.display = "none"));
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "none"));
        // return false;
      } else {
        setMinCriteriaPlaceholder("set Value");
        document.querySelectorAll(".minCriteria").forEach((el) => {
          el.style.display = "initial";
        });
        document
          .querySelectorAll(".maxCriteria")
          .forEach((el) => (el.style.display = "none"));
      }
    } else if (CriteriaType === "Inbetween") {
      setMinCriteriaPlaceholder("min");
      document.querySelectorAll(".minCriteria").forEach((el) => {
        el.style.display = "initial";
      });
      document
        .querySelectorAll(".maxCriteria")
        .forEach((el) => (el.style.display = "initial"));
    }

    setRecallCriteria(value);
  }, []);

  return (
    <div className={disabled}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label className="fw-semibold fs-7 mb-0">{name}</Form.Label>
        <Form.Select
          className="field-input mb-2 CriteriaType"
          rangeCriteriaAttr="Data Criteria"
          aria-label="Default select example "
          onChange={handleChange}
          name={Id}
        >
          <option selected={!value && value !== null ? "selected" : ""}>
            Select
          </option>
          <option value=">" selected={value === ">" ? "selected" : ""}>
            Greater Than
          </option>
          <option value="<" selected={value === "<" ? "selected" : ""}>
            Less Than
          </option>
          <option value="=" selected={value === "=" ? "selected" : ""}>
            Equal
          </option>
          <option
            value="Inbetween"
            selected={value === "Inbetween" ? "selected" : ""}
          >
            Inbetween
          </option>
        </Form.Select>
      </Form.Group>
    </div>
  );
}
export default Component;
