import { React, useEffect, useState } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Fields from "../modals/FieldPropertiesPage";
import ConditionalFormatting from "../modals/ConditionalFields";
const TableFields = ({
  setViewDataFieldPropTable,
  TableId,
  FilterTableId,
  setFilterTableId,
  tableRowsDefault,
  setViewDataFieldsTable,
  setViewDataFilterTable,
  runValidation,
  viewDataFieldPropTable,
}) => {
  const BaseUrl = GetBaseUrl()[0];
  const [TableColumns, setTableColumns] = useState([]);
  const [tableHead, setTableHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  const [ColumnsOptions, setColumnsOptions] = useState([]);
  const [restrictionTypes, setRestrictionTypes] = useState();
  const [Json, setJson] = useState([]);
  let AllDataProp = [];

  const [viewConditionalFormatting, setViewConditionalFormatting] =
    useState(false);
  //----------------------------------------------
  function setAssignedColumn(Item) {
    setJson([...Json, Item]);
    // console.log(Json);
  }
  //--------
  function saveAssignedColumns(run, inbehind = false) {
    // console.log(Json);
    const BaseUrl = GetBaseUrl()[0];
    var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
    try {
      axios({
        method: "post",
        url: BaseUrl + "/Data/AssignColumnFieldData",
        data: Json,
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (!inbehind) {
            toast.success("Data Fields have been updated", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          if (run) {
            setTimeout(function () {
              toast.info("Validation in Progress", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }, 2000);
            setTimeout(function () {
              runValidation(TableId);
            }, 5000);
          }
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        });
    } catch (error) {
      toast.error("error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }
  //------------------------------------------------------
  const [ColumnId, setColumnId] = useState("");
  const [ColumnName, setColumnName] = useState("");
  const [reloadColumnProp, setReloadColumnProp] = useState(null);
  const [columnProps, setColumnProps] = useState([]);
  const [ByDataType, setByDataType] = useState([]);
  const [FieldPropsIcon, setFieldPropsIcon] = useState({
    column: "000",
    type: "TextNumber",
  });
  const [currentColumnDataType, setCurrentColumnDataType] = useState([]);
  //---------------------------------------------------
  useEffect(() => {
    var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
    let tableHead = ["Field Name", "Data Type", "Field Properties"];
    let tablebodyCol = ["columnName"];
    setByDataType([]);

    if (TableId) {
      setViewDataFieldPropTable(false);
      setFilterTableId(TableId);
      // console.log(BaseUrl + "/Data/GetColumnsByTable/" + TableId);
      setTAbleUrl(BaseUrl + "/Data/GetColumnsByTable/" + TableId);
      axios
        .get(BaseUrl + "/Data/GetColumnsByTable/" + TableId, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          setTableName(res.data.tableName);
          setTableColumns(res.data.columns);
          res.data.columns.map((item) => (AllDataProp[item.columnId] = []));
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        })
        .finally(() => {
          setTableHead(tableHead);
          setBodyCol(tablebodyCol);
        });
    }

    AllDataProp = [];
    if (tableUrl) {
      axios
        .get(tableUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          res.data.columns.map((item) => (AllDataProp[item.columnId] = []));
        })
        .catch(() => {})
        .finally(() => {});
    }
  }, [TableId]);
  //---------------------------------------------------
  function CurrentColumnFunc(params) {
    setCurrentColumnDataType([]);
    setTimeout(function () {
      setCurrentColumnDataType(params);
    }, 1000);
  }
  //---------------------------------------------------

  //-------------------------------------------------------customSequence
  const [dataTypeId, setDataTypeId] = useState(0);
  const [propertiesSequence, setPropertiesSequence] = useState([]);
  function getObjectByKeyValue(jsonArray, key, value) {
    return jsonArray.find((obj) => obj[key] === value);
  }

  useEffect(() => {
    let foundSequenceObj = getObjectByKeyValue(
      sequenceArray,
      "dataType",
      dataTypeId
    );

    // console.log(dataTypeId);
    // console.log(foundSequenceObj);

    dataTypeId &&
      setPropertiesSequence(foundSequenceObj ? foundSequenceObj.sequence : []);
  }, [dataTypeId, ColumnId]);

  let sequenceArray = [
    {
      dataType: 1, //Number
      sequence: ["int", "options", "range", "radio", "checkbox"],
    },
    {
      dataType: 2, //Number
      sequence: ["int", "options", "range", "radio", "checkbox"],
    },
    {
      dataType: 3, //Number
      sequence: ["int", "options", "range", "radio", "checkbox"],
    },
    {
      dataType: 4, //Text
      sequence: ["text", "int", "radio", "options", "checkbox"],
    },
    {
      dataType: 5, //Date
      sequence: ["dateRange", "int", "options", "radio", "checkbox"],
    },

    {
      dataType: 6, //Boolean
      sequence: ["int", "options", "radio", "checkbox"],
    },
    {
      dataType: 8, //contact
      sequence: ["int", "radio", "options", "checkbox"],
    },
    {
      dataType: 9, //datetime
      sequence: ["datetimeRange", "int", "options", "radio", "checkbox"],
    },
    {
      dataType: 10, //time
      sequence: ["timeRange", "int", "options", "radio", "checkbox"],
    },
  ];
  //-------------------------------------------------------customSequence

  useEffect(() => {
    // console.log(propertiesSequence);
  }, [propertiesSequence]);

  return (
    <>
      {viewDataFieldPropTable ? (
        <Fields
          dataTypeId={dataTypeId}
          setDataTypeId={setDataTypeId}
          propertiesSequence={propertiesSequence}
          TableId={TableId}
          TableName={tableName}
          viewDataFieldPropTable={viewDataFieldPropTable}
          setViewDataFieldPropTable={setViewDataFieldPropTable}
          setViewDataFieldsTable={setViewDataFieldsTable}
          ColumnId={ColumnId}
          ColumnsOptions={ColumnsOptions}
          setRestrictionTypes={setRestrictionTypes}
          setColumnsOptions={setColumnsOptions}
          runValidation={runValidation}
          AllDataProp={AllDataProp}
          reloadColumnProp={reloadColumnProp}
          setByDataType={setByDataType}
          ByDataType={ByDataType}
          ColumnName={ColumnName}
          currentColumnDataType={currentColumnDataType}
          CurrentColumnFunc={CurrentColumnFunc}
          setCurrentColumnDataType={setCurrentColumnDataType}
          saveAssignedColumns={saveAssignedColumns}
          setViewConditionalFormatting={setViewConditionalFormatting}
          viewConditionalFormatting={viewConditionalFormatting}
        />
      ) : viewConditionalFormatting ? (
        <ConditionalFormatting
          TableId={TableId}
          TableName={tableName}
          TableColumns={TableColumns}
          ColumnId={ColumnId}
          ColumnName={ColumnName}
          setViewDataFieldPropTable={setViewDataFieldPropTable}
          setViewConditionalFormatting={setViewConditionalFormatting}
          viewConditionalFormatting={viewConditionalFormatting}
          currentColumnDataType={currentColumnDataType}
          ColumnsOptions={ColumnsOptions}
        />
      ) : tableUrl ? (
        <PublicTable
          loadingText="Loading Table"
          TableTitle={tableName}
          TableId={TableId}
          TableUrl={tableUrl}
          TableHead={tableHead}
          BodyCol={bodyCol}
          lastColumn={false}
          withOutBorder={false}
          defaultRows={tableRowsDefault ? tableRowsDefault : "10"}
          withHeader="Save"
          firstColumn={false}
          InternalColumns
          DataTypeComponent
          RestrictionsComponent
          setAssignedColumn={setAssignedColumn}
          saveAssignedColumns={saveAssignedColumns}
          FilterTableId={FilterTableId}
          runValidation={runValidation}
          setViewDataFieldsTable={setViewDataFieldsTable}
          setViewDataFilterTable={setViewDataFilterTable}
          TableClassName="table table-bordered mt-4"
          TableHeadTrClassName="fs-6"
          TableThClassName="fw-semibold p-3"
          TableBodyTrClassName=""
          TableTdClassName="d-flex justify-content-between align-items-center ps-1"
          setColumnsOptions={setColumnsOptions}
          ColumnsOptions={ColumnsOptions}
          setRestrictionTypes={setRestrictionTypes}
          setViewDataFieldPropTable={setViewDataFieldPropTable}
          setColumnId={setColumnId}
          ADP={AllDataProp}
          reloadColumnProp={reloadColumnProp}
          setReloadColumnProp={setReloadColumnProp}
          setColumnProps={setColumnProps}
          setByDataType={setByDataType}
          ByDataType={ByDataType}
          setColumnName={setColumnName}
          FieldPropsIcon={FieldPropsIcon}
          setFieldPropsIcon={setFieldPropsIcon}
          ColumnName={ColumnName}
          setCurrentColumnDataType={setCurrentColumnDataType}
          currentColumnDataType={currentColumnDataType}
          hidePagination={true}
        />
      ) : null}
    </>
  );
};

export default TableFields;
