import React, { useState, useContext } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import disconnect_gif from "../../assets/gif/disconnect.gif";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { IndexContext } from "../layout/Layout";

function Disconnect(props) {
  //---------------------------------------------------------Index Style
  const IndexProvider = useContext(IndexContext);
  let PIS = IndexProvider.indexStyle;
  //---------------------------------------------------------Index Style

  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const handleDisconnect = async () => {
    const response = await axios
      .delete(
        BaseUrl +
          "/Platform/DeletePlatformConnection/" +
          props.PlatformId +
          "/" +
          props.ConnectionId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .catch((error) => console.log("Error: ", error));
    if ((response && response.data) || response.status === 200) {
      toast.success("Platform Connection has been removed", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
      if (props.PlatformId === "1") {
        props.setOffice_Connection(false);
      }
      if (props.PlatformId === "2") {
        props.setCanvas_Connection(false);
      }
      if (props.PlatformId === "3") {
        props.setBb_Connection(false);
      }
      props.setReloadTable(Math.random());
      // window.location.href='/DataInput/Directory'
    }
  };

  return (
    <div className={`w-100`}>
      <Button
        className={`rounded-pill fw-semibold fs-7 mx-1 w-100`}
        variant="danger"
        onClick={handleShow}
      >
        Disconnect
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className="p-0 pb-0 pt-3 px-3" closeButton></Modal.Header>
        <Modal.Body className="p-2 mb-3">
          <div className="d-flex flex-column align-items-center p-2">
            <img className="w-30 mb-2" src={disconnect_gif} />
            <span className="fw-medium fs-5 text-center">
              Are you sure you want to disconnect with this directory?
            </span>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 d-flex ">
          <div className="d-flex justify-content-center w-100">
            <Button
              className=" w-30 fw-semibold rounded-pill py-2 mx-2"
              variant="secondary"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              className="main-button w-30 fw-semibold rounded-pill py-2"
              variant=""
              onClick={handleDisconnect}
            >
              Yes
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Disconnect;
