import { React, useEffect, useState, useContext } from "react";
import TableList from "../components/UploadedTable/TableList";
import GroupList from "../components/UploadedTable/GroupList";
import Tableheader from "../components/table/Tableheader";
import TableResult from "../components/table/TableResult";
import Pagination from "../components/pagination/Pagination";
import GetBaseUrl from "../functions/SetBaseUrl";
import TableFields from "../components/table/TableFields";
//import Fields from "./Fields";
import { IndexContext } from "../components/layout/Layout";

import TableFilter from "../components/table/TableFilter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const Tables = () => {
  const [groupsList, setGroupsList] = useState([]);
  const [tablesList, setTablesList] = useState([]);
  const [viewTable, setViewTable] = useState();
  const [reloadLists, setReloadLists] = useState(true);
  const [Syncing, setSyncing] = useState(false);
  const [viewDataFieldsTable, setViewDataFieldsTable] = useState();
  const [viewDataFieldPropTable, setViewDataFieldPropTable] = useState();
  const [viewDataFilterTable, setViewDataFilterTable] = useState();
  const [FilterTableId, setFilterTableId] = useState();
  const [reload, setReload] = useState(null);
  const [anySyncing, setAnySyncing] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const IndexProvider = useContext(IndexContext);
  IndexProvider.setPathName("Tables");
  //----------------------------------------------
  function runValidations(GroupId) {
    axios
      .get(BaseUrl + "/Data/ValidateDataByGroup/" + GroupId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Group Validation Done", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch(() => {
        toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
      });
  }
  //----------------------------------------------
  function syncTables(GroupId) {
    setSyncing(true);
    setReloadLists(false);
    axios
      .post(BaseUrl + "/Sync/SyncAPIDataByGroup/" + GroupId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {})
      .catch(() => {
        toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {
        toast.success("Group Syncing has been Done", {
          position: toast.POSITION.TOP_RIGHT,
        });

        setSyncing(false);
        setReloadLists(true);
      });
  }

  //----------------------------------------------
  function runValidation(TableId) {
    setIsValidating(true);
    axios
      .get(BaseUrl + "/Data/ValidateDataByTable/" + TableId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        toast.success("Validation Done", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setViewTable(TableId);
        setViewDataFieldsTable("");
        setIsValidating(false);
      })
      .catch(() => {
        toast.error("Incompleted Validation", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }
  //----------------------------------------------
  function exportTable(TableId, hasError) {
    let FileUrl = "";
    axios
      .get(
        BaseUrl +
          "/Data/GetExcelFileByTable/" +
          TableId +
          "?HasErrors=" +
          hasError,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          FileUrl = res.data;
        }
      })
      .catch((error) => {
        toast.error("Export File Error", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .finally(() => {
        if (FileUrl) {
          window.location.href = FileUrl;
        }
      });
  }
  //----------------------------------------------
  function syncTable(GroupId, TableId) {
    setSyncing(true);
    setReloadLists(false);
    let TId = "";

    axios
      .post(BaseUrl + "/Sync/SyncApiData/" + GroupId + "?TableId=" + TableId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        TId = res.data;
        setViewTable("");
      })
      .catch(() => {
        toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {
        toast.success("Sync Api Data has been Done", {
          position: toast.POSITION.TOP_RIGHT,
        });
        if (TableId) {
          setViewTable(TId);
          setViewDataFieldsTable("");
        }
        setSyncing(false);
        setReloadLists(true);
      });
  }
  //----------------------------------------------

  useEffect(() => {
    if (reloadLists) {
      axios
        .get(BaseUrl + "/Data/GetGroups", {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          if (res.data !== "No groups found.") {
            setGroupsList(res.data);
          }
          //
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        });

      let arr = [];
      axios
        .get(BaseUrl + "/Data/GetTables", {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          arr = res.data.sort((a, b) => a.apiId - b.apiId);

          //console.log(res.data);
        })
        .catch(() => {
          toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
        })
        .finally(() => {
          setReloadLists(false);
          setTablesList(arr);
        });
    }
  }, [reloadLists]);
  //------------------------------------------------------

  const renderItem = (item, index) => (
    <div className="fw-semibold" key={index}>
      {item}
    </div>
  );
  //------------------------------------------------------

  useEffect(() => {
    // console.log('Here: '+ viewDataFieldsTable);
  }, [viewDataFieldsTable]);

  //------------------------------------------------------
  function FindIndexInObj(array, Key, Value) {
    var msg = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i;
        break;
      }
    }
    return msg;
  }
  //------------------------------------------------------
  useEffect(() => {
    if (FindIndexInObj(tablesList, "syncing", true) !== -1) {
      setTimeout(function () {
        setReloadLists(true);
      }, 10000);
    } else {
      setReloadLists(false);
    }
  }, [tablesList]);
  //------------------------------------------------------

  //------------------------------------------------------

  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-4 col-xl-3 px-0 pe-3 table-scroll">
            <div className="top-bg p-4 rounded-5 min-vh-100">
              <div className="row d-flex flex-column h-100">
                <div className="col-6 w-100 px-1">
                  <TableList
                    renderItem={renderItem}
                    tablesList={tablesList}
                    setViewTable={setViewTable}
                    IsViewTable={true}
                    setViewDataFieldsTable={setViewDataFieldsTable}
                    setViewDataFilterTable={setViewDataFilterTable}
                    runValidation={runValidation}
                    syncTable={syncTable}
                    setTablesList={setTablesList}
                    setReloadLists={setReloadLists}
                    setSyncing={setSyncing}
                  />
                </div>

                <div className="col-6 w-100 px-1">
                  <GroupList
                    renderItem={renderItem}
                    GroupsList={groupsList}
                    setViewTable={setViewTable}
                    IsViewTable={true}
                    setViewDataFieldsTable={setViewDataFieldsTable}
                    setViewDataFilterTable={setViewDataFilterTable}
                    runValidation={runValidation}
                    syncTable={syncTable}
                    runValidations={runValidations}
                    syncTables={syncTables}
                    setReload={setReload}
                    setReloadLists={setReloadLists}
                    setSyncing={setSyncing}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-xl-9 px-0 ">
            <div className="top-bg rounded-5 min-vh-100">
              {/* <Tableheader/> */}
              <div className="">
                {viewTable ? (
                  <TableResult
                    TableId={viewTable}
                    setViewDataFieldsTable={setViewDataFieldsTable}
                    setViewTable={setViewTable}
                    HeaderType="ViewTable"
                    Syncing={Syncing}
                    tableRowsDefault="10"
                    exportTable={exportTable}
                    setSyncing={setSyncing}
                    isValidating={isValidating}
                  />
                ) : null}

                {viewDataFieldsTable ? (
                  <TableFields
                    TableId={viewDataFieldsTable}
                    viewDataFieldPropTable={viewDataFieldPropTable}
                    setViewDataFieldPropTable={setViewDataFieldPropTable}
                    setViewDataFieldsTable={setViewDataFieldsTable}
                    setViewDataFilterTable={setViewDataFilterTable}
                    setViewTable={setViewTable}
                    viewDataFilterTable={viewDataFilterTable}
                    FilterTableId={FilterTableId}
                    setFilterTableId={setFilterTableId}
                    tableRowsDefault="200"
                    runValidation={runValidation}
                  />
                ) : null}

                {viewDataFilterTable ? (
                  <TableFilter
                    ColumnId={viewDataFilterTable[0]}
                    ColumnName={viewDataFilterTable[1]}
                    FilterTableId={FilterTableId}
                    setViewDataFieldPropTable={setViewDataFieldPropTable}
                    setViewDataFilterTable={setViewDataFilterTable}
                    setViewDataFieldsTable={setViewDataFieldsTable}
                    setViewTable={setViewTable}
                    tableRowsDefault="10"
                    runValidation={runValidation}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tables;
