import {React, useEffect, useState } from "react";
import QueryArea from '../components/query/QueryArea'
import Result from '../components/TableResults/Result'
import GenerateDashboard from '../components/modals/GenerateDashboard'
import { Button, Dropdown } from 'react-bootstrap'
import xls from '../assets/svg/xls_1.svg'
import pdf from '../assets/svg/pdf.svg'
import OutputTableList from '../components/output/OutputTableList'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import FirstReportResult from '../components/table/FirstReportResult'
import FIndNReplace from '../components/modals/FIndNReplace'
import SetFilter from '../components/modals/Filter'
import export_icon from '../assets/svg/export.svg'
import edit_header from '../assets/svg/editHeader.svg'

import GetBaseUrl from '../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
const BaseUrl = GetBaseUrl()[0]   ;
const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");








function ReportOption() {


  const [tablesList, setTablesList] = useState([]);
  const [viewTable, setViewTable] = useState();
  const [viewReport, setViewReport] = useState(null);
  const [obj, setObj] = useState(null);
  const [Title, setTitle] = useState('');
  const [ChangeColumns, setChangeColumns] = useState(null);
  

  useEffect(() => { 

          axios
          .get( BaseUrl  +   '/Relations2/GetAllRelationships', {
              headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
              },
          })
          .then((res) => {
            setTablesList(res.data )
          }).finally(() => {}) ;

  }, [])
  const renderItem = (item, index) =>    <div className='fw-semibold' key={index}>{item}</div>   




  function ExportReport(Json) {
//console.log(Json);
      axios
      .post( BaseUrl  +  '/Report/GetFullReportByRelationId/', Json, {
          headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          },
      })
      .then((res) => {
        
        window.location.href=res.data
  
      }).catch((error) => {
  
        toast.error('Error: '+error, {        position: toast.POSITION.TOP_RIGHT      });  
  
      }).finally(() =>{
   
      });
  
  }




  const [newName, setNewName] = useState('');



  
  return (
    <div>
    <div className = "rounded-5 main-bg p-35 min-vh-85">
   <div className='row'>
        <div className = "col-lg-4 col-xl-3 px-0 pe-2">
        <div className='d-flex'>
            <div className='top-bg p-4 w-100 rounded-5 min-vh-100'>
            <OutputTableList
            
            renderItem={renderItem}
            tablesList={tablesList}
            setViewTable={setViewTable}            
            setViewReport={setViewReport}
            setObj={setObj}
            obj={obj}
            setTitle={setTitle}
            setChangeColumns={setChangeColumns}
            newName={newName}
            />
            </div>
            </div>

        </div>
    
    <div className = "col-lg-9 col-xl-9 px-0 ">
    <div className = "top-bg rounded-5 mb-4">


      <QueryArea
      obj={obj}
      viewReport={viewReport}
      setNewName={setNewName}
      newName={newName}
      ChangeColumns={ChangeColumns}
      />
    </div>
    <div className = "top-bg p-4 rounded-5 min-vh-75">
    <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex'>
            <h4 className = "fw-semibold mb-0 fs-5">{Title}</h4>
        </div>
        <div className='d-flex align-items-center justify-content-between rounded-pill py-2 px-2 bg-body-tertiary mb-3 w-100' variant="">
            <h4 className = "fw-semibold mb-0 text-black ps-2 fs-6">Results</h4>
            <div className="d-flex align-items-center">
            <Button className='d-flex align-items-center px-2' variant='' >
              <img src={edit_header}/>
              <span className='ms-1 fw-semibold'>Edit Header</span>
            </Button>
            <SetFilter/>
            <Button className="rounded-pill main-button fw-semibold fs-7"><img src = {export_icon}/> <span>Export</span></Button>
            </div>
        </div>
         </div>
        
        


      </div>
   </div>
    </div>

    </div>
    </div>
  )
}

export default ReportOption
