import { React } from "react";

import "react-toastify/dist/ReactToastify.css";
import Field_Properties from "../components/modals/FieldPropertiesPage";

const Fields = (props) => {
  return (
    <div>
      <div className="rounded-5 main-bg p-35 min-vh-85">
        <div className="row">
          <div className="col-lg-12 col-xl-12 px-0 ">
            <div className="top-bg rounded-5 min-vh-100">
              <Field_Properties
                setViewDataFieldPropTable={props.setViewDataFieldPropTable}
                setViewDataFilterTable={props.setViewDataFilterTable}
                setViewDataFieldsTable={props.setViewDataFieldsTable}
                setViewTable={props.setViewTable}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Fields;
