import React from 'react'
import file from '../../assets/svg/file.svg'
import './platform.css'

function PlatformFiles() {
  return (
    <div>
    <div className='d-flex justify-content-between align-content-center my-2 files'>
         <div className='d-flex align-items-center w-100'>
              <img src={file}/>
              <span className='fs-7 text-truncate mx-2'>Student Information</span>
          </div>
         </div>
  </div>
  )
}

export default PlatformFiles
