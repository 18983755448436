import React, { useState, useRef, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import GetBaseUrl from "../../functions/SetBaseUrl";
import errorImg from "../../assets/svg/error-fill.svg";

const Form_Modal = (props) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <img
        alt="error"
        src={errorImg}
        onClick={handleShow}
        style={{ position: "absolute", right: "35px", top: "20px" }}
      />

      <Modal
        centered
        fullscreen={props.FullScreen}
        size={props.size}
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <b>Recomended Query</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 style={{ color: "orange" }}>{props.queryStructures}</h4>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Use
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default Form_Modal;
