import React, { useState } from "react";
import PlatformTableItem from "./PlatformTableItem";
import { Button, Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

const TableList = ({
  setViewConnection,
  IsPlatformTable,
  tablesList,
  setViewTable,
  IsViewTable,
  PlatformType,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <div className=" d-flex flex-column justify-content-between h-100 ">
      <div className="d-flex justify-content-between align-items-center mb-1">
        <div className="d-flex align-items-center">
          {/* <Link to="/DataInput/Directory"> */}
          <Button variant="" className="p-2">
            <i
              className="edu ic-left"
              onClick={() => setViewConnection(false)}
            />
          </Button>
          {/* </Link> */}
          <span className="fs-5 fw-semibold third text-black">
            {PlatformType}
          </span>
        </div>
      </div>
      <div className="">
        {tablesList &&
          tablesList.map((item, index) => (
            <PlatformTableItem
              key={index}
              setViewTable={setViewTable}
              tableId={item.id}
              Title={item.apiName}
              IsViewTable={IsViewTable}
              IsPlatformTable={IsPlatformTable}
              setViewConnection={setViewConnection}
            />
          ))}
      </div>
    </div>
  );
};

export default TableList;
