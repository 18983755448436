import React, { useState } from 'react'
import { Modal, Form, InputGroup } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import blackboard from '../../../assets/svg/blackboard.svg'
import GetBaseUrl from '../../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import BlackBoard from '../../Platforms/BlackBoard'
import { Link } from 'react-router-dom'

function ConnectBlackBoard(props){

    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState(""); 
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const Redirect = () => {
      window.location.href='/BlackBoard/Platform/Tables';
    };
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");

    const [ConnectionId, setConnectionId] = useState('');
    const [Connected, setConnected] = useState(false);

const [Bb_Username, setBb_Username] = useState('');
const [Bb_Password, setBb_Password] = useState('');
const [Bb_BaseURL, setBb_BaseURL] = useState('');
const [ConnectionName, setConnectionName] = useState('');
const handleChange = event => {
  if(event.target.name==='Bb_Username'){
    setBb_Username(event.target.value);
  }
  else if(event.target.name==='Bb_Password'){
    setBb_Password(event.target.value);
  }
  else if(event.target.name==='Bb_BaseURL'){
    setBb_BaseURL(event.target.value);
  }
  else if(event.target.name==='ConnectionName'){
    setConnectionName(event.target.value);
  }
};    
 
const handleConnection = async () => {

      if(!Bb_Username || !Bb_Password || !Bb_BaseURL  || !ConnectionName){  toast.warning('Fill Required Fields') ; return false; }
      const ToPost = {"platformId": 3, "applicationId": Bb_Username, "secretKey": Bb_Password, "baseurl": Bb_BaseURL, "connectionName" : ConnectionName}; 
      console.log(ToPost);
      const response = await axios
          .post(BaseUrl+"/Platform/AddBlackboardConnection", ToPost, {   
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error));
          if (response.status===200 ) {

                toast.success('Connected successfully', {
                  position: toast.POSITION.TOP_RIGHT
                });
                handleClose();
                setConnected(true)
                setConnectionId(response.data.connectionId)                
          }
          else{
                toast.error('Incorrect credentials', {
                  position: toast.POSITION.TOP_RIGHT
                });
          }          
};

  return (
<div className = "rounded-5 main-bg h-100">
  {!Connected ?
    <div className = "top-bg rounded-5 h-100">
    <div className='d-flex flex-column align-items-center w-30'>
    <div className='d-flex align-items-center w-100 mb-4'>
    <Link to='/DataInput/Directory'   >
    <Button variant='' className='p-0 pe-3'><i className='edu ic-left'/></Button>
    </Link>
    <img src = {blackboard} className='w-50 '/>
    </div>
    <div className='w-100'>
    <Form.Label className='fw-semibold'>Directory Name</Form.Label>
    <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-4' placeholder="Enter Directory Name" name='ConnectionName' onChange={handleChange}/>
    <Form.Label className='fw-semibold'>Connect to Directory</Form.Label>
    <Form.Control type="text" className=' p-3 py-2 rounded-pill w-100 mb-3' placeholder="Application Id" name='Bb_Username' onChange={handleChange} />
    <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="secretKey" name='Bb_Password' onChange={handleChange} />
    <Form.Control type="text" className='p-3 py-2 rounded-pill w-100 mb-4' placeholder="BaseURL" name='Bb_BaseURL' onChange={handleChange} />
    <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleConnection}>
      Connect & Save
    </Button>
    
    </div>
</div>
    </div>

    :
  <BlackBoard  ConnectionId={ConnectionId} />
  }    
    </div>
  )
}

export default ConnectBlackBoard
