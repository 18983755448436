import React from "react";
import "./topnav.css";
import { Link } from "react-router-dom";
import Dropdown from "../dropdown/Dropdown";
import ThemeMenu from "../thememenu/ThemeMenu";
import notifications from "../../assets/JsonData/notification.json";
import empty_user from "../../assets/svg/empty-user.svg";
import user_menu from "../../assets/JsonData/user_menus.json";
import GetBaseUrl from "../../functions/SetBaseUrl";
import collapseIcon from "../../assets/images/collapseIcon.svg";
import styles from "../../components/layout/Layout.module.scss";
import IndexStyles from "../../assets/css/index.module.scss";

const curr_user = {
  display_name: localStorage.getItem(GetBaseUrl()[1] + "_Edu_Fullname"),
  image: empty_user,
};

const renderNotificationItem = (item, index) => (
  <div className="notification-item" key={index}>
    <i className={item.icon}></i>
    <span>{item.content}</span>
  </div>
);

const renderUserToggle = (user) => (
  <div className="topnav__right-user">
    <div className="topnav__right-user__image">
      <img src={user.image} alt="" />
    </div>
    <div className={`topnav__right-user__name ${styles["username-account"]}`}>
      {user.display_name}
    </div>
  </div>
);

function logOut(name) {
  if (name === "Sign out") {
    localStorage.setItem(GetBaseUrl()[1] + "_Edu_AuthToken", "");
    var filePath = "/";
    var link = document.createElement("a");
    link.href = filePath;
    link.click();
  }
}

const renderUserMenu = (item, index) => (
  <Link to="/" key={index} onClick={() => logOut(item.content)}>
    <div className="notification-item">
      <i className={item.icon}></i>
      <span>{item.content}</span>
    </div>
  </Link>
);

const Topnav = ({ pathName, setCollapsedNavbar, collapsedNavbar }) => {
  return (
    <>
      <div
        className={`topnav ${styles["responsive-topNav"]} align-items-center justify-content-between`}
      >
        <div className="align-items-center">
          <img
            src={collapseIcon}
            className={`${styles["collapseIcon"]}`}
            alt="collapseIcon"
            onClick={() => setCollapsedNavbar(!collapsedNavbar)}
          />
          <h2 className={`fw-semibold  ${styles["pathName"]}`}>{pathName}</h2>
        </div>

        <div className="d-flex w-75 justify-content-end ">
          <div
            className={`topnav__search w-50 me-5 ${styles["search-container"]}`}
          >
            <input type="text" placeholder="Search" />
            <i className="bx bx-search"></i>
          </div>
          <div className="topnav__right ">
            <div
              className={`topnav__right-item mt-2 ${styles["notification-container"]}`}
            >
              <Dropdown
                icon="edu ic-noti"
                badge="12"
                contentData={notifications}
                renderItems={(item, index) =>
                  renderNotificationItem(item, index)
                }
                renderFooter={() => <Link to="/">View All</Link>}
              />
              {/* dropdown here */}
            </div>

            <div className="topnav__right-item">
              {/* dropdown here */}
              <Dropdown
                customToggle={() => renderUserToggle(curr_user)}
                contentData={user_menu}
                renderItems={(item, index) => renderUserMenu(item, index)}
                showTitle
              />
            </div>

            <div className="topnav__right-item" style={{ display: "none" }}>
              <ThemeMenu />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Topnav;
