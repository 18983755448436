import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import delete_gif from "../../../assets/gif/delete.gif";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import GetBaseUrl from "../../../functions/SetBaseUrl";
import axios from "axios";

function Delete(props) {
  const handleClose = () => props.setDeleteMode(false);
  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  var loggedInUsername = localStorage.getItem(GetBaseUrl()[1] + "_username");

  function handleDelete() { 

    props.selectedUser.username === loggedInUsername ?
    toast.warning("You can not delete your account", {
      position: toast.POSITION.TOP_RIGHT,
    })
    :
    axios
      .delete(
        BaseUrl + "/UserManagement/DeleteUser/" + props.selectedUser.userId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        toast.success("User has been deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });

        props.refetchUsers();
        handleClose();
      })
      .catch((res) => {
        toast.error(res + "", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  }

  return (
    <Modal centered show onHide={handleClose}>
      <Modal.Header className="p-0 pb-0 pt-3 px-3" closeButton></Modal.Header>
      <Modal.Body className="p-2 mb-3">
        <div className="d-flex flex-column align-items-center p-2">
          <img className="w-30 mb-2" src={delete_gif} />
          <span className="fw-medium fs-5">
            Are you sure you want to delete this user?
          </span>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0 d-flex ">
        <div className="d-flex justify-content-center w-100">
          <Button
            className=" w-30 fw-semibold rounded-pill py-2 mx-2"
            variant="secondary"
            onClick={handleClose}
          >
            No
          </Button>
          <Button
            className="main-button w-30 fw-semibold rounded-pill py-2"
            variant=""
            onClick={() => handleDelete()}
          >
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default Delete;
