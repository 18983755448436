import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardStatics.module.scss";
import StatisticIcon_1 from "../../assets/svg/Dashboard/StatisticIcon_1.svg";
import StatisticIcon_2 from "../../assets/svg/Dashboard/StatisticIcon_2.svg";
import StatisticIcon_3 from "../../assets/svg/Dashboard/StatisticIcon_3.svg";
import StatisticIcon_4 from "../../assets/svg/Dashboard/StatisticIcon_4.svg";
import StatisticIcon_5 from "../../assets/svg/Dashboard/StatisticIcon_5.svg";
import StatisticIcon_6 from "../../assets/svg/Dashboard/StatisticIcon_6.svg";
import EditQuery from "../../assets/images/EditQuery.svg";
import QueryStaticsModal from "./QueryStaticsModal";
import { GetAPISavedStaticsQuery } from "../../functions/GetAPIList";
import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

const DashboardStatics = (props) => {
  const [statistics, setStatistics] = useState([
    { index: 201, value: 0, title: "Potential Students" },
    { index: 202, value: 0, title: "Signed NDA" },
    { index: 203, value: 0, title: "Logged In" },
    { index: 204, value: 0, title: "Active" },
    { index: 205, value: 0, title: "Total Prompts Entered" },
    { index: 206, value: 0, title: "Total Flagged Prompts" },
  ]);

  const [askQueryModal, setAskQueryModal] = useState(false);
  const [queryId, setQueryId] = useState();
  const [reloadStatistics, setReloadStatistics] = useState(true);
  const [selectedStatics, setSelectedStatics] = useState([]);

  const [statisticKey, setStatisticKey] = useState(0);
  const [selected, setSelected] = useState();
  const [query, setQuery] = useState();

  const [activePeriod, setActivePeriod] = useState(30);

  let queryStructures = [
    `SELECT 
   COUNT - DISTINCT(id)`,

    `Select 
   Count`,
    `Select 
   Count`,
    `Select 
   Count`,
    `SELECT 
   COUNT`,
    `Select 
   Count`,
  ];

  //----------------------------------------------
  useEffect(() => {
    const SavedStaticsQuery = async () => {
      try {
        const data = await GetAPISavedStaticsQuery();
        setSelectedStatics(data);
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };
    if (reloadStatistics) {
      SavedStaticsQuery();
    }
  }, [reloadStatistics]);
  //----------------------------------------------
  const formatNumber = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return num;
    }
  };

  //---------------------------------------------------
  const [columns, setColumns] = useState([]);
  const [selectedDateColumn, setSelectedDateColumn] = useState("");
  const handleChangeDateColumn = (event) => {
    setSelectedDateColumn(event.target.value);
  };
  //----------------------------------------------
  const viewQueryResult = (index) => {
    let obj = {
      queryId: index,
      Period: activePeriod,
      filterData: props.selectedFilters,
    };
    // console.log(obj);
    let updatedArray = [...statistics];
    axios
      .post(BaseUrl + "/Report/GetDashboardReportQueryData", obj, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data && res.data.queryName) {
          for (var i = 0; i < updatedArray.length; i++) {
            if (updatedArray[i].index === index) {
              updatedArray[i].value = res.data.total;
              updatedArray[i].title = res.data.queryName;
              break;
            }
          }
        }
      })
      .catch((res) => {
        for (var i = 0; i < updatedArray.length; i++) {
          if (updatedArray[i].index === index) {
            updatedArray[i].value = 0;
            updatedArray[i].title = "Error";
            break;
          }
        }
      })
      .finally(() => {
        setStatistics(updatedArray);
      });
  };
  //---------------------
  useEffect(() => {
    // console.log(statistics);
    // for (let i = 0; i < statistics.length; i++) {
    //   const element = statistics[i];
    //   viewQueryResult(element.index);
    // }
    // setReloadStatistics(false);
  }, [statistics]);

  //******************** */
  const getObjByIndex = (array, i) => {
    const item = array.find((element) => element.index === i);
    return item ? item : "noObject";
  };
  //---------------------
  useEffect(() => {
    // console.log(statistics);
    // console.log(props.reloadDashboard);
    // console.log(props.selectedFilters);

    setActivePeriod(30);
    for (let i = 0; i < statistics.length; i++) {
      const element = statistics[i];
      viewQueryResult(element.index);
    }
  }, [props.reloadDashboard]);
  //---------------------
  useEffect(() => {
    viewQueryResult(204);
  }, [activePeriod]);
  //---------------------------------------------------

  useEffect(() => {
    // console.log(queryId);

    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedStaticsQuery();
        // console.log(data);
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === queryId) {
            // console.log(element);
            setSelectedDateColumn(element.dateColumnId);
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          } else {
            setQuery({
              queryName: statistics[selected].title,
              reportId: 0,
              query: queryStructures[selected],
            });
          }
        }
      } catch (error) {
        console.error("Error :", error);
      }
    };
    SavedGraphQuery();

    // viewQueryResult(queryId);
  }, [queryId]);
  //---------------------------------------------------

  return (
    <>
      <button
        className={`${styles.switcher} ${
          statisticKey === 4 ? styles.active : ""
        }`}
        onClick={() => setStatisticKey(4)}
      >
        Students
      </button>
      <button
        className={`${styles.switcher} ${
          statisticKey === 0 || statisticKey === 2 ? styles.active : ""
        }`}
        onClick={() => setStatisticKey(2)}
      >
        Prompts
      </button>

      {statistics && statistics.length ? (
        <div className={`${styles.container} row`}>
          <div className={`${styles.card1} row`}>
            <div className={`${styles.c1} row`}>
              {props.withQuery && (
                <img
                  src={EditQuery}
                  onClick={() => {
                    setSelected(0);
                    setAskQueryModal(true);
                    setQueryId(201);
                  }}
                  className={`${styles["EditQuery"]}`}
                  alt="EditQuery"
                />
              )}
              <img src={StatisticIcon_1} />
              <div>
                <label>{getObjByIndex(statistics, 201).title}</label>
                <h2>{formatNumber(getObjByIndex(statistics, 201).value)}</h2>
              </div>
            </div>
            <div className={`${styles.c2} row`}>
              {props.withQuery && (
                <img
                  src={EditQuery}
                  onClick={() => {
                    setSelected(1);
                    setAskQueryModal(true);
                    setQueryId(202);
                  }}
                  className={`${styles["EditQuery"]}`}
                  alt="EditQuery"
                />
              )}
              <img src={StatisticIcon_2} />
              <div>
                <label>{getObjByIndex(statistics, 202).title}</label>
                <h2>{formatNumber(getObjByIndex(statistics, 202).value)}</h2>
              </div>
            </div>
            <div className={`${styles.c3} row`}>
              {props.withQuery && (
                <img
                  src={EditQuery}
                  onClick={() => {
                    setSelected(2);
                    setAskQueryModal(true);
                    setQueryId(203);
                  }}
                  className={`${styles["EditQuery"]}`}
                  alt="EditQuery"
                />
              )}
              <img src={StatisticIcon_3} />
              <div>
                <label>{getObjByIndex(statistics, 203).title}</label>
                <h2>{formatNumber(getObjByIndex(statistics, 203).value)}</h2>
              </div>
            </div>
            <div className={`${styles.c4} row`}>
              {props.withQuery && (
                <img
                  src={EditQuery}
                  onClick={() => {
                    setSelected(3);
                    setAskQueryModal(true);
                    setQueryId(204);
                  }}
                  className={`${styles["EditQuery"]}`}
                  alt="EditQuery"
                />
              )}
              <img src={StatisticIcon_4} />
              <div>
                <label>{getObjByIndex(statistics, 204).title}</label>
                {/* </div>

              <div> */}
                <h2>{formatNumber(getObjByIndex(statistics, 204).value)}</h2>
                <div className={`${styles.periodContainer} row`}>
                  <span
                    className={`${styles["periodBtn"]} ${
                      activePeriod === 1 ? styles["active"] : styles[""]
                    }`}
                    onClick={() => setActivePeriod(1)}
                  >
                    24h
                  </span>
                  <span
                    className={`${styles["periodBtn"]} ${
                      activePeriod === 7 ? styles["active"] : styles[""]
                    }`}
                    onClick={() => setActivePeriod(7)}
                  >
                    7d
                  </span>
                  <span
                    className={`${styles["periodBtn"]} ${
                      activePeriod === 30 ? styles["active"] : styles[""]
                    }`}
                    onClick={() => setActivePeriod(30)}
                  >
                    30d
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* -------------------------------------------------------- */}
          {statisticKey === 4 && (
            <>
              <div className={`${styles.card2_2} `}>
                <div className={`${styles.c1} row`}>
                  {props.withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setSelected(0);
                        setAskQueryModal(true);
                        setQueryId(201);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={StatisticIcon_1} />
                  <div>
                    <label>{getObjByIndex(statistics, 201).title}</label>
                    <h2>
                      {formatNumber(getObjByIndex(statistics, 201).value)}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={`${styles.card2_2} `}>
                <div className={`${styles.c1} row`}>
                  {props.withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setSelected(1);
                        setAskQueryModal(true);
                        setQueryId(202);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={StatisticIcon_2} />
                  <div>
                    <label>{getObjByIndex(statistics, 202).title}</label>
                    <h2>
                      {formatNumber(getObjByIndex(statistics, 202).value)}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={`${styles.card2_2} `}>
                <div className={`${styles.c1} row`}>
                  {props.withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setSelected(2);
                        setAskQueryModal(true);
                        setQueryId(203);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={StatisticIcon_3} />
                  <div>
                    <label>{getObjByIndex(statistics, 203).title}</label>
                    <h2>
                      {formatNumber(getObjByIndex(statistics, 203).value)}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={`${styles.card2_2} `}>
                <div className={`${styles.c1} row`}>
                  {props.withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setSelected(3);
                        setAskQueryModal(true);
                        setQueryId(204);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={StatisticIcon_4} />
                  <div>
                    <label>{getObjByIndex(statistics, 204).title}</label>
                    <h2 style={{ marginTop: "-8px" }}>
                      {formatNumber(getObjByIndex(statistics, 204).value)}
                    </h2>
                    <div className={`${styles.periodContainer} row`}>
                      <span
                        className={`${styles["periodBtn"]} ${
                          activePeriod === 1 ? styles["active"] : styles[""]
                        }`}
                        onClick={() => setActivePeriod(1)}
                      >
                        24h
                      </span>
                      <span
                        className={`${styles["periodBtn"]} ${
                          activePeriod === 7 ? styles["active"] : styles[""]
                        }`}
                        onClick={() => setActivePeriod(7)}
                      >
                        7d
                      </span>
                      <span
                        className={`${styles["periodBtn"]} ${
                          activePeriod === 30 ? styles["active"] : styles[""]
                        }`}
                        onClick={() => setActivePeriod(30)}
                      >
                        30d
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* -------------------------------------------------------- */}
          {statisticKey !== 4 && (
            <>
              <div className={`${styles.card2} `}>
                <div className={`${styles.c1} row`}>
                  {props.withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setSelected(4);
                        setAskQueryModal(true);
                        setQueryId(205);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={StatisticIcon_5} />
                  <div>
                    <label>{getObjByIndex(statistics, 205).title}</label>
                    <h2>
                      {formatNumber(getObjByIndex(statistics, 205).value)}
                    </h2>
                  </div>
                </div>
              </div>
              <div className={`${styles.card2} `}>
                <div className={`${styles.c1} row`}>
                  {props.withQuery && (
                    <img
                      src={EditQuery}
                      onClick={() => {
                        setSelected(5);
                        setAskQueryModal(true);
                        setQueryId(206);
                      }}
                      className={`${styles["EditQuery"]}`}
                      alt="EditQuery"
                    />
                  )}
                  <img src={StatisticIcon_6} />
                  <div>
                    <label>
                      {getObjByIndex(statistics, 206).title
                        ? getObjByIndex(statistics, 206).title
                        : "N/A"}
                    </label>
                    <h2>
                      {getObjByIndex(statistics, 206).value
                        ? formatNumber(getObjByIndex(statistics, 206).value)
                        : "0"}
                    </h2>
                  </div>
                </div>
              </div>
            </>
          )}

          {askQueryModal ? (
            <QueryStaticsModal
              setAskQueryModal={setAskQueryModal}
              query={query}
              setQuery={setQuery}
              queryId={queryId}
              defaultStructure={[queryStructures[selected]]}
              selectedStatics={selectedStatics}
              setReloadStatistics={setReloadStatistics}
              setReloadDashboard={props.setReloadDashboard}
              viewQueryResult={viewQueryResult}
              handleChangeDateColumn={handleChangeDateColumn}
              selectedDateColumn={selectedDateColumn}
              setSelectedDateColumn={setSelectedDateColumn}
              columns={columns}
              setColumns={setColumns}
              activePeriod={activePeriod}
              setActivePeriod={setActivePeriod}
            />
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default DashboardStatics;
