import { React, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

import GetBaseUrl from "../../functions/SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

function SelectRelationship(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);

  const [OptionsList, setOptionsList] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  //------------------------------------------------------
  const handleChange = (e) => {
    setSelectedOption(e);
    props.setSelectedRelation(e.value);
    props.setEditReportId(0);
    props.setViewTable("");
  };
  //------------------------------------------------------
  useEffect(() => {
    let tempOptions = [];

    axios
      .get(BaseUrl + "/Relations/GetAllRelationships", {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        res.data &&
          res.data.map((item) =>
            tempOptions.push({
              value: item.relationshipId,
              label: item.relationshipName,
            })
          );
      })
      .finally(() => {
        setOptionsList(tempOptions);
      });
  }, []);
  //------------------------------------------------------

  return (
    <>
      <Button id="add_table" className="p-0" variant="" onClick={handleShow}>
        <i className="edu ic-plus fs-4"></i>
      </Button>

      <Modal centered show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className=" d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="fs-5 fw-semibold fs-7">
              Select Relationship
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="border rounded-pill border-dark">
            <Select
              className="basic-single rounded-pill"
              classNamePrefix="select"
              placeholder="Choose Relationship"
              defaultValue=""
              isClearable={isClearable}
              isSearchable={isSearchable}
              name="color"
              value={selectedOption}
              options={OptionsList}
              onChange={handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="fw-semibold rounded-pill w-45"
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            className="main-button fw-semibold rounded-pill w-45"
            variant=""
            onClick={handleClose}
          >
            Select
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectRelationship;
