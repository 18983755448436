import React, { useEffect, useState, useRef } from "react";
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetBaseUrl from '../../functions/SetBaseUrl'
import axios from "axios";


function ScheduleRun(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");

    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedDayNumber, setSelectedDayNumber] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [option, setOption] = useState(null);
    const [email, setEmail] = useState(null);
    const [isChecked1, setIsChecked1] = useState(false)
    const [isChecked2, setIsChecked2] = useState(false)
    const [isChecked3, setIsChecked3] = useState(false)
  
const [obj, setObj] = useState(
   
   {
      "reportId": props.ReportId,
      "email": email,
      "scheduleType":  option,
      "scheduleValue": option==='Day' ? selectedTime :option==='Week' ? selectedDay+", "+selectedTime  :option==='Month' ? selectedDayNumber+", "+selectedTime : ''
   }   

);



    useEffect(() => {  

if(show){



      let arr1=[]
      let arr=''

               axios
               .get( BaseUrl  +  '/Report/GetSchedule/'+props.ReportId, {
                  headers: {
                  Authorization: `Bearer ${token}`,
                  "content-type": "application/json",
                  },
               })
               .then((res) => { 
            

                  if(res.data.length>0){
                              if(res.data[0].scheduleType==='Week'){
                                 arr1= (res.data[0].scheduleValue).split(', ')

                                 setIsChecked1(false)
                                 setIsChecked2(true)
                                 setIsChecked3(false)                     
                                 setEmail(res.data[0].email)
                                 setOption('Week');
                                 
                                 document.getElementById('Days').value  = arr1[0]
                                 document.getElementById('Time2').value  =arr1[1]

                              }  
                              else if(res.data[0].scheduleType==='Month'){

                                 arr1= (res.data[0].scheduleValue).split(', ')

                                 setIsChecked1(false)
                                 setIsChecked2(false)
                                 setIsChecked3(true)                     
                                 setEmail(res.data[0].email)
                                 setOption('Month');

                                 document.getElementById('dayNumber').value =   arr1[0]
                                 document.getElementById('Time3').value =       arr1[1]


                              }    
                              else if(res.data[0].scheduleType==='Day'){

                                 arr= res.data[0].scheduleValue
                                 setIsChecked1(true)
                                 setIsChecked2(false)
                                 setIsChecked3(false)                     
                                 setEmail(res.data[0].email)
                                 setOption('Day');

                                 document.getElementById('Time1').value  = res.data[0].scheduleValue

                              }                         
                  }



               }).catch(()=>{  })
               .finally(() =>{


                  setObj(
                  {
                     "reportId": props.ReportId,
                     "email": email,
                     "scheduleType":  option,
                     "scheduleValue": option==='Day' ? arr :option==='Week' ? arr1[0]+", "+arr1[1]  :option==='Month' ? arr1[0]+", "+arr1[1] : ''
                  }
                  )



               });



}





   }, [show])





useEffect(() => {  

 //  console.log(obj);

}, [obj])

//------------------------------------------------------
const handleChange = e => { 
    
   let id= e.target.id
   if(id==='Time1' || id==='Time2' || id==='Time3'){
                setSelectedTime(e.target.value);
   }
   else if(id==='Days'){
                setSelectedDay(e.target.value);
   }
   else if(id==='dayNumber'){
                setSelectedDayNumber(e.target.value);
   }






}
//------------------------------------------------------
const handleChangeEmail = event => {
   setEmail(event.target.value);


 };
//------------------------------------------------------
const handleChangeOption = event => {



         setOption(event.target.id);
         let isChecked = event.target.checked   ;

         if(isChecked && event.target.id==='Day'){
                  setIsChecked1(true)
                  setIsChecked2(false)
                  setIsChecked3(false)
         }
         else if(isChecked && event.target.id==='Week'){
                  setIsChecked1(false)
                  setIsChecked2(true)
                  setIsChecked3(false)
         }
         else if(isChecked && event.target.id==='Month'){
                  setIsChecked1(false)
                  setIsChecked2(false)
                  setIsChecked3(true)
         }   
         else{       
                  setIsChecked1(false)
                  setIsChecked2(false)
                  setIsChecked3(false)
         }





         

 };



 //------------------------------------------------------

useEffect(() => {  


   setObj(

      {
         "reportId": props.ReportId,
         "email": email,
         "scheduleType":  option,
         "scheduleValue": option==='Day' ? selectedTime :option==='Week' ? selectedDay+", "+selectedTime  :option==='Month' ? selectedDayNumber+", "+selectedTime : ''
      }   
   
   )


   }, [email, option, selectedTime, selectedDay, selectedDayNumber])
//------------------------------------------------------
// const obj = 
// {
//       "reportId": props.ReportId,
//       "email": email,
//       "scheduleType":  option,
//       "scheduleValue": option==='Day' ? selectedTime :option==='Week' ? selectedDay+", "+selectedTime  :option==='Month' ? selectedDayNumber+", "+selectedTime : ''
// };    


//------------------------------------------------------
function scheduleReport(obj) {
  
   console.log(obj);
   if(!obj.email) {          toast.error('Email is mandatory', {position: toast.POSITION.TOP_RIGHT  });  return false; }
   if(!obj.scheduleType) {          toast.error('schedule Type is mandatory', {position: toast.POSITION.TOP_RIGHT  });  return false; }
   if(!obj.scheduleValue || obj.scheduleValue.includes('null')) {          toast.error('schedule Value is mandatory', {position: toast.POSITION.TOP_RIGHT  });  return false; }

      axios
      .post( BaseUrl  +  '/Report/CreateUpdateSchedule',obj, {
          headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
          },
      })
      .then((res) => { 
    
         toast.success('Report has been scheduled', {
            position: toast.POSITION.TOP_RIGHT
         });  
         setShow(false)
         setIsChecked1(false)
         setIsChecked2(false)
         setIsChecked3(false)         

      }).catch(()=>{ toast.error('Error', { position: toast.POSITION.TOP_RIGHT }); })
      .finally(() =>{

      });
    
}
//------------------------------------------------------








  return (
    <div>
      
       <Button className="p-0 fs-7" variant="" onClick={handleShow}>
       <i className='edu ic-sched-run'> </i>Schedule
      </Button>




        <Modal centered size='lg' className='sched-modal' show={show} onHide={handleClose} >
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold'>Schedule Sync & Export</Modal.Title>
        </div>


                   


        </Modal.Header>
        <Modal.Body>
 




            <div className=''>
            <Form>
            <Form.Label className="fw-semibold fs-6">Schedule</Form.Label>

            
            <div className='d-flex align-items-center mb-3'>
            <Form.Check  
                type="switch"
                id="Day"
                onChange={handleChangeOption}

                checked={isChecked1}
             />
             <Form.Label className="mx-2 mb-0 fs-7  me-3">Every Day at</Form.Label>
             <div className='sched ms-4'>
                <div className='d-flex align-items-center'>
                <Form.Select aria-label="Default select example" id='Time1' className='fs-7 ' onChange={handleChange}>
                <option>HH:MM</option>
                <option value="00:00">00:00</option>
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                </Form.Select>
                <i className='edu ic-time mx-2'/>
                </div>
             </div>
            </div>

            <div className='d-flex align-items-center mb-3'>
            <div className='d-flex align-items-center w-40'>
            <Form.Check 
                type="switch"
                id="Week" 
                onChange={handleChangeOption}
                checked={isChecked2}
                />
             <Form.Label className="mx-2 fs-7 mb-0">Every Week on</Form.Label>
            </div>
             <div className='d-flex w-70 ms-3 align-items-center'>
            <div className='sched'>
                <div className='d-flex align-items-center'>
                <Form.Select aria-label="Default select example" id='Days' className='fs-7' onChange={handleChange}>
                <option>Select DAY</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Satuarday">Satuarday</option>
                <option value="Sunday">Sunday</option>
                </Form.Select>
                <i className='edu ic-date mx-2'/>
                </div>
             </div>
             <span className='mx-3'>at</span>
             <div className='sched w-75'>
                <div className='d-flex align-items-center'>
                <Form.Select aria-label="Default select example" id='Time2'  onChange={handleChange}>
                <option>HH:MM</option>
                <option value="00:00">00:00</option>
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                </Form.Select>
                <i className='edu ic-time mx-2'/>
                </div>
             </div>
            </div>
            </div>

            <div className='d-flex align-items-center mb-3'>
            <div className='d-flex align-items-center w-40'>
            <Form.Check  
                type="switch"
                id="Month"
                onChange={handleChangeOption}
                checked={isChecked3}
                />
             <Form.Label className="mx-2 fs-7 mb-0">Every Month of</Form.Label>
            </div>
             <div className='d-flex w-70 ms-3 align-items-center'>
            <div className='sched'>
                <div className='d-flex align-items-center'>
                <Form.Select aria-label="Default select example" id='dayNumber' className='fs-7' onChange={handleChange}>
                <option>Select</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
                <option value="21">21</option>
                <option value="22">22</option>
                <option value="23">23</option>
                <option value="24">24</option>
                <option value="25">25</option>
                <option value="26">26</option>
                <option value="27">27</option>
                <option value="28">28</option>
                <option value="29">29</option>
                <option value="30">30</option>
                </Form.Select>
                <i className='edu ic-date mx-2'/>
                </div>
             </div>
             <span className='mx-3'>at</span>
             <div className='sched w-75'>
                <div className='d-flex align-items-center'>
                <Form.Select aria-label="Default select example" id='Time3' onChange={handleChange}>
                <option>HH:MM</option>
                <option value="00:00">00:00</option>
                <option value="01:00">01:00</option>
                <option value="02:00">02:00</option>
                <option value="03:00">03:00</option>
                <option value="04:00">04:00</option>
                <option value="05:00">05:00</option>
                <option value="06:00">06:00</option>
                <option value="07:00">07:00</option>
                <option value="08:00">08:00</option>
                <option value="09:00">09:00</option>
                <option value="10:00">10:00</option>
                <option value="11:00">11:00</option>
                <option value="12:00">12:00</option>
                <option value="13:00">13:00</option>
                <option value="14:00">14:00</option>
                <option value="15:00">15:00</option>
                <option value="16:00">16:00</option>
                <option value="17:00">17:00</option>
                <option value="18:00">18:00</option>
                <option value="19:00">19:00</option>
                <option value="20:00">20:00</option>
                <option value="21:00">21:00</option>
                <option value="22:00">22:00</option>
                <option value="23:00">23:00</option>
                </Form.Select>
                <i className='edu ic-time mx-2'/>
                </div>
             </div>
            </div>
            </div>

            <div className=''>
               <span className='fw-semibold fs-6'>Send Generated Report to:</span>
               <Form.Label className="fw-semibold fs-7 mb-0 mt-3">Email</Form.Label>
              <Form.Control className="field-input" type="email" placeholder="Enter email address" onChange={handleChangeEmail} defaultValue={email} />
            </div>
            </Form>
            </div>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button className='main-button fw-semibold rounded-pill py-2 w-100' variant="" onClick={()=> scheduleReport(obj)}>
            Set
          </Button>
        </Modal.Footer>
        </Modal>
    </div>
  )
}

export default ScheduleRun
