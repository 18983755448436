import React, { useEffect, useState, useRef, useContext } from "react";
import ReportResult from "../../components/table/ReportResult";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddEditUser from "../../components/modals/Users/AddEditUser";
import DeleteUser from "../../components/modals/Users/DeleteUser";
import TableHeader from "../../components/Molecules/TableHeader";
import UsersTable from "../../components/Molecules/Users/UsersTable";
import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";
import TableUsers from "../../components/Molecules/Users/TableUsers";
import { Rings } from "react-loader-spinner";
import { IndexContext } from "../../components/layout/Layout";


const BaseUrl = GetBaseUrl()[0];
const token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
const companyId = localStorage.getItem(GetBaseUrl()[1] + "_CompanyId");

const SavedOutput = () => {
  const IndexProvider = useContext(IndexContext);
  //---------------------------------------------------------Index Style
  let PIS = IndexProvider.indexStyle;
  //---------------------------------------------------------Index Style

  const [selectedUser, setSelectedUser] = useState();
  const [usersPageSize, setUsersPageSize] = useState(10);
  const [usersPage, setUsersPage] = useState(1);
  const [reload, setReload] = useState(null);
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowsCount, setRowsCount] = useState(0);
  //---------------------------------------------------------------------
  const tableHeader = [
    {
      name: "First Name",
      originalName: "firstName",
    },
    {
      name: "Last Name",
      originalName: "lastName",
    },
    {
      name: "Email",
      originalName: "displayEmail",
    },
    {
      name: "User Name",
      originalName: "username",
    },
    {
      name: "Mobile",
      originalName: "mobile",
    },
    {
      name: "Status",
      originalName: "isActive",
    },
    {
      name: "Role",
      originalName: "role",
    },
  ];

  const refetchUsers = (Page = usersPage, PageSize = usersPageSize) => {
    setReload(false);
    setLoading(true);
    if (PageSize === -1) {
      PageSize = rowsCount;
    }
    axios
      .get(
        BaseUrl + `/UserManagement/GetUsers/${Page}/${PageSize}/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        // console.log(res.data);

        if (res.status === 200) {
          setReload(true);
          setUsersList(res.data.usersList);
          setRowsCount(res.data.totalRecords);
        }
      })
      .catch(() => {
        toast.error("Error", { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {});
  };
  //---------------------------------------------------------------------
  useEffect(() => {
    IndexProvider.setPathName("Users");
    refetchUsers(usersPage, usersPageSize);
  }, [usersPage, usersPageSize]);
  //---------------------------------------------------------------------

  useEffect(() => {
    setLoading(false);
  }, [usersList]);

  //---------------------------------------------------------------------

  return (
    <div className="rounded-5 p-35  main-bg min-vh-85 mainPage-container ">
      {/* <TableHeader /> */}
      <div className="top-bg rounded-5 min-vh-85">


        {loading || !usersList.length ? (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <Rings color="#05BC86" height={150} width={150} /> {"Users List Loading ..."}
            ...
          </div>
        ) : usersList.length ? (
          <TableUsers
            tablePageSize={usersPageSize}
            tablePage={usersPage}
            setTablePageSize={setUsersPageSize}
            setTablePage={setUsersPage}
            data={usersList}
            tableHeader={tableHeader}
            TableId={"viewDataFieldsTable"}
            tableRowsDefault="15"
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            rowsCount={rowsCount}
            refetchUsers={refetchUsers}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SavedOutput;
