import {React, useEffect, useState } from "react";
import { Button, ButtonGroup, Collapse, Dropdown } from 'react-bootstrap'
import UpdateTable from '../modals/UpdateTable'
import Rename from '../modals/Rename'
import Delete from '../modals/Delete'
import DataType from './DataType'
import './output.css'
import RelationshipList from '../relationship/RelationshipList'
import OutputXls from './OutputXls'
import OutputList from '../relationship/OutputList'
import GetBaseUrl from '../../functions/SetBaseUrl'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
const BaseUrl = GetBaseUrl()[0]   ;
const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");


const Relation = (props) => {


  
  const [showR, setShowR] = useState(null);
  const [changeR, setChangeR] = useState(null);
  const [tablesList, setTablesList] = useState([]);
  const [relationShipName, setRelationshipName] = useState('');
  const [relationShipJson, setRelationshipJson] = useState({});


useEffect(() => { 

          axios
          .get( BaseUrl  +   '/Relations2/GetRelationshipStructureById/'+props.Id, {
              headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
              },
          })
          .then((res) => {
            
            setRelationshipName(res.data.relationShipName )

             let a=[]
             for (let i = 0; i < res.data.tables.length; i++) {
                  const element = res.data.tables[i];
                  const element1 = element.reference;

                  for (let k = 0; k < element.tableColumns.length; k++) {
                    element.tableColumns[k].visible=true
                  }

                    a.push(
                        {
                          "tableId": element.tableId,
                          "tableName": element.tableName,
                          "columns": element.tableColumns,
                        }                
                    )

                    for (let i1 = 0; i1 < element1.length; i1++) {

                          const arr= element1[i1].target 
                          for (let j = 0; j < arr.length; j++) {
                            
                                if(arr[j].tableId && arr[j].tableName){

                                    for (let k = 0; k < arr[j].tableColumns.length; k++) {
                                      arr[j].tableColumns[k].visible=true
                                    }                                  
                                    a.push(
                                          {
                                            "tableId": arr[j].tableId,
                                            "tableName": arr[j].tableName,
                                            "columns": arr[j].tableColumns,
                                          }                
                                    )
                                  }
                          }

                    }

             }
             setTablesList(a)



          }).catch(() =>{

          }).finally(() => {

            

            setRelationshipJson(
              {
                "RelationshipId": props.Id,
                "Tables": tablesList  
              }
            )

            if(changeR){
              // props.setTitle("EduLytics_"+props.Title)
            //  props.setTitle(props.newName)
              setShowR(Math.random(300))
            }


          }) ;



}, [changeR])
//----------------------------------------------------
useEffect(() => { 

  if(showR){

    props.setViewReport(Math.random(500))
    props.setObj(relationShipJson)
   // props.setChangeColumns(Math.random(500))

  }




}, [showR])







  const [open, setOpen] = useState(false);
  return (
    <div>

<div>
    <div onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
        variant=''
        className='w-100'>
       <div className="" onClick={() => setChangeR(Math.random(100))}>

          <OutputList
           RelationshipName={relationShipName}
           
          
          />
           
       </div>
      </div>
      <Collapse in={open} className='collapse-content'>
      <div className='ms-3 border-start'>
            {tablesList && tablesList.map((item, index) =>
            <OutputXls
            key={index}
            setRelationshipJson={setRelationshipJson}
            relationShipJson={relationShipJson}
            setShowR={setShowR}
            tableId={item.tableId}
            tableName={item.tableName}  
            setTitle={props.setTitle} 
            setChangeColumns={props.setChangeColumns}   

            />
            )}
      </div>
      </Collapse>
</div>



    </div>
  )
}

export default Relation
