import React, { useState } from "react";

import TablesLink from "../modals/TablesLink";
import AllTablesLinks from "../modals/AllTablesLinks";
import Button from "react-bootstrap/Button";

const Actions = (props) => {
  return (
    <div>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <div className="w-100">
            <input
              className="form-control border rounded-pill fs-6 fw-medium w-85"
              defaultValue={props.newName}
              type="text"
              placeholder="Add New Relationship Name"
              aria-label="default input example"
              onChange={props.handleChange}
            />
          </div>
          <div className="d-flex w-70 align-items-center justify-content-end">
            <button
              className="btn main-button w-20 p-2 rounded-pill ms-1 fs-7 fw-semibold"
              onClick={() => props.saveNewRelationship(props.newName)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Actions;
