import React from 'react'
import { ButtonGroup, Dropdown } from 'react-bootstrap'
import Rename from '../modals/Rename'
import Delete from '../modals/Delete'
import ScheduleRun from '../modals/ScheduleRun'
import Undo from '../modals/Undo'

const Query = () => {
  return (
    <div>
      <div className='d-flex justify-content-between align-content-center my-2 query'>
           <div className='d-flex align-items-center w-100'>
                <i className='edu ic-output-tag fs-7'></i> 
                <span className='fs-7 text-truncate mx-2'>Customer Details and Status</span>
            </div>
            <div className='d-flex justify-between-end align-items-center'>
            <Dropdown>
             <Dropdown.Toggle variant='' id="dropdown-basic"  className='edu ic-more p-0'/>
      
               <Dropdown.Menu className='fs-7 query-more'>
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3 w-100'><i className='edu ic-run'> </i>Run Result</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3 w-100'><i className='edu ic-edit-query'> </i>Edit Query</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-1" className='p-1 ps-3 w-100'><i className='edu ic-start'/> Start</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3 w-100'><ScheduleRun/></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3 w-100'><Undo/></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3 w-100'><i className='edu ic-lock'> </i>Lock</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-2" className='p-1 ps-3 w-100'><i className='edu ic-share'> </i>Share Query</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-3" className='p-1 ps-3 w-100'><Rename/></Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item href="#/action-4" className=' p-1 ps-3 w-100'><Delete/></Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            </div>
           </div>
    </div>
  )
}

export default Query
