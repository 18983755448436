import { React, useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import back from "../../assets/svg/back.svg";
import upload from "../../assets/svg/upload_mapping.svg";
import plus from "../../assets/svg/add_condition.svg";
import add from "../../assets/svg/add-value.svg";
import reset from "../../assets/svg/retry-white.svg";
import Delete from "./DeleteReport";
import AddValue from "./AddValue";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import GetBaseUrl from "../../functions/SetBaseUrl";
import * as XLSX from "xlsx";

function SingleCondition(props) {
  const [mappingValues, setMappingValues] = useState(props.mapping);
  const [data, setData] = useState([]);
  const [selectedSingleValue, setSelectedSingleValue] = useState("");
  const [selectedModalSingleValue, setSelectedModalSingleValue] = useState("");
  const [selectedColumn, setSelectedColumn] = useState(
    props.conditions[props.index].columnId
  );
  const [selectedOperator, setSelectedOperator] = useState(
    props.conditions[props.index].operator
  );
  const [selectedValue, setSelectedValue] = useState(
    props.conditions[props.index].columnValue
  );
  const [selectedActions, setSelectedActions] = useState(
    props.conditions[props.index].columnAction
  );
  const [selectedRequired, setSelectedRequired] = useState(
    props.conditions[props.index].isRequired
  );
  let obj = props.conditions[props.index];

  const handleAddSingleValueChange = (e) => {
    setSelectedSingleValue(e.target.value);
  };
  const handleAddModalSingleValueChange = (e) => {
    setSelectedModalSingleValue(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (
      e.key === "Enter" &&
      selectedSingleValue &&
      [...mappingValues].indexOf(selectedSingleValue) === -1
    ) {
      setMappingValues([...mappingValues, selectedSingleValue]);
      setSelectedSingleValue("");
    }
  };

  //---------------------------------
  function resetConditionValues() {
    setMappingValues([]);
    setSelectedColumn("");
    setSelectedOperator("");
    setSelectedValue("");
    setSelectedActions("");
    setSelectedRequired(false);
    props.setConditionValues([
      props.index,
      selectedColumn,
      selectedOperator,
      selectedValue,
      selectedActions,
      selectedRequired,
    ]);
  }
  //---------------------------------
  function removeSingleCondition() {
    let arr = [...props.conditions];
    arr.splice(props.index, 1);
    props.setConditions(arr);
  }

  //---------------------------------

  const handleColumnChange = (e) => {
    setSelectedColumn(e.target.value);
    obj.columnId = e.target.value;
    props.setConditionValues([
      props.index,
      e.target.value,
      selectedOperator,
      selectedValue,
      selectedActions,
      selectedRequired,
    ]);
  };
  const handleOperatorChange = (e) => {
    setSelectedOperator(e.target.value);
    obj.operator = e.target.value;
    props.setConditionValues([
      props.index,
      selectedColumn,
      e.target.value,
      selectedValue,
      selectedActions,
      selectedRequired,
    ]);
  };
  const handleValueChange = (e) => {
    setSelectedValue(e.target.value);
    obj.columnValue = e.target.value;
    props.setConditionValues([
      props.index,
      selectedColumn,
      selectedOperator,
      e.target.value,
      selectedActions,
      selectedRequired,
    ]);
  };
  const handleActionChange = (e) => {
    setSelectedActions(e.target.value);
    obj.columnAction = e.target.value;
    props.setConditionValues([
      props.index,
      selectedColumn,
      selectedOperator,
      selectedValue,
      e.target.value,
      selectedRequired,
    ]);
  };
  const handleRequiredChange = (e) => {
    setSelectedRequired(e.target.value);
    obj.isRequired = e.target.value;

    let boolValue = /true/.test(e.target.value);

    props.setConditionValues([
      props.index,
      selectedColumn,
      selectedOperator,
      selectedValue,
      selectedActions,
      boolValue,
    ]);
  };

  const handleFileUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      /* Convert array to json*/
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
      setData(dataParse);
    };
    reader.readAsBinaryString(f);
  };

  function uniq(a) {
    return a.sort().filter(function (item, pos, ary) {
      return !pos || item != ary[pos - 1];
    });
  }
  //---------------------------------------------------
  useEffect(() => {
    setMappingValues(props.mapping);
  }, [props.reset]);
  //---------------------------------------------------

  //---------------------------------------------------
  useEffect(() => {
    // console.log(mappingValues);
    if (data && data.length) {
      let map = [...mappingValues];
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        map.push(element[0]);
      }

      setMappingValues(uniq(map));
      document.getElementsByClassName("MappingFile")[props.index].value = "";
    }
  }, [data]);
  //---------------------------------------------------
  useEffect(() => {
    if (obj && mappingValues && mappingValues.length) {
      obj.mapping = mappingValues;
      let array = [...props.conditions];
      for (let i = 0; i < array.length; i++) {
        // const element = array[i];
        if (i === props.index) {
          array[i] = obj;
          break;
        }
      }
      props.setConditions(array);
    }
  }, [mappingValues]);

  return (
    <div className={props.index ? "py-3 border-top" : "my-3"}>
      <div className="d-flex align-items-center">
        <span className="fw-semibold mt-2 me-2 w-5">
          {props.index ? "Else IF" : "IF"}{" "}
        </span>
        <div className="w-20 mx-2 ">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">
              Column Name
            </Form.Label>
            <Form.Select
              onChange={handleColumnChange}
              className="field-input"
              aria-label="Default select example"
              value={selectedColumn}
            >
              <option>Select</option>
              {props.TableColumns &&
                props.TableColumns.map((item) => (
                  <option key={item.columnId} value={item.columnId}>
                    {item.columnName}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </div>
        <div className="w-20 mx-2 ">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">Operator</Form.Label>
            <Form.Select
              className="field-input"
              aria-label="Default select example"
              onChange={handleOperatorChange}
              value={selectedOperator}
            >
              <option>Select</option>
              <option value={"="}>Equal</option>
              <option value={"!="}>Not Equal</option>
            </Form.Select>
          </Form.Group>
        </div>
        <div className="w-25 mx-2 ">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">Value</Form.Label>
            <Form.Control
              className="field-input"
              type="text"
              placeholder="Enter Value"
              onChange={handleValueChange}
              value={selectedValue}
            />
          </Form.Group>
        </div>
        <span className="fw-semibold mt-2 mx-2 fs-7">Then</span>
        <div className="w-25 mx-2 ">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">Actions</Form.Label>
            <Form.Select
              className="field-input"
              aria-label="Default select example"
              onChange={handleActionChange}
              value={selectedActions}
            >
              <option value={""}>Select</option>
              <option>Mapping</option>
            </Form.Select>
          </Form.Group>
        </div>
        <div className="w-5">
          <Dropdown className="">
            <Dropdown.Toggle variant="" id="dropdown-basic" className="mt-3">
              <i className="edu ic-more ifmore fs-5" />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className="p-1 ps-3 fw-medium"
                onClick={() => resetConditionValues()}
              >
                <i className="edu ic-refresh me-2" /> Reset Fields
              </Dropdown.Item>
              {props.index ? (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className="p-1 ps-3 fw-medium me-2"
                    onClick={() => removeSingleCondition()}
                  >
                    <i className="edu ic-delete me-2" /> Delete Condition
                  </Dropdown.Item>
                </>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div></div>
      </div>
      <div className="d-flex align-items-center mb-3">
        <div className="w-32">
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label className="fw-semibold fs-7 mb-0">Required</Form.Label>
            <Form.Select
              className="field-input"
              aria-label="Default select example"
              onChange={handleRequiredChange}
              value={selectedRequired}
            >
              <option value={true} selected>
                Yes
              </option>
              <option value={false}>No</option>
            </Form.Select>
          </Form.Group>
        </div>

        {selectedActions === "Mapping" ? (
          <>
            <div className="w-20 mx-2 d-flex ">
              {" "}
              <Button
                variant=""
                className=" d-flex align-items-center mt-2 px-0"
                onClick={() =>
                  document
                    .getElementsByClassName("MappingFile")
                    [props.index].click()
                }
              >
                <img src={upload} />
                <span className="ps-2 fw-semibold fs-7 text-start">
                  Upload file with accepted values
                </span>
                <Form.Control
                  className="field-input MappingFile hide"
                  type="file"
                  placeholder="Enter Values"
                  onChange={handleFileUpload}
                  accept=".xlsx, .xls"
                />
              </Button>
            </div>
            <div className="w-25 mx-2 ">
              <div className="">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label className="fw-semibold mb-0 fs-7">
                    Add value manually
                  </Form.Label>
                  <div className="inputWithButton">
                    <Form.Control
                      className="field-input"
                      type="text"
                      placeholder="Enter Values"
                      onChange={handleAddSingleValueChange}
                      onKeyDown={handleKeyDown}
                      value={selectedSingleValue}
                    />
                    <AddValue
                      mappingValues={mappingValues}
                      handleAddModalSingleValueChange={
                        handleAddModalSingleValueChange
                      }
                      selectedModalSingleValue={selectedModalSingleValue}
                      setSelectedModalSingleValue={setSelectedModalSingleValue}
                      setMappingValues={setMappingValues}
                    />
                  </div>
                </Form.Group>
              </div>
            </div>
          </>
        ) : null}

        <div className="w-30 mx-4 "></div>

        <div></div>
      </div>
    </div>
  );
}

export default SingleCondition;
