import React, { useState } from 'react'
import { Button, ButtonGroup, Collapse, Dropdown } from 'react-bootstrap'
import GroupTab from './GroupTab'

function OutputGroups() {
    const [open, setOpen] = useState(false);
  return (
    <div>
    <Button onClick={() => setOpen(!open)}
     aria-controls="example-collapse-text"
     aria-expanded={open}
     variant=''
     className='w-100 p-0'>
      <div className='d-flex justify-content-between align-items-center my-3'>
             <div className='d-flex align-items-center'>
                 <button className='btn p-0'><i className='edu ic-down fs-10 mx-2'></i></button>
                 <span className='fs-7 third'>GROUPS</span>
             </div>
         </div> 
   </Button>
   <Collapse in={!open}>
   <div className=''>
   <GroupTab/>
   <GroupTab/>
   <GroupTab/>
   </div>
   </Collapse>
 </div>
  )
}

export default OutputGroups
