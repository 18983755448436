import {React, useEffect, useState } from "react";
import TableList from '../components/UploadedTable/TableList'
import GroupList from '../components/UploadedTable/GroupList'
import Tableheader from '../components/table/Tableheader'
import TableResult from '../components/table/TableResult'
import Pagination from '../components/pagination/Pagination'
import GetBaseUrl from '../functions/SetBaseUrl'
import InnerHeader from '../components/innerheader/InnerHeader'
import TableFields from '../components/table/TableFields'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";


const BaseUrl = GetBaseUrl()[0]   ;

const DataFields = () => {

  const [groupsList, setGroupsList] = useState([]);
  const [tablesList, setTablesList] = useState([]);
  const [viewDataFieldsTable, setViewDataFieldsTable] = useState();
  const [viewTable, setViewTable] = useState();



//----------------------------------------------  
function runValidation(TableId) {
  
  const BaseUrl = GetBaseUrl()[0]   ;
  var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");
  axios
  .get( BaseUrl  +  '/Data/ValidateDataByTable/'+ TableId, {
      headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      },
  })
  .then((res) => {

    toast.success('Validation Done', {
      position: toast.POSITION.TOP_RIGHT
    });    

  });


}
//---------------------------------------------- 




  useEffect(() => { 
    const Url =BaseUrl  +   '/Data/GetGroups';
    const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");
    fetch(Url ,{
      headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      },
    })
    .then(response => response.json())
    .then(json => setGroupsList(json )) 


    const Url1 =BaseUrl  +   '/Data/GetTables';
    fetch(Url1 ,{
      headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
      },
    })
    .then(response => response.json())
    .then(json => setTablesList(json ))     



    

  }, [])
  const renderItem = (item, index) =>    <div className='fw-semibold' key={index}>{item}</div>   


  return (
    <div>
    <div className = "rounded-5 main-bg p-35 min-vh-85">
   <div className='row'>
   <div className = "col-lg-4 col-xl-3 px-0 pe-3">
    <div className = "top-bg p-3 rounded-5 min-vh-85">

    <TableList 
    renderItem={renderItem}
    tablesList={tablesList}
    setViewDataFieldsTable={setViewDataFieldsTable}
    IsViewTable={false}
    runValidation = {runValidation}
    />

    <GroupList 
    renderItem={renderItem}
    GroupsList={groupsList}
    setViewDataFieldsTable={setViewDataFieldsTable}
    IsViewTable={false}
    runValidation = {runValidation}
    />


    </div>
    </div>
    <div className = "col-lg-8 col-xl-9 px-0 ">
    <div className = "top-bg rounded-5 min-vh-85">

        <TableFields
         TableId={viewDataFieldsTable}
         setViewDataFieldsTable={setViewDataFieldsTable}
         tableRowsDefault='15'
         />
    </div>
    </div>
   </div>
    </div>

    </div>
  )
}

export default DataFields
