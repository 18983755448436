import React, { useEffect, useState, useContext } from "react";
import { useTable } from "react-table";
import styles from "./Table.module.scss";
import StatisticIcon_1 from "../../../assets/svg/Dashboard/TopTableIcon1.svg";
import StatisticIcon_2 from "../../../assets/svg/Dashboard/TopTableIcon2.svg";
import StatisticIcon_3 from "../../../assets/svg/Dashboard/TopTableIcon3.svg";
import StatisticIcon_4 from "../../../assets/svg/Dashboard/TopTableIcon4.svg";
import StatisticIcon_5 from "../../../assets/svg/Dashboard/TopTableIcon5.svg";
import PageArrowLeft from "../../../assets/svg/Dashboard/PageArrowLeft.svg";
import PageArrowRight from "../../../assets/svg/Dashboard/PageArrowRight.svg";
import EditQuery from "../../../assets/images/EditQuery.svg";
import QueryModal from "../../DashboardElements/QueryModal";

import GetBaseUrl from "../../../functions/SetBaseUrl";
import { GetAPISavedGraphQuery } from "../../../functions/GetAPIList";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const BaseUrl = GetBaseUrl()[0];
var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

export const TableComponent = ({
  // data,
  tableTitle,
  queryStructures,
  withQuery,
  widgetType,
  index,
  viewQueryResult,
  queryResult,
}) => {
  const [defaultQueryStructure, setDefaultQueryStructure] = useState(
    queryStructures[0]
  );
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [query, setQuery] = useState();
  const [tabIndex, setTabIndex] = useState(index[0]);
  //---------------------------------------------------
  const getValueByIndex = (array, i) => {
    const item = array.find((element) => element.queryId === i);
    return item ? item.content : "no";
  };
  //---------------------------------------------------
  const [data, setData] = useState([]);
  const [header, setHeader] = useState([]);
  const [statistics, setStatistics] = useState([
    100000, 100000, 100000, 20000, 5.6,
  ]);

  const columns = [
    {
      Header: "Date",
      accessor: "Date",
      isIcon: false,
    },
    {
      Header: "Visit Type",
      accessor: "Visit_Type",
      isIcon: false,
    },
    {
      Header: "Trainer Name",
      accessor: "Trainer_Name",
      isIcon: false,
    },
    {
      Header: "Attendees",
      accessor: "Attendees",
      isIcon: false,
    },
    {
      Header: "User Type",
      accessor: "User_Type",
      isIcon: true,
    },
    {
      Header: "Rate",
      accessor: "Rate",
      isIcon: true,
    },
  ];

  //----------------------------------------------------
  const handleSave = () => {
    let Query = {
      queryId: tabIndex,
      queryType: "report",
      widgetType: widgetType,
      queryName: query.queryName,
      queryText: query.query,
      reportId: query.reportId,
      tableId: "00000000-0000-0000-0000-000000000000",
      columnId: "00000000-0000-0000-0000-000000000000",
    };
    // console.log(Query);
    if (query.queryName && query.query && query.reportId) {
      axios
        .post(BaseUrl + "/Report/InsertReportQuery", Query, {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Query has been added", {
            position: toast.POSITION.TOP_RIGHT,
          });
          viewQueryResult(tabIndex);
        })
        .catch((res) => {
          toast.error(res + "", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
      return true;
    } else {
      toast.warning("Enter required values", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  //---------------------------------------------------
  useEffect(() => {
    // console.log(queryResult);

    for (let i = 0; i < queryResult.length; i++) {
      const qr = queryResult[i];
      if (qr && qr.queryId === tabIndex && qr.content) {
        let result = qr.content;

        let arrayOfHeader = [];
        result.header &&
          result.header.map((item) => {
            arrayOfHeader.push({
              Header: item,
              accessor: item,
              isIcon: false,
            });
          });
        setStatistics(result.stats);
        setHeader(arrayOfHeader);
        setData(result.rows);
        break;
      }
    }
  }, [queryResult]);

  //---------------------------------------------------

  useEffect(() => {
    const SavedGraphQuery = async () => {
      try {
        const data = await GetAPISavedGraphQuery();
        for (let i = 0; i < data.length; i++) {
          const element = data[i];

          if (element.queryId === tabIndex) {
            setQuery({
              queryName: element.queryName,
              reportId: element.reportId,
              query: element.queryText,
            });
            break;
          }
        }
      } catch (error) {
        console.error("Error By Id1 :", error);
      }
    };
    SavedGraphQuery();
  }, []);
  //----------------------------------------------------

  const formatNumber = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return num;
    }
  };
  //----------------------------------------------
  return (
    <div className={`${styles.container}`}>
      {/* {statistics ? ( */}
      <div className={`${styles.containerTop} row`}>
        <div className={`${styles.c1} row`}>
          <img src={StatisticIcon_1} />
          <div>
            <label>Total Visits</label>
            <h2>
              {statistics && statistics[0] && formatNumber(statistics[0].count)}
            </h2>
          </div>
        </div>
        <div className={`${styles.c1} row`}>
          <img src={StatisticIcon_2} />
          <div>
            <label>Total Attendees</label>
            <h2>
              {statistics && statistics[1] && formatNumber(statistics[1].count)}
            </h2>
          </div>
        </div>
        <div className={`${styles.c1} row`}>
          <img src={StatisticIcon_3} />
          <div>
            <label>Total Calls</label>
            <h2>
              {statistics && statistics[2] && formatNumber(statistics[2].count)}
            </h2>
          </div>
        </div>
        <div className={`${styles.c1} row`}>
          <img src={StatisticIcon_4} />
          <div>
            <label>Total Emails</label>
            <h2>
              {statistics && statistics[3] && formatNumber(statistics[3].count)}
            </h2>
          </div>
        </div>
        <div className={`${styles.c1} row`}>
          <img src={StatisticIcon_5} />
          <div>
            <label>Avg Rating</label>
            <h2>
              {statistics && statistics[4] && formatNumber(statistics[4].count)}
            </h2>
          </div>
        </div>
      </div>
      {/* ------------------cardsContainerTop------------------ */}
      <div className={`${styles.cardsContainerTop} row`}>
        <div className={`${styles.containerCardTop} `}>
          <div className={`${styles.c1} row`}>
            <img src={StatisticIcon_1} />
            <div>
              <label>Total Visits</label>
              <h2>
                {statistics &&
                  statistics[0] &&
                  formatNumber(statistics[0].count)}
              </h2>
            </div>
          </div>
        </div>
        <div className={`${styles.containerCardTop}`}>
          <div className={`${styles.c1} row`}>
            <img src={StatisticIcon_2} />
            <div>
              <label>Total Attendees</label>
              <h2>
                {statistics &&
                  statistics[1] &&
                  formatNumber(statistics[1].count)}
              </h2>
            </div>
          </div>
        </div>
        <div className={`${styles.containerCardTop}`}>
          <div className={`${styles.c1} row`}>
            <img src={StatisticIcon_3} />
            <div>
              <label>Total Calls</label>
              <h2>
                {statistics &&
                  statistics[2] &&
                  formatNumber(statistics[2].count)}
              </h2>
            </div>
          </div>
        </div>
        <div className={`${styles.containerCardTop}`}>
          <div className={`${styles.c1} row`}>
            <img src={StatisticIcon_4} />
            <div>
              <label>Total Emails</label>
              <h2>
                {statistics &&
                  statistics[3] &&
                  formatNumber(statistics[3].count)}
              </h2>
            </div>
          </div>
        </div>
        <div className={`${styles.containerCardTop}`}>
          <div className={`${styles.c1} row`}>
            <img src={StatisticIcon_5} />
            <div>
              <label>Avg Rating</label>
              <h2>
                {statistics &&
                  statistics[4] &&
                  formatNumber(statistics[4].count)}
              </h2>
            </div>
          </div>
        </div>
      </div>
      {/* ------------------cardsContainerTop------------------ */}

      {/* ) : null} */}
      <div className={`${styles.containerBottom}`}>
        <div className={`row ${styles.tableHead}`}>
          <h3 className="">{tableTitle}</h3>
          {withQuery && (
            <img
              src={EditQuery}
              onClick={() => setAskQueryModal(true)}
              className={`${styles["EditQuery"]}`}
              alt="EditQuery"
            />
          )}
          {/* <div className={`${styles.pagesControl} `}>
            <img src={PageArrowLeft} alt="PageArrowLeft" />
            &nbsp;
            <img src={PageArrowRight} alt="PageArrowRight" />
          </div> */}
        </div>

        <table>
          <thead>
            <tr>
              {header.map((column) => (
                <th key={column.accessor}>{column.Header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row, index) => {
                return (
                  <tr key={index}>
                    {header.map((cell, i) => (
                      <td key={i}>
                        {!cell.isIcon ? (
                          row[cell.accessor]
                        ) : (
                          <img src={row[cell.accessor]} alt="rate" />
                        )}
                      </td>
                    ))}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      {askQueryModal ? (
        <QueryModal
          setAskQueryModal={setAskQueryModal}
          query={query}
          setQuery={setQuery}
          handleSave={handleSave}
          defaultQueryStructure={defaultQueryStructure}
        />
      ) : null}
    </div>
  );
};
