import React, { useState, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import TableXls from '../tablelist/TableXls'
import TableBb from '../tablelist/TableBb';
import GetBaseUrl from '../../functions/SetBaseUrl'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import TableList from '../UploadedTable/TableList'

const BaseUrl = GetBaseUrl()[0]   ;


function CreateGroup(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
    
    const [TablesArray, setTablesArray] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [tablesList, setTablesList] = useState([]);
    const [tablesToAssign, setTablesToAssign] = useState([]);
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");

    useEffect(() => { 

      const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");
      const Url1 =BaseUrl  +   '/Data/GetTables';
      fetch(Url1 ,{
        headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        },
      })
      .then(response => response.json())
      .then(json => setTablesList(json ))     
  
 
    }, [])
    const renderItem = (item, index) =>    <div className='fw-semibold' key={index}>{item}</div>   
  


    const [groupName, setGroupName] = useState('');
    const handleChange = event => {
      setGroupName(event.target.value);
    };



    function TableIds(checked,value) { 

    }

    

    useEffect(() => { 


    }, [TablesArray])


    const handleCreate = async () => {

      const ToPost = {"groupName": groupName, "tableIds": TablesArray}; 
      if(!groupName){  toast.warning('Fill Required Fields') ; return false; }

     // console.log(ToPost);
      const response = await axios
          .post(BaseUrl+"/Data/CreateGroup", ToPost, {   
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error));
      if ((response && response.data) || response.status===200 ) {

        toast.success('New Group has been created', {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
        props.setReloadLists(true);

      }
  };



  return (
    <div>
    <Button className="p-0 fs-8 main" variant="" onClick={handleShow}>
        Create Group
      </Button>

        <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold'>Create Group</Modal.Title>
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className=" d-flex justify-content-between align-items-center mb-2">
            <Form.Group className="w-100" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7">Group Name</Form.Label>
              <Form.Control className="inp-input p-2 fs-7 fw-medium" type="text" placeholder="Enter Group Name" defaultValue='' onChange={handleChange} />
            </Form.Group>
         </div>
        <div className=''>




        <TableList 
          renderItem={renderItem}
          tablesList={tablesList}
          WithCheckbox={true}
          TableIds={(checked,value) => TableIds(checked,value)}
          setTablesArray={setTablesArray}
          TablesArray={TablesArray}
        />





        </div>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleCreate}>
            Save Group
          </Button>
        </Modal.Footer>
        </Modal>
    
    </div>
  )
}

export default CreateGroup
