import React, { useEffect, useState, useContext, Component } from "react";
import QueryError from "../../../components/modals/QueryError";

import ReactApexChart from "react-apexcharts";
import styles from "./barGraph.module.scss";
import { display, padding } from "@mui/system";
import { Background } from "reactflow";
import EditQuery from "../../../assets/images/EditQuery.svg";

const ApexChartComponent = (props) => {
  const [chartOptions, setChartOptions] = useState({
    series: props.series,
    options: {
      chart: {
        height: props.height,
        stacked: props.stacked,
        type: props.Gtype,
        toolbar: {
          show: props.showtoolbar, // Disable the export and download options
        },
        zoom: {
          enabled: false,
        },
      },
      colors: props.colors, // Custom colors for each series

      dataLabels: {
        enabled: props.Gtype === "area" ? false : true,
        formatter: (val) => `${val} `,
        offsetY: 0,
        style: {
          fontSize: "12px",
          colors: ["#FFF"],
        },
      },
      stroke: {
        curve: "smooth",
      },
      title: {
        text: `${props.title}  `,
        align: "left",
        style: {
          fontSize: "24px",
          fontWeight: "bold",
          color: "#263238",
          fontFamily: "Arial, sans-serif",
        },
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"], //  takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: props.categories,
      },
      yaxis: {
        max: props.max,
        labels: {
          formatter: (val) => `${val} `,
        },
        title: {
          // text: "Percentage",
        },
      },
    },
  });

  //-----------------------------------------------------------

  useEffect(() => {
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: props.series,

      options: {
        ...prevOptions.options,
        xaxis: {
          ...prevOptions.options,
          categories: props.categories,
        },
      },
    }));
  }, [props.categories, props.series]);
  //-----------------------------------------------------------
  useEffect(() => {
    let multi = 10;
    if (props.max.toString().length === 4) {
      multi = 1000;
    }
    if (props.max.toString().length === 3) {
      multi = 100;
    }
    if (props.max.toString().length === 2) {
      multi = 10;
    }
    let yMax = Math.ceil(props.max / multi) * multi;
    setChartOptions((prevOptions) => ({
      ...prevOptions,
      series: props.series,

      options: {
        ...prevOptions.options,
        yaxis: {
          ...prevOptions.options,
          max: yMax,
        },
      },
    }));
  }, [props.max]);
  //-----------------------------------------------------------

  return (
    <div className={`${styles.graphContainer}`}>
      {props.tabs && (
        <div className={`${styles.tabsContainer} row`}>
          {props.tabs.map((tab, i) => {
            return (
              <div
                key={tab.title}
                className={`${styles.tab} ${tab.active ? styles.active : ""} `}
                style={{ width: tab.width }}
                onClick={() => props.setActiveTab(props.tabs, tab.title)}
              >
                <span className={`${tab.active ? styles.active : ""}`}>
                  {tab.title}
                  {tab.hasError && (
                    <QueryError
                      queryStructures={props.queryStructures[i]}
                      size="md"
                    />
                  )}
                </span>
                {props.withQuery && (
                  <img
                    src={EditQuery}
                    onClick={() => props.setAskQueryModal(true)}
                    className={`${styles["EditQuery"]}`}
                    alt="EditQuery"
                  />
                )}
              </div>
            );
          })}
        </div>
      )}

      <div className={`${styles.container} ${props.tabs ? styles.tabbed : ""}`}>
        {props.withQuery && !props.tabs && (
          <>
            <img
              src={EditQuery}
              onClick={() => props.setAskQueryModal(true)}
              className={`${styles["EditQuery"]}`}
              alt="EditQuery"
            />
          </>
        )}
        <ReactApexChart
          options={chartOptions.options}
          series={chartOptions.series}
          type={props.Gtype}
          height={props.height}
        />
      </div>
    </div>
  );
};

export default ApexChartComponent;
