import { React, useEffect, useState, useRef } from "react";
import PublicTable from "../../components/API_Table/main";
import GetBaseUrl from "../../functions/SetBaseUrl";
import { useLottie } from "lottie-react";
import loading_lottie from "../../assets/lottie/loading.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import axios from "axios";

const TableResult = ({
  onlyView,
  TableId,
  tableRowsDefault,
  setViewDataFieldsTable,
  setViewTable,
  OnlyErrorRows,
  HeaderType,
  DownloadExcel,
  tableRef,
  Syncing,
  exportTable,
  toShowColumnArray,
  setToShowColumnArray,
}) => {
  const BaseUrl = GetBaseUrl()[0];
  const [tableHead, setTableHead] = useState([""]);
  const [bodyCol, setBodyCol] = useState([]);
  const [tableUrl, setTAbleUrl] = useState("");
  const [tableName, setTableName] = useState("");
  const [LastUpdate, setLastUpdate] = useState("");
  const [IsScheduled, setIsScheduled] = useState("");
  const [tableOriginalHead, setTableOriginalHead] = useState([""]);

  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  const [tableHeadIds, setTableHeadIds] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [sortBy, setSortBy] = useState(0);
  const [sortType, setSortType] = useState("ASC");
  const [reloadTable, setReloadTable] = useState(0);
  //---------------------------------------------------------

  const [pageIndex, setPageIndex] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [allRecords, setAllRecords] = useState(10000000); // For fetch all records by pagination
  //---------------------------------------------------------

  const options = {
    animationData: loading_lottie,
    loop: true,
  };

  const { View } = useLottie(options);

  useEffect(() => {
    let tableHead = [];
    let tablebodyCol = [];
    let tableOriginalHead = [];
    let tableHeadIds = [];
    let order = [];

    if (TableId) {
      let queryParam = ``;
      if (searchText && sortBy) {
        queryParam = `?SearchQuery=${searchText}&SelectedColumn=${sortBy}&SortDirection=${sortType}`;
      } else if (searchText && !sortBy) {
        queryParam = `?SearchQuery=${searchText}`;
      } else if (!searchText && sortBy) {
        queryParam = `?SelectedColumn=${sortBy}&SortDirection=${sortType}`;
      }

      setTAbleUrl(
        `${BaseUrl}/Report/GetReportById/${TableId}/${pageIndex}/${
          pageSize !== -1 ? pageSize : allRecords
        }${queryParam}`
      );

      axios
        .get(
          `${BaseUrl}/Report/GetReportById/${TableId}/${pageIndex}/${
            pageSize !== -1 ? pageSize : allRecords
          }${queryParam}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "content-type": "application/json",
            },
          }
        )
        .then((res) => {
          setTotalRows(res.data.totalCount);
          setTableName(res.data.reportName);
          setLastUpdate(res.data.lastUpdated);
          setIsScheduled(res.data.IsScheduled);

          res.data.columns.map((item) => {
            tableHeadIds.push(item["columnId"]);
            tableHead.push({
              tableId: item["tableId"],
              columnName: item["columnName"],
              columnId: item["columnId"],
            });

            tablebodyCol.push({
              tableId: item["tableId"],
              columnName: item["originalName"],
              columnId: item["columnId"],
            });

            tableOriginalHead.push({
              tableName: item["tableName"],
              columnName: item["originalName"],
            });

            order.push({
              id: item["columnId"],
              label: item["columnName"],
            });
          });
        })
        .catch(() => {
          toast.error("Relationship of this report is not exist", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .finally(() => {
          setTableHead(tableHead);
          setBodyCol(tablebodyCol);
          setTableOriginalHead(tableOriginalHead);

          setTableHeadIds(tableHeadIds);
        });
    }
    // }, [TableId, pageIndex, pageSize]);
  }, [reloadTable, pageIndex, pageSize, sortBy, sortType, searchText]);
  //----------------------------------------

  useEffect(() => {
    setPageIndex(1);
    setPageSize(10);
    setSortBy("");
    setReloadTable(Math.random(500));
  }, [TableId]);
  //----------------------------------------
  useEffect(() => {
    setToShowColumnArray(tableHeadIds);
  }, [tableHeadIds]);
  //----------------------------------------

  function ExportReport(TableId) {
    axios
      .get(BaseUrl + "/Report/GetFullReportByReportId/" + TableId, {
        headers: {
          Authorization: `Bearer ${token}`,
          "content-type": "application/json",
        },
      })
      .then((res) => {
        window.location.href = res.data;
      })
      .catch((error) => {
        toast.error("Error: " + error, { position: toast.POSITION.TOP_RIGHT });
      })
      .finally(() => {});
  }

  return (
    <>
      {tableUrl ? (
        <PublicTable
          loading={View}
          TableTitle={tableName}
          TableId={TableId}
          TableUrl={tableUrl}
          onlyColumns={toShowColumnArray}
          TableHead={tableHead}
          BodyCol={bodyCol}
          lastColumn={false}
          withOutBorder={false}
          defaultRows={tableRowsDefault ? tableRowsDefault : "10"}
          withHeader={HeaderType}
          firstColumn={false}
          Internal={true}
          ExportReport={ExportReport}
          ReportTable
          IsScheduled={IsScheduled}
          setViewDataFieldsTable={setViewDataFieldsTable}
          setViewTable={setViewTable}
          DownloadExcel={DownloadExcel}
          tableRef={tableRef}
          onlyView={onlyView}
          ReportColumn
          TableOriginalHead={tableOriginalHead}
          LastUpdate={LastUpdate}
          Syncing={Syncing}
          exportTable={exportTable}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          setPageSize={setPageSize}
          pageSize={pageSize}
          totalRows={totalRows}
          searchText={searchText}
          setSearchText={setSearchText}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortType={sortType}
          setSortType={setSortType}
        />
      ) : null}
    </>
  );
};

export default TableResult;
