
import React, { useState } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Result from '../TableResults/Result';
import Button from 'react-bootstrap/Button';
import full from '../../assets/svg/full.svg'
import ErrorList from './ErrorList';
import TableResult from '../table/TableResult';

function FullTable() {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  return (
    <>
    <Button className="" variant="" onClick={handleShow}>
      <img src = {full} />
    </Button>


    <Modal centered fullscreen show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <div className='d-flex align-items-center justify-content-between w-100'>
            <div className='w-75'>
            <Modal.Title className='fs-5 fw-semibold'>Customer Data</Modal.Title>
            <ErrorList/>
            </div>
            <div className='d-flex w-50 justify-content-end'>
        <div className='d-flex align-items-center'>
            <span className='fs-7 w-50'>Go to row</span>
            <select className="form-select w-10 rounded-pill mx-2 w-50" aria-label="Default select example">
            <option selected>0</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            </select>
        </div>
        <button className='btn main-button fw-medium rounded-pill fs-7'>Edit Data Field</button>

    </div>
        </div>
      
      </Modal.Header>
      <Modal.Body>
        
        <TableResult/>


      </Modal.Body>
    </Modal>
  </>
  )
}

export default FullTable
