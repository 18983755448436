import React from "react";
import start_date from "../../assets/svg/start-date.svg";
import end_date from "../../assets/svg/end-date.svg";
import retry from "../../assets/svg/retry.svg";
import { Button } from "react-bootstrap";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function SyncCard(props) {
  let startDateTime =
    props.createdOn.substring(0, 10) +
    " T " +
    props.createdOn.split("T")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[2].substring(0, 2);
  let endDateTime =
    props.createdOn.substring(0, 10) +
    " T " +
    props.createdOn.split("T")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[1].substring(0, 2) +
    ":" +
    props.createdOn.split(":")[2].substring(0, 2);

  function DownloadReport() {
    if (props.jobStatus === "Completed" && props.jobType === "createReport") {
      Window.location.href = props.reportFullUrl;
    } else {
      toast.error("Incompleted Report !", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  return (
    <div className="">
      <div className="report-card p-3">
        <div className="d-flex justify-content-between align-items-end">
          <div className="sync-tag py-1 d-flex justify-content-center flex-column rounded-pill px-2">
            <span
              className="fw-semibold fs-9 pointer"
              onClick={() => DownloadReport()}
            >
              {props.jobType}
            </span>
          </div>
        </div>
        <div className="">
          <h4
            title={props.reportName}
            className="fs-6 fw-semibold mt-3 mb-0 jobTitle"
          >
            {props.reportName.length > 65
              ? props.reportName.substring(0, 65) + "..."
              : props.reportName}
          </h4>
          <h5
            className={
              props.jobStatus === "In Progress"
                ? "fs-7 fw-semibold text-info mt-2 mb-0"
                : props.jobStatus === "Completed"
                ? "fs-7 fw-semibold text-success mt-2 mb-0"
                : props.jobStatus === "Failed"
                ? "fs-7 fw-semibold text-danger mt-2 mb-0"
                : "fs-7 fw-semibold text-default mt-2 mb-0"
            }
          >
            {props.jobStatus}
          </h5>
          <div className="d-flex justify-content-between mt-2">
            <div className="">
              <img src={start_date} />
              <span className="ms-1 fs-8 fw-normal light-dark">
                Start Date and Time <br></br>
                {startDateTime.split(" T ")[0]} <br></br>{" "}
                {startDateTime.split(" T ")[1]}
              </span>
            </div>
            <div className="">
              <img src={end_date} />
              <span className="ms-1 fs-8 fw-normal light-dark">
                End Date and Time <br></br>
                {endDateTime.split(" T ")[0]} <br></br>{" "}
                {endDateTime.split(" T ")[1]}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SyncCard;
