import React, { useState, useEffect } from 'react'
import { Modal, Form } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import RelationshipList from '../../components/relationship/RelationshipList'
import GetBaseUrl from '../../functions/SetBaseUrl'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import TableList from '../UploadedTable/TableList'

const BaseUrl = GetBaseUrl()[0]   ;


function CreateGroup(props) {
    const [show, setShow] = useState(false);
    const [value, setValue] = React.useState("");
    
    const [TablesArray, setTablesArray] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [list, setList] = useState([]);
    const [tablesToAssign, setTablesToAssign] = useState([]);
    const BaseUrl = GetBaseUrl()[0]   ;
    var token = localStorage.getItem( GetBaseUrl()[1] + "_Edu_AuthToken");

    useEffect(() => { 

      const token = localStorage.getItem(GetBaseUrl()[1]+"_Edu_AuthToken");
      const Url1 =BaseUrl  +   '/Relations/GetAllRelationships';
      fetch(Url1 ,{
        headers: {
        Authorization: `Bearer ${token}`,
        "content-type": "application/json",
        },
      })
      .then(response => response.json())
      .then(json => setList(json ))     
  
 
    }, [])
    const renderItem = (item, index) =>    <div className='fw-semibold' key={index}>{item}</div>   
  


    const [projectName, setProjectName] = useState('');
    const handleChange = event => {
      setProjectName(event.target.value);
    };



 


    const handleCreate = async () => {

      const ToPost = {"projectName": projectName}; 
      if(!projectName){  toast.warning('Fill Required Fields') ; return false; }

     // console.log(ToPost);
      const response = await axios
          .post(BaseUrl+"/Relations/CreateProject", ToPost, {   
            headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
            },
          })
          .catch((error) => console.log('Error: ', error));
      if ((response && response.data) || response.status===200 ) {

        toast.success('New Project has been created', {
          position: toast.POSITION.TOP_RIGHT
        });
        handleClose();
        props.setReload(Math.random());

      }
  };



  return (
    <div>
    <Button className="p-0 fs-8 main" variant="" onClick={handleShow}>
        Create Project
      </Button>

        <Modal centered show={show} onHide={handleClose}>
        <Modal.Header className='p-3 pb-0' closeButton>
        <div className=" d-flex justify-content-between align-items-center w-100">
          <Modal.Title className='fs-5 fw-semibold'>Create Project</Modal.Title>
        </div>
        </Modal.Header>
        <Modal.Body>
        <div className=" d-flex justify-content-between align-items-center mb-2">
            <Form.Group className="w-100" controlId="exampleForm.ControlInput1">
              <Form.Label className="fw-semibold fs-7">Project Name</Form.Label>
              <Form.Control className="inp-input p-2 fs-7 fw-medium" type="text" placeholder="Enter Group Name" defaultValue='' onChange={handleChange} />
            </Form.Group>
         </div>
        <div className=''>



        {/* <RelationshipList 
                      renderItem={renderItem}
                      relationshipsList={list}

                      />
 */}




        </div>
        </Modal.Body>
        <Modal.Footer className='border-0'>
          <Button className='main-button w-100 fw-semibold rounded-pill py-2' variant="" onClick={handleCreate}>
            Save Project
          </Button>
        </Modal.Footer>
        </Modal>
    
    </div>
  )
}

export default CreateGroup
