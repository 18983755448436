import React, { useState, useEffect, useContext } from "react";
import GetBaseUrl from "./SetBaseUrl";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";


  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");
  const companyId = localStorage.getItem(GetBaseUrl()[1] + "_CompanyId");


//----------------------------------------------------
  // Function to extract strings between square brackets
 export const extractStringsBetweenBrackets = (str) => {
    const regex = /\[([^\]]+)\]/g;
    let matches = [];
    let match;
    while ((match = regex.exec(str)) !== null) {
      matches.push(match[1]);
    }
    return matches;
  };  
//----------------------------------------------------
 export function FindMultipleInObj(array, Key1, Value1, Key2='test', Value2='test') {
    let res = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key1] === Value1 && array[i][Key2] === Value2) {
        //array.splice(i, 1);
        res = true;
        break;
      }
    }
    return res;
  }
  //----------------------------------------------------
 export function FindObj(array, Key, Value) {
    var msg = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = true;
        break;
      }
    }
    return msg;
  }

  //----------------------------------------------------
 export function FindIndexInObj(array, Key, Value) {
    var msg = -1;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i ;
        break;
      }
    }
    return msg;
  }
    //----------------------------------------------------
 export function FindDeleteInObj(array, Key, Value) {
    //var msg = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
       // msg = i + 1;
       array.splice(i,1)
        break;
      }
    }
   // return msg;
  }
  //----------------------------------------------
 export function RandomNumberInRange(min, max) {
    //  get number between min and max
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }






    //----------------------------------------------
