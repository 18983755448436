import { render } from "@testing-library/react";
import { React, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup, Form } from "react-bootstrap";

const DataType = (props) => {
  const [Columns, setColumns] = useState([]);
  const [ch, setCh] = useState(false);

  //----------------------------------------------------
  function FindVisibleObj(array, Key, Value, visible) {
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        // console.log(array[i]);
        array[i].visible = visible;
      }
    }
  }
  //----------------------------------------------------
  function FindIndexInObj(array, Key, Value) {
    var msg;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key] === Value) {
        msg = i;
        break;
      }
    }
    return msg;
  }
  //----------------------------------------------------
  function FindDeleteFromObj(array, Key1, Value1) {
    let res = false;
    for (var i = 0; i < array.length; i++) {
      if (array[i][Key1] === Value1) {
        array.splice(i, 1);
        res = true;
      }
    }

    return res;
  }
  //----------------------------------------------------
  function reorderArray(arr, fromIndex, toIndex) {
    // Ensure the indices are within bounds
    if (
      fromIndex < 0 ||
      fromIndex >= arr.length ||
      toIndex < 0 ||
      toIndex >= arr.length
    ) {
      console.error("Invalid indices");
      return;
    }

    // Remove the item from the original position
    const [movedItem] = arr.splice(fromIndex, 1);

    // Insert the item at the new position
    arr.splice(toIndex, 0, movedItem);
  }
  //----------------------------------------------------

  const handleChange = (e) => {
    let isChecked = e.target.checked;
    let value = e.target.value;

    if (isChecked) {
      let newArray = [...props.toShowColumnArray, value];
      // props.setToShowColumnArray(reorderArray(newArray, 0, newArray.length));
      props.setToShowColumnArray([...props.toShowColumnArray, value]);
    } else {
      let newArray = props.toShowColumnArray.filter((item) => item !== value);
      // props.setToShowColumnArray(reorderArray(newArray, 0, newArray.length));
      props.setToShowColumnArray(
        props.toShowColumnArray.filter((item) => item !== value)
      );
    }

    // let updatedObj = { ...props.obj };
    // let TableIndex = FindIndexInObj(
    //   updatedObj.Tables,
    //   "tableId",
    //   props.tableId
    // );
    // let ColumnIndex = FindIndexInObj(
    //   updatedObj.Tables[TableIndex].columns,
    //   "columnId",
    //   value
    // );
    // updatedObj.Tables[TableIndex].columns[ColumnIndex].isVisible = isChecked;
    setCh(isChecked);

    // props.setObj(updatedObj);
  };

  //----------------------------------------------------
  useEffect(() => {
    if (
      props.EditReportIdHead.length > 0 &&
      props.EditReportIdHead.indexOf(props.columnId) > -1
    ) {
      setCh(true);
    } else if (
      props.EditReportIdHead.length > 0 &&
      props.EditReportIdHead.indexOf(props.columnId) === -1
    ) {
      setCh(false);
    }
  }, [props.EditReportIdHead, props.open]);

  //----------------------------------------------------
  useEffect(() => {
    // reportSelectedColumns;

    for (let index = 0; index < props.reportSelectedColumns.length; index++) {
      const element = props.reportSelectedColumns[index];
      if (element.columnId === props.columnId) {
        setCh(true);
      }
    }
  }, [props.reportSelectedColumns]);

  //----------------------------------------------------

  return (
    <div>
      <div className="d-flex align-items-center  w-75">
        <Form.Check
          id={props.index + 1}
          type="checkbox"
          l={props.columnName}
          label={props.columnName}
          className="ms-2 mb-2 w-100 fs-7 text-truncate"
          onChange={handleChange}
          value={props.columnId}
          table={props.tableId}
          checked={ch}
        />
      </div>
    </div>
  );
};

export default DataType;
