import React, { useEffect, useState, useContext } from "react";
import styles from "./DashboardHeader.module.scss";
import Select, { components } from "react-select";
import { Background } from "reactflow";
import Filter from "./Filter";
import ToggleOptions from "./ToggleOptions";
import QueryFiltersModal from "./QueryFiltersModal";
import FiltersModal from "./FiltersModal";

// import { Emirates, Schools, SchoolTypes, Period } from "./FilterData";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import more from "../../assets/svg/more.svg";
import filterIcon from "../../assets/svg/filterIcon.svg";
import EmiratesIcon from "../../assets/svg/EmiratesIcon.svg";
import SchoolIcon from "../../assets/svg/schoolIcon.svg";
import WeekIcon from "../../assets/svg/weekIcon.svg";
import {
  GetAPIFilterData,
  GetAPISavedFilterQuery,
} from "../../functions/GetAPIList";

import {
  comprehensiveStylesBlue,
  comprehensiveStyles,
} from "./Styles/HeaderStyles";

const DashboardHeader = (props) => {
  const [askQueryModal, setAskQueryModal] = useState(false);
  const [filtersModal, setFiltersModal] = useState(false);

  const [Emirates, setEmirates] = useState([]);
  const [Schools, setSchools] = useState([]);
  const [SchoolTypes, setSchoolTypes] = useState([]);
  const [Period, setPeriod] = useState([
    { value: "Day", label: "Day" },
    { value: "Week", label: "Week" },
    { value: "Month", label: "Month" },
  ]);
  const [FilterCol, setFilterCol] = useState("");
  const [FilterIndex, setFilterIndex] = useState(1);
  const [queryId, setQueryId] = useState();
  const [Filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);

  const [selectedFilter1, setSelectedFilter1] = useState("");
  const [selectedFilter2, setSelectedFilter2] = useState("");
  const [selectedFilter3, setSelectedFilter3] = useState("");
  const [selectedFilter4, setSelectedFilter4] = useState("");

  //----------------------------------------------
  useEffect(() => {
    //console.log(selectedFilter1);
  }, [selectedFilter1, selectedFilter2, selectedFilter3, selectedFilter4]);
  //-------------------------------

  //--------------------------------------
  useEffect(() => {
    setFilters([
      {
        container_class: "Filter1",
        icon: EmiratesIcon,
        options: Emirates,
        placeholder: "Emirates",
        selected: selectedFilter1,
        onChange: setSelectedFilter1,
      },
      {
        container_class: "Filter2",
        icon: SchoolIcon,
        options: Schools,
        placeholder: "School Name",
        selected: selectedFilter2,
        onChange: setSelectedFilter2,
      },
      {
        container_class: "Filter3",
        icon: SchoolIcon,
        options: SchoolTypes,
        placeholder: "School Type",
        selected: selectedFilter3,
        onChange: setSelectedFilter3,
      },
      {
        container_class: "Date",
        icon: WeekIcon,
        options: Period,
        placeholder: "Week",
        selected: selectedFilter4,
        onChange: setSelectedFilter4,

        blue: true,
      },
    ]);
  }, [Emirates, Schools, SchoolTypes]);

  //---------------------------------------------------  (1)
  const ApplyFilters = () => {
    props.setReloadDashboard(Math.random(400));
  };
  //----------------------------------------------
  useEffect(() => {
    const Fill_Filters = async (Id, Index) => {
      try {
        const data = await GetAPIFilterData(Id);
        // console.log(data);
        if (Index === 1) {
          setEmirates(data);
        } else if (Index === 2) {
          setSchools(data);
        } else if (Index === 3) {
          setSchoolTypes(data);
        }
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };

    const SavedFilterQuery = async () => {
      try {
        const data = await GetAPISavedFilterQuery();
        // console.log(data);
        setSelectedFilters(data);
        for (let i = 0; i < data.length; i++) {
          const columnId = data[i].columnId;
          columnId && Fill_Filters(columnId, i + 1);
        }
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };

    SavedFilterQuery();
  }, []);
  //----------------------------------------------

  useEffect(() => {
    const Fill_Filters = async (Id, Index) => {
      try {
        const data = await GetAPIFilterData(Id);

        if (Index === 1) {
          setEmirates(data);
          props.setSelectedFilter1("");
        } else if (Index === 2) {
          setSchools(data);
          props.setSelectedFilter2("");
        } else if (Index === 3) {
          setSchoolTypes(data);
          props.setSelectedFilter3("");
        }
      } catch (error) {
        console.error("Error By Id :", error);
      }
    };

    FilterCol && Fill_Filters(FilterCol, FilterIndex);
  }, [FilterCol]);
  //----------------------------------------------

  return (
    <div className={`${styles.container} row `}>
      <label className={`${styles.label} `}>AI Tutor Dashboard</label>
      <div className={`${styles["Filters"]} row `}>
        {Filters &&
          Filters.map((item, index) => {
            return item.blue ? null : (
              <Filter
                key={index}
                index={index + 1}
                setQueryId={setQueryId}
                icon={item.icon}
                placeholder={item.placeholder}
                selected={item.selected}
                onChange={item.onChange}
                container_class={item.container_class}
                options={item.options}
                withQuery={props.withQuery}
                setAskQueryModal={setAskQueryModal}
                setFilterIndex={setFilterIndex}
                selectedFilters={props.selectedFilters}
                setSelectedFilters={props.setSelectedFilters}
              />
            );
          })}

        <div className={`${styles.Apply}`}>
          <Button
            className={`main-button w-100 fw-semibold  ${styles.applyBtn}`}
            variant=""
            onClick={() => ApplyFilters()}
          >
            Apply Filter
          </Button>
          <img
            src={filterIcon}
            alt="aa"
            id="dropdown-split-basic"
            className={`${styles.filterIcon}`}
            onClick={() => setFiltersModal(true)}
          />
        </div>
      </div>

      <div className={`${styles["Date-container"]} row `}>
        {Filters &&
          Filters.map((item, index) => {
            return !item.blue ? null : (
              <Filter
                key={index}
                index={index + 1}
                setQueryId={setQueryId}
                icon={item.icon}
                placeholder={item.placeholder}
                container_class={item.container_class}
                options={item.options}
                blue
                selected={item.selected}
                onChange={item.onChange}
                setFilterIndex={setFilterIndex}
                selectedFilters={props.selectedFilters}
                setSelectedFilters={props.setSelectedFilters}
                // withQuery={props.withQuery}
                // setAskQueryModal={setAskQueryModal}
              />
            );
          })}
        <ToggleOptions
          setEditMode={props.setEditMode}
          editMode={props.editMode}
          container_class="Options"
        />
      </div>

      {askQueryModal ? (
        <QueryFiltersModal
          setAskQueryModal={setAskQueryModal}
          setFilterCol={setFilterCol}
          queryId={queryId}
          selectedFilters={selectedFilters}
        />
      ) : null}

      {filtersModal ? (
        <FiltersModal
          Filters={Filters}
          ApplyFilters={ApplyFilters}
          setFiltersModal={setFiltersModal}
          setQueryId={setQueryId}
          withQuery={props.withQuery}
          setAskQueryModal={setAskQueryModal}
          setFilterIndex={setFilterIndex}
          selectedFilters={props.selectedFilters}
          setSelectedFilters={props.setSelectedFilters}
        />
      ) : null}
    </div>
  );
};

export default DashboardHeader;
