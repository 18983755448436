import React, { useState, useEffect } from "react";
import { Modal, Form, Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";

import GetBaseUrl from "../../functions/SetBaseUrl";
import axios from "axios";

function CustomHeader(props) {
  const [show, setShow] = useState(false);
  const [value, setValue] = React.useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [Columns, setColumns] = useState([]);

  const BaseUrl = GetBaseUrl()[0];
  var token = localStorage.getItem(GetBaseUrl()[1] + "_Edu_AuthToken");

  useEffect(() => {
    setColumns(props.TableHead);
  }, [props.TableHead]);

  useEffect(() => {
    // console.log(Columns);
  }, [Columns]);

  const handleChange = (e) => {
    let Index = e.target.id;
    let a = [...Columns];
    let isChecked = e.target.checked;
    let value = e.target.defaultValue;
    let table = props.TableId;
    if (isChecked) {
      a[Index - 1] = value;
      if (table && Index) {
        document
          .querySelectorAll(
            "#Table" + table + " thead tr th:nth-child(" + Index + ")"
          )
          .forEach((el) => (el.style.display = ""));
        document
          .querySelectorAll(
            "#Table" + table + " tbody tr td:nth-child(" + Index + ")"
          )
          .forEach((el) => (el.style.display = ""));
      }
      setColumns(a);
    } else {
      a[Index - 1] = "";
      if (table && Index) {
        document
          .querySelectorAll(
            "#Table" + table + " thead tr th:nth-child(" + Index + ")"
          )
          .forEach((el) => (el.style.display = "none"));
        document
          .querySelectorAll(
            "#Table" + table + " tbody tr td:nth-child(" + Index + ")"
          )
          .forEach((el) => (el.style.display = "none"));
      }
      setColumns(a);
    }
  };

  const saveTable = async (TableId, array) => {
    //console.log(array);
    array = array.filter(function (item) {
      return item !== "";
    });
    const response = await axios
      .post(
        BaseUrl + "/Sync/SaveAPIData/" + props.ConnectionId + "/" + TableId,
        array,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .catch((error) => console.log("Error: ", error));
    if (response.status === 200) {
      toast.success("Table has been saved", {
        position: toast.POSITION.TOP_RIGHT,
      });
      handleClose();
    }
  };

  const ExportToExcel = async (ConnectionId, TableId) => {
    axios
      .get(
        BaseUrl + "/Sync/GetFilePlatformData/" + ConnectionId + "/" + TableId,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "content-type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        window.location.href = res.data;
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => {});
  };

  return (
    <>
      <div>
        <Dropdown>
          <Dropdown.Toggle
            variant=""
            id="dropdown-basic"
            className="main-button rounded-pill fw-semibold mx-3"
          >
            Custom Header
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-3 w-100">
            <Form>
              <div className="">
                {props.TableHead &&
                  props.TableHead.map((item, index) => (
                    <Form.Check
                      id={index + 1}
                      type="checkbox"
                      label={item}
                      className="mb-2 w-100 text-truncate"
                      onChange={handleChange}
                      value={item}
                      table={props.TableId}
                      defaultChecked
                    />
                  ))}
              </div>
            </Form>
            {/* <Button variant='' className='main-button rounded-pill mt-1 fw-semibold w-100' onClick={() => setShow(false)}>Confirm</Button> */}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Button
        variant=""
        className="main-button rounded-pill fw-semibold"
        onClick={() => ExportToExcel(props.ConnectionId, props.TableId)}
      >
        Export To Excel
      </Button>
      &nbsp; &nbsp;
      <Button
        variant=""
        className="main-button rounded-pill fw-semibold"
        TableId={props.TableId}
        onClick={() => saveTable(props.TableId, Columns)}
      >
        Save as Table
      </Button>
    </>
  );
}

export default CustomHeader;
