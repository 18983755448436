import React from "react";
import { Link } from "react-router-dom";
import noPermission from "../assets/svg/noPermission.svg";
import { border, padding, width } from "@mui/system";
const NoPermission = () => {
  return (
    <div style={{ position: "relative" }}>
      <Link to="/">
        <img src={noPermission} width={"85%"} height={"50%"} />
      </Link>{" "}
    </div>
  );
};

export default NoPermission;
