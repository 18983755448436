import React from 'react'
import OutputTableList from '../components/output/OutputTableList'
import QueryArea from '../components/query/QueryArea'
import Result from '../components/TableResults/Result'
import GenerateDashboard from '../components/modals/GenerateDashboard'
import { Dropdown } from 'react-bootstrap'
import xls from '../assets/svg/xls_1.svg'
import pdf from '../assets/svg/pdf.svg'

const Outputs = () => {
  return (
    <div>
    <div className = "rounded-5 main-bg min-vh-85">
   <div className='row'>
   <div className = "col-lg-4 col-xl-3 px-0 pe-3">
    <div className = "top-bg p-3 rounded-5 min-vh-85">
    <OutputTableList/>
    </div>
    </div>
    <div className='col-lg-8 col-xl-9'>
    <div className = "top-bg rounded-5 mb-4">
      <QueryArea/>
    </div>
    <div className = "top-bg p-4 rounded-5 min-vh-75">
    <div className='d-flex justify-content-between align-items-center mb-3'>
        <div className='d-flex'>
            <h4 className = "fw-semibold mb-0 fs-5">Result</h4>
        </div>
        <div className='d-flex'>
        <Dropdown>
      <Dropdown.Toggle variant='' id="dropdown-basic" className='rounded-pill main-button px-3 fw-medium mx-2 fs-8'>
        Export
      </Dropdown.Toggle>

      <Dropdown.Menu>
      <Dropdown.Item href="#/action-1" className='p-1 ps-3'><img className='mx-2' src = {xls}/> Xls</Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item href="#/action-2" className='p-1 ps-3'><img className='mx-2' src = {pdf}/> Pdf</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        <GenerateDashboard/>
        </div>
         </div>
        <Result/>
      </div>
    </div>
   </div>
    </div>
    </div>
  )
}

export default Outputs
